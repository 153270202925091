import { combineReducers } from 'redux';


export const MAP_FETCH_ALL_INIT = "MAP_FETCH_ALL_INIT";
export const MAP_FETCH_ALL_SUCCESS = "MAP_FETCH_ALL_SUCCESS";
export const MAP_FETCH_ALL_ERROR = "MAP_FETCH_ALL_ERROR";
export const MAP_CLEAR_ERRORS = "MAP_CLEAR_ERRORS";
export const MAP_REFRESH_ALL_DATA = "MAP_REFRESH_ALL_DATA";
export const MAP_SHIP_POPUP = 'MAP_SHIP_POPUP';
export const MAP_SHIP_BOUNDS = 'MAP_SHIP_BOUNDS';
export const MAP_SHIP_FLYING_CENTER = 'MAP_SHIP_FLYING_CENTER';
export const MAP_FLEET_FILTERS_SHIP = 'MAP_FLEET_FILTERS_SHIP';
export const MAP_FLEET_FILTERS_POSITION = 'MAP_FLEET_FILTERS_POSITION';
export const MAP_SHIP_DAYLIGHT_DATA = 'MAP_SHIP_DAYLIGHT_DATA';
export const MAP_FLEET_SELECTED_SHIPID = 'MAP_FLEET_SELECTED_SHIPID';

const defaultPadding = {top:50,left:50,right:50,bottom:70};

const defaultFilters = {ships:[], positions:['AT_PORT', 'AT_SEA']};

const createMapReducer = () => {

  const shipsData = (state=null, action) => {
    switch(action.type){
      case MAP_FETCH_ALL_SUCCESS:
      case MAP_REFRESH_ALL_DATA:
        if (JSON.stringify(action.items) === JSON.stringify(state)) return state;
        return action.items;
      case MAP_FETCH_ALL_ERROR:
        return [];
      default:
        return state;
    }
  }

  const ships = (state=[], action) => {
    switch(action.type){
      case MAP_FETCH_ALL_SUCCESS:
        return action.items.features.map(el => {
          return {
            id: +el.properties.shipId,
            name: el.properties.shipName,
            speed: +el.properties.speed,
            engineSup: el.properties.engineSup,
            safetySup: el.properties.safetySup,
            icon: el.properties.icon
          }
        });
      case MAP_FETCH_ALL_ERROR:
        return [];
      default:
        return state;
    }
  }

  const filters = (state=defaultFilters, action) => {
    switch(action.type){
      case MAP_FLEET_FILTERS_SHIP:
        const {ship} = action;
        const copyShips = [...state.ships];
        const i = copyShips.findIndex(el => +el.id === +ship.id);
        if (i > -1) {
          copyShips.splice(i,1);
        }else{
          copyShips.push(ship);
        }
        return {...state, ships:copyShips.sort((a, b) => +a.id - +b.id)}
      case MAP_FLEET_FILTERS_POSITION:
        const positions = [...state.positions];
        const p = positions.findIndex(el => el === action.position);
        if (p > -1){
          positions.splice(p, 1);
        }else{
          positions.push(action.position);
        }
        return {...state, positions};
      case MAP_FETCH_ALL_SUCCESS:
        const list = action.items.features.map(el => {
          return {
            id: +el.properties.shipId,
            name: el.properties.shipName,
            speed: +el.properties.speed,
            engineSup: el.properties.engineSup,
            safetySup: el.properties.safetySup,
            icon: el.properties.icon,
            lng: el.properties.lng,
            lat: el.properties.lat,
            course: el.properties.course,
          }
        });
        return {...defaultFilters, ships: list.sort((a, b) => +a.id - +b.id)};
      default:
        return state;
    }
  }

  const error = (state=null, action) => {
    switch(action.type){
      case MAP_FETCH_ALL_ERROR:
        return action.error;
      case MAP_FETCH_ALL_INIT:
      case MAP_FETCH_ALL_SUCCESS:
      case MAP_CLEAR_ERRORS:
        return null;
      default:
        return state;
    }
  }

  const isLoading = (state=false, action) => {
    switch(action.type){
      case MAP_FETCH_ALL_INIT:
        return true;
      case MAP_FETCH_ALL_ERROR:
      case MAP_FETCH_ALL_SUCCESS:
        return false;
      default:
        return state;
    }
  }

  const shipPopupData = (state=null, action) => {
    switch(action.type) {
      case MAP_SHIP_POPUP:
        return action.value;
      default:
        return state;
    }
  }

  const bounds = (state=[[0,0],[0,0]], action) => {
    switch(action.type) {
      case MAP_SHIP_BOUNDS:
        return action.bounds;
      default:
        return state;
    }
  }

  const padding = (state=defaultPadding, action) => {
    switch(action.type) {
      case MAP_SHIP_BOUNDS:
        return action.padding || defaultPadding;
      case MAP_SHIP_POPUP:
        if (!action.value) return defaultPadding;
        return {...state, top:1000};
      default:
        return state;
    }
  }

  const flyingCenter = (state=null, action) => {
    switch(action.type) {
      case MAP_SHIP_FLYING_CENTER:
        return action.value;
      case MAP_SHIP_POPUP:
        if (!action.value) return null;
        return [+action.value.lng, +action.value.lat];
      default:
        return state;
    }
  }

  const daylightData = (state=null, action) => {
    switch(action.type) {
      case MAP_SHIP_DAYLIGHT_DATA:
        return action.value;
      default:
        return state;
    }
  }
  
  const selectedShipId = (state=null, action) => {
    switch(action.type) {
      case MAP_FLEET_SELECTED_SHIPID:
        return state === action.shipId ? null : action.shipId;
      default:
        return state;
    }
  }

  return combineReducers({
    shipsData,
    ships,
    filters,
    error,
    isLoading,
    shipPopupData,
    bounds,
    padding,
    flyingCenter,
    daylightData,
    selectedShipId,
  });
}

export default createMapReducer();