export const formatDate = dt => {
  if (!dt || dt === '') return '';
  dt = dt.replace('T', ' ');
  const arr = dt.split(' ')[0].split('-');
  const arr2 = dt.split(' ')[1].split(':');
  return `${arr[2]}.${arr[1]}.${arr[0]} ${arr2[0]}:${arr2[1]}`;
};

export const currentWeekNumber = () => {
  const today = new Date();
  const firstDay = new Date(today.getFullYear(),0,1);
  const days = Math.floor((today - firstDay) / (24*60*60*1000));
  return Math.ceil(days / 7);
}

export const phoneFormat = p => {
  if (!p) return null;
  p = p.replaceAll(/\D/g, '');
  if (p.slice(0, 1)*1 === 0) {
    p = `9${p}`;
  }else if (p.slice(0, 1)*1 === 5){
    p = `90${p}`;
  }
  return "+" + p.slice(0,2) + " " + p.slice(2,5) + " " + p.slice(5,8) + " " + p.slice(8,10) + " " + p.slice(10,12);
}