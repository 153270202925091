import { 
  IZINLER_CLEAR_ALL_ERRORS,
  IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_ERROR,
  IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_INIT,
  IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_SUCCESS,
  IZINLER_FETCH_FORMGETIRMEYENLER_ERROR,
  IZINLER_FETCH_FORMGETIRMEYENLER_INIT,
  IZINLER_FETCH_FORMGETIRMEYENLER_SUCCESS,
  IZINLER_FETCH_MYWAITINGS_ERROR,
  IZINLER_FETCH_MYWAITINGS_INIT,
  IZINLER_FETCH_MYWAITINGS_SUCCESS,
  IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_ERROR,
  IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_INIT,
  IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_SUCCESS,
  IZINLER_FETCH_WAITINGFORME_ERROR,
  IZINLER_FETCH_WAITINGFORME_INIT,
  IZINLER_FETCH_WAITINGFORME_SUCCESS,
  IZINLER_FILTERS_TOGGLE,
  IZINLER_RESET,
  IZINLER_SELECTED_IZINID
} from "../reducers/izinler"
import myfetch from "../utils/myFetch";

export const toggleFilters = (forWhat, value) => dispatch => {
  dispatch({type:IZINLER_FILTERS_TOGGLE, forWhat, value});
}

export const activateIzinListItem = (izinId) => dispatch => {
  dispatch({type:IZINLER_SELECTED_IZINID, izinId});
}

export const fetchFormGetirmeyenler = () => async dispatch => {
  dispatch({type:IZINLER_FETCH_FORMGETIRMEYENLER_INIT});
  try {
    const items = await dispatch(myfetch({url:"/izinler", params:{formGetirmeyenler:true}}));
    dispatch({type:IZINLER_FETCH_FORMGETIRMEYENLER_SUCCESS, items});
  } catch (error) {
    dispatch({type:IZINLER_FETCH_FORMGETIRMEYENLER_ERROR, error});
  }
}

export const fetchMyWaitings = () => async dispatch => {
  dispatch({type:IZINLER_FETCH_MYWAITINGS_INIT});
  try {
    const izinler = await dispatch(myfetch(({url:'/izinler', params:{waitings:true}})));
    dispatch({type:IZINLER_FETCH_MYWAITINGS_SUCCESS, izinler});
  } catch (error) {
    dispatch({type:IZINLER_FETCH_MYWAITINGS_ERROR, error});
  }
}

export const fetchWaitingForMe = () => async dispatch => {
  dispatch({type:IZINLER_FETCH_WAITINGFORME_INIT});
  try {
    const izinler = await dispatch(myfetch(({url:'/izinler', params:{waitingForMe:true}})));
    dispatch({type:IZINLER_FETCH_WAITINGFORME_SUCCESS, izinler});
  } catch (error) {
    dispatch({type:IZINLER_FETCH_WAITINGFORME_ERROR, error});
  }
}

export const fetchBekleyenIzinler = (userId) => async dispatch => {
  dispatch({type:IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_INIT});
  try {
    const izinler = await dispatch(myfetch(({url:'/izinler', params:{userId, waitings:true}})));
    dispatch({type:IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_SUCCESS, izinler, userId});
  } catch (error) {
    dispatch({type:IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_ERROR, error});
  }
}

export const fetchTamamlananIzinler = (userId, firstFetch) => async (dispatch, getState) => {
  let {offset, limit, toplamIzinSayisi} = getState().izinler;
  
  if (+toplamIzinSayisi < offset) {
    console.log("Toplam İzin Sayısı:", toplamIzinSayisi, " - offset:", offset);
    return;
  }

  dispatch({type:IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_INIT});
  try {
    const {
      izinler,
      toplamIzinSayisi, 
      kalanIzinSayisi
    } = await dispatch(myfetch(({
      url:'/izinler', 
      params:{offset, limit, userId}
    })));

    dispatch({
      type:IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_SUCCESS,
      offset: offset+limit,
      limit,
      userId,
      izinler, 
      toplamIzinSayisi, 
      kalanIzinSayisi,
      firstFetch
    });
  } catch (error) {
    dispatch({type:IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_ERROR, error});
  }
}



export const clearIzinlerErrors = () => dispatch => {
  dispatch({type:IZINLER_CLEAR_ALL_ERRORS});
}

export const resetIzinlerData = () => dispatch => {
  dispatch({type:IZINLER_RESET});
}



