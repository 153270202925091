import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom";
import { cancelIzin, clearIzinErrors, deleteIzin, fetchIzin, putGemiEndDate, putGemiStartDate, toggleFormGetirdimi } from "../../../../actions/izin";
import Button from "../../../../components/button/Button";
import Confirmation from "../../../../components/confirmation/Confirmation";
import ErrorMessage from "../../../../components/errorMessage/ErrorMessage";
import LoadingWave from "../../../../components/loading_spinners/LoadingWave";
import { formatDate } from "../../../../utils/functions";
import styles from './Izin.module.css';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import IzinAnswer from "./IzinAnswer";
import MyMap from "../../../../components/map/MyMap";
import { addNotification } from "../../../../actions/notification";


const Izin = ({backPath, backPathName}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { izin, error, isLoading } = useSelector(({izin}) => izin);
  const { user } = useSelector(({auth}) => auth);
  const { authorizations } = useSelector(({user}) => user);
  const { izinId } = useParams();
  const [ izinStatus, setIzinStatus ] = useState('...');
  const [ onay, setOnay ] = useState();
  const [ guncelleyebilir, setGunceleyebilir ] = useState(false);
  const classesRef = useRef([styles.Wrapper]);
  const gemiStartDateRef = useRef();
  const gemiEndDateRef = useRef();

  useEffect(()=>{
    setGunceleyebilir(!!izin
      && (+izin.userId === +user.id || +authorizations.IZINLER > 2)
      //&& (izin.status === "Approved" || izin.status === "Approving")
    );
  }, [authorizations.IZINLER, izin, user.id]);

  useEffect(()=>{
    if (!!izinId) dispatch(fetchIzin(izinId));
  }, [dispatch, izinId]);

  useEffect(()=>{
    if (!!izin && izin.status === "Approved") {
      setIzinStatus("Onaylandı.");
      classesRef.current = [styles.Wrapper, styles.Approved];
    }
    if (!!izin && izin.status === "Approving") {
      setIzinStatus("Onay Aşamasında.");
      classesRef.current = [styles.Wrapper, styles.Approving];
    }
    if (!!izin && izin.status === "Canceled") {
      setIzinStatus("İptal Edildi");
      classesRef.current = [styles.Wrapper, styles.Canceled];
    }
    if (!!izin && izin.status === "Canceling") {
      setIzinStatus("İptal Aşamasında.");
      classesRef.current = [styles.Wrapper, styles.Canceling];
    }
    if (!!izin && izin.status === "Declined") {
      setIzinStatus("Reddedildi!");
      classesRef.current = [styles.Wrapper, styles.Declined];
    }
  }, [izin]);

  const askCancel = () => {
    let onaylayanlar = izin.answers.filter(el => el.answer === "Approved").length;
    if (onaylayanlar > 0) {
      setOnay({
        question: `İzninizi onaylamış olan ${onaylayanlar} kişinin bu iznin iptalini onaylamasını isteyeceğiz. Devam edelim mi?`,
        onConfirm: ()=>{
          setOnay(null);
          dispatch(cancelIzin(izin.id));
        },
        onCancel:()=>setOnay(null)
      });
    }else{
      setOnay({
        question: "Henüz kimse onaylamadığı için izin talebini sadece sileceğiz. Onaylıyor musunuz?",
        onConfirm: () => {
          dispatch(cancelIzin(izin.id));
          setOnay(null);
          navigate('../..');
        },
        onCancel: () => setOnay(null)
      });
    }
  }

  const askDelete = () => {
    setOnay({
      question: "Silmek istediğinize emin misiniz?",
      onConfirm: ()=>{dispatch(deleteIzin(izin.id)); setOnay(null);},
      onCancel: ()=>setOnay(null)
    });
  }

  const askFormGetirdi = () => {
    setOnay({
      question: `Form ${!!izin.formGetirdimi ? "Getirmedi" : "Getirdi"} işaretlemek istediğinize emin misiniz?`,
      onCancel: () => setOnay(null),
      onConfirm: () => {
        setOnay(null);
        dispatch(toggleFormGetirdimi(!izin.formGetirdimi, izin.id));
      }
    })
  }

  const closeMe = () => {
    navigate("../..", {replace:true});
    dispatch(clearIzinErrors());
  }

  const saveGemiStartDate = () => {
    let gemiStartDate = gemiStartDateRef.current.value;
    if (gemiStartDate === null || gemiStartDate === '') {
      dispatch(addNotification("Gemiye çıkış tarihi hatalı!", "danger"));
      return;
    }
    setOnay({
      question: "Gemiye Çıkış Tarihini kaydetmek istediğinize emin misini?",
      onCancel: () => setOnay(null),
      onConfirm: () => {
        setOnay(null);
        dispatch(putGemiStartDate(gemiStartDate, izin.id));
      }
    });    
  }

  const saveGemiEndDate = () => {
    let gemiEndDate = gemiEndDateRef.current.value;
    if (gemiEndDate === null || gemiEndDate === '') {
      dispatch(addNotification("Gemiden iniş tarihi hatalı!", "danger"));
      return;
    }
    setOnay({
      question: "Gemiden iniş Tarihini kaydetmek istediğinize emin misini?",
      onCancel: () => setOnay(null),
      onConfirm: () => {
        setOnay(null);
        dispatch(putGemiEndDate(gemiEndDate, izin.id));
      }
    });    
  }

  return <Fragment>
    {onay && <Confirmation {...onay} />}
    {error && <ErrorMessage error={error} closeMe={closeMe} />}
    {isLoading && <LoadingWave open={true} />}
    {!!izin && <div className={classesRef.current.join(' ')}>
      <div className={styles.topBackArea}>
        <a href={backPath || '/izinler/menu'}>
          <ArrowLeftIcon height={30} width={30} className={styles.BackIcon} />
          <span>{backPathName || 'İzinler'}</span>
        </a>
      </div>
      <div className={styles.Card}>
        <div className={styles.CardHeader}>
          <h2>İzin Talebi</h2>
          <h4 className={styles.SubHeading}>
            {izin.izinType.name}
          </h4>
        </div>
        
        <h3>İzin Talebi {izinStatus}</h3>
        
        <table>
          <tbody>
            <tr>
              <td>İzin Talep Eden</td>
              <td>{izin.username}</td>
            </tr>
            {izin.createdDate && <tr>
              <td>Oluşturulma Zamanı</td>
              <td>{formatDate(izin.createdDate)}</td>
            </tr>}
            {izin.modifiedDate && <tr>
              <td>Değiştirilme Zamanı</td>
              <td>{formatDate(izin.modifiedDate)}</td>
            </tr>}
            {!!izin.gorevTuru && <tr>
              <td>Görev Türü</td>  
              <td>{izin.gorevTuruDiger || izin.gorevTuru.name}</td>  
            </tr>}
            {!!izin.gorevYeri && <tr>
              <td>Görev Yeri</td>  
              <td>{izin.gorevYeriShipName || izin.gorevYeriDiger || izin.gorevYeri.name}</td>  
            </tr>}
            {!!izin.eStartDate && <tr>
              <td>Ofisten Çıkış Zamanı</td>  
              <td>{formatDate(izin.eStartDate)}</td>  
            </tr>}
            {!!izin.gorevYeriShipName && <Fragment>
              <tr className={(guncelleyebilir && !izin.gemiStartDate) ? styles.Guncellenebilir : ''}>
                <td>Gemiye Çıkış Zamanı:</td>
                {(guncelleyebilir && !izin.gemiStartDate) ? 
                  <td className={styles.MultipleTD}>
                    <input ref={gemiStartDateRef} type='datetime-local' />
                    <button onClick={saveGemiStartDate} >Kaydet</button>
                  </td> :
                  <td>{!!izin.gemiStartDate ? formatDate(izin.gemiStartDate) : "Belli Değil"}</td>
                }
              </tr>
              <tr className={(guncelleyebilir && !izin.gemiEndDate) ? styles.Guncellenebilir : ''}>
                <td>Gemiden İniş Zamanı:</td>
                {(guncelleyebilir && !izin.gemiEndDate) ? 
                  <td className={styles.MultipleTD}>
                    <input ref={gemiEndDateRef} type='datetime-local' />
                    <button onClick={saveGemiEndDate} >Kaydet</button>
                  </td> :
                  <td>{!!izin.gemiEndDate ? formatDate(izin.gemiEndDate) : "Belli Değil"}</td>
                }
              </tr>
            </Fragment>}
            {!!izin.eEndDate && <tr>
              <td>Ofise Dönüş Zamanı</td>  
              <td>{formatDate(izin.eEndDate)}</td>  
            </tr>}
            {+izin.izinType.id === 2 && <tr>
              <td>İzin Formunu Teslim Etti mi?</td>  
              <td className={!izin.formGetirdimi ? styles.Danger : ''}>{+izin.formGetirdimi ? "EVET" : "HAYIR"}</td>  
            </tr>}
            {!!izin.explanation && <tr>
              <td className={styles.Explanation} colSpan={2}>{izin.explanation}</td>  
            </tr>}

            
          </tbody>
        </table>
        
        <div className={styles.Bolum}>
          <h3>Onaylar</h3>
          <ul>
            {izin.answers.map(iz => <IzinAnswer 
              key={iz.id} 
              answer={iz}
              forCancel={izin.status === "Canceling" || izin.status === "Canceled"}
            />)}
          </ul>
        </div>

        {+izin.userId === +user.id && (izin.status === "Approved" || izin.status === "Approving") && <div className={styles.IzinActions}>
          <Button btnType='Warn' onClick={()=>navigate(`../edit/${izin.id}`)} >Düzenle</Button>
          <Button btnType='Danger' onClick={askCancel} >iptal edilmesini iste</Button>
        </div>}

        {+authorizations.IZINLER > 0 &&
          <div className={styles.Bolum}>
            <h3>Yetkili İşlemleri</h3>
            <ul className={styles.YetkiliIslemler}>
              {+authorizations.IZINLER === 5 && 
                <Button btnType="Danger" onClick={askDelete}>DELETE</Button>}
              {+authorizations.IZIN_FORM_GETIRDIMI === 5 && +izin.izinType.id === 2 &&
                <Button btnType="Info" onClick={askFormGetirdi}>{!!izin.formGetirdimi ? "Form Getirmedi İşaretle" : "Form Getirdi İşaretle"}</Button>}
            </ul>
          </div>
        }
      </div>

      
      {!!izin.location && <Fragment>
        <h2 className={styles.LocationHeader}>İzin Talebi Yapılan Lokasyon</h2>
        <div className={styles.Map}>
          <MyMap location={izin.location} username={izin.username} />
        </div>
      </Fragment>}

      <div className={styles.Footer}>
        
      </div>
    </div>}
  </Fragment>
}

export default Izin;