import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.css';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { mapFleetMenuToggle } from '../../../../actions/mapSettings';
import { CogIcon, HomeIcon, LogoutIcon, MoonIcon } from '@heroicons/react/solid';
import { showSettingsToggle, showDayLightToggle } from '../../../../actions/mapSettings';
import { logout } from '../../../../actions/auth';
import { useNavigate } from 'react-router-dom';
import MenuItemShip from './MenuItemShip';

const MapFleetMenu = () => {
  const { isMenuOpen, showSettings, showDayLight } = useSelector(({mapSettings}) => mapSettings);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shipsData } = useSelector(({map}) => map);

  const cikis = () => {
    dispatch(logout());
    navigate('/');
  }

  return <div className={`${styles.FleetMenu} ${isMenuOpen ? styles.Open : ''}`} >
    <h3 className='baslik'>Menü</h3>
    <div 
      className={styles.ToggleMenuBtn} 
      onClick={()=>dispatch(mapFleetMenuToggle())}
    >
      <ArrowRightIcon className={styles.Icon} width={25} height={25} />
    </div>
    <div className={`${styles.SettingsWrapper} ${showSettings ? styles.Open : ''}`}>
      <h2>Ayarlar</h2>
      <div className={styles.ActionList}>
        <button onClick={()=>dispatch(showDayLightToggle())}>
          <MoonIcon width={20} color={showDayLight ? "yellow" : "#0d223d"} />
          <span>{`Gün Işığını ${showDayLight ? "Kapat" : "Göster"}`}</span>
        </button>
      </div>
    </div>
    <nav>
      <ul className={styles.ShipsList}>
        {!!shipsData && shipsData.features.map((ship, i) => <MenuItemShip key={i} ship={ship.properties} />)}
      </ul>
    </nav>
    <div className={styles.Bottom}>
      <button onClick={()=> dispatch(showSettingsToggle())}>
        <CogIcon width={25} color={showSettings ? '#df4f4f' : 'whitesmoke'}/>
        <span>{showSettings ? "Kapat" :  "Ayarlar"}</span>
      </button>
      <button onClick={()=>navigate('/')} >
        <HomeIcon width={25} color='whitesmoke' />
        <span>Ana Sayfa</span>
      </button>
      <button onClick={cikis}>
        <LogoutIcon width={25} color='#d4b8b8' />
        <span>Çıkış</span>
      </button>
      
    </div>
  </div>
}

export default MapFleetMenu;