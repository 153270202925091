import { combineReducers } from "redux";
import { AUTH_LOGOUT } from "./auth";

export const IZIN_FETCH_INIT = "IZIN_FETCH_INIT";
export const IZIN_FETCH_SUCCESS = "IZIN_FETCH_SUCCESS";
export const IZIN_FETCH_ERROR = "IZIN_FETCH_ERROR";
export const IZIN_SAVE_INIT = "IZIN_SAVE_INIT";
export const IZIN_SAVE_SUCCESS = "IZIN_SAVE_SUCCESS";
export const IZIN_SAVE_ERROR = "IZIN_SAVE_ERROR";
export const IZIN_DELETE_INIT = "IZIN_DELETE_INIT";
export const IZIN_DELETE_SUCCESS = "IZIN_DELETE_SUCCESS";
export const IZIN_DELETE_ERROR = "IZIN_DELETE_ERROR";

export const IZIN_CANCEL_INIT = "IZIN_CANCEL_INIT";
export const IZIN_CANCEL_SUCCESS = "IZIN_CANCEL_SUCCESS";
export const IZIN_CANCEL_ERROR = "IZIN_CANCEL_ERROR";

export const IZIN_ANSWER_INIT = "IZIN_ANSWER_INIT";
export const IZIN_ANSWER_SUCCESS = "IZIN_ANSWER_SUCCESS";
export const IZIN_ANSWER_ERROR = "IZIN_ANSWER_ERROR";

export const IZIN_EDITING_IZIN = "IZIN_EDITING_IZIN";
export const IZIN_EDITING_IZIN_KEEP = "IZIN_EDITING_IZIN_KEEP";
export const IZIN_CLEAR_ERRORS = "IZIN_CLEAR_ERRORS";

export const IZIN_GEMI_DATE_INIT = "IZIN_GEMI_DATE_INIT";
export const IZIN_GEMI_DATE_SUCCESS = "IZIN_GEMI_DATE_SUCCESS";
export const IZIN_GEMI_DATE_ERROR = "IZIN_GEMI_DATE_ERROR";

export const IZIN_FORM_GETIRDIMI_INIT = "IZIN_FORM_GETIRDIMI_INIT";
export const IZIN_FORM_GETIRDIMI_SUCCESS = "IZIN_FORM_GETIRDIMI_SUCCESS";
export const IZIN_FORM_GETIRDIMI_ERROR = "IZIN_FORM_GETIRDIMI_ERROR";


export const emptyEditingIzin = {
  isEmpty: true,
  errors: {
    izinTypeId:"İzin türü seçmediniz",
    eStartDate:"Başlangıç Zamanı Eksik",
    eEndDate:"Bitiş Zamanı eksik",
    explanation:"Açıklama Giriniz"
  },
  values: {
    izinTypeId:null,
    eStartDate:null,
    eStartTime: "09:00",
    eEndDate:null,
    eEndTime: "09:00",
    explanation:null
  },
  toucheds: {
    izinTypeId:false,
    eStartDate:false,
    eEndDate:false,
    explanation:false
  },
  formHasError: false,
  formIsTouched:false,
}

const createIzinReducer = () => {

  const izin = (state=null, action) => {
    switch(action.type){
      case IZIN_FETCH_SUCCESS:
      case IZIN_SAVE_SUCCESS:
      case IZIN_ANSWER_SUCCESS:
        return action.izin;
      case IZIN_GEMI_DATE_SUCCESS:
        return {...state, [action.dateType]:action.value};
      case IZIN_FORM_GETIRDIMI_SUCCESS:
        return {...state, formGetirdimi:action.formGetirdimi}
      case IZIN_DELETE_SUCCESS:
      case IZIN_FETCH_INIT:
      case IZIN_FETCH_ERROR:
      case AUTH_LOGOUT:
        return null;
      case IZIN_CANCEL_SUCCESS:
        if (!action.izin) return state;
        return action.izin;
        // eğer onaylayan yoksa izin null geliyor oyüzden
      default:
        return state;
    }
  }


  const error = (state=null, action) => {
    switch(action.type){
      case IZIN_FETCH_ERROR:
      case IZIN_SAVE_ERROR:
      case IZIN_DELETE_ERROR:
      case IZIN_CANCEL_ERROR:
      case IZIN_ANSWER_ERROR:
      case IZIN_GEMI_DATE_ERROR:
      case IZIN_FORM_GETIRDIMI_ERROR:
        return action.error;
      case IZIN_SAVE_INIT:
      case IZIN_DELETE_INIT:
      case IZIN_CANCEL_INIT:
      case IZIN_FETCH_INIT:
      case IZIN_ANSWER_INIT:
      case IZIN_SAVE_SUCCESS:
      case IZIN_DELETE_SUCCESS:
      case IZIN_CANCEL_SUCCESS:
      case IZIN_FETCH_SUCCESS:
      case IZIN_ANSWER_SUCCESS:
      case IZIN_GEMI_DATE_SUCCESS:
      case IZIN_GEMI_DATE_INIT:
      case IZIN_FORM_GETIRDIMI_INIT:
      case IZIN_FORM_GETIRDIMI_SUCCESS:
      case IZIN_CLEAR_ERRORS:
        return null;
      default:
        return state;
    }
  }

  const isLoading = (state=false, action) => {
    switch(action.type) {
      case IZIN_FETCH_INIT:
      case IZIN_SAVE_INIT:
      case IZIN_DELETE_INIT:
      case IZIN_CANCEL_INIT:
      case IZIN_ANSWER_INIT:
      case IZIN_GEMI_DATE_INIT:
      case IZIN_FORM_GETIRDIMI_INIT:
        return true;
      case IZIN_SAVE_SUCCESS:
      case IZIN_DELETE_SUCCESS:
      case IZIN_CANCEL_SUCCESS:
      case IZIN_FETCH_SUCCESS:
      case IZIN_ANSWER_SUCCESS:
      case IZIN_SAVE_ERROR:
      case IZIN_DELETE_ERROR:
      case IZIN_CANCEL_ERROR:
      case IZIN_FETCH_ERROR:
      case IZIN_ANSWER_ERROR:
      case IZIN_GEMI_DATE_SUCCESS:
      case IZIN_GEMI_DATE_ERROR:
      case IZIN_FORM_GETIRDIMI_SUCCESS:
      case IZIN_FORM_GETIRDIMI_ERROR:
        return false;
      default:
        return state;
    }
  }

  const editingIzin = (state=emptyEditingIzin, action) => {
    switch(action.type){
      case IZIN_EDITING_IZIN:
        return action.editingIzin;
      case IZIN_SAVE_SUCCESS:
        return emptyEditingIzin;
      default:
        return state;
    }
  }


  return combineReducers({
    izin,
    error,
    isLoading,
    editingIzin
  });
}

export default createIzinReducer();