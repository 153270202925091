import { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { clearErrors, setDayLightData } from "../../actions/map";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";
import MapFleet from "./mapviews/map_fleet/MapFleet";
import DayLight from "../../utils/daylight.js";

const Map = () => {
  const { error } = useSelector(({map}) => map);
  const { showDayLight } = useSelector(({mapSettings}) => mapSettings);
  const dispatch = useDispatch();

  useEffect(()=>{
    if (showDayLight) dispatch(setDayLightData(new DayLight()));
    const timer = setInterval(()=>{
      dispatch(setDayLightData(new DayLight()));
    }, 60000);
    if (!showDayLight) clearInterval(timer);
    return () => clearInterval(timer);
  }, [showDayLight, dispatch]);

  return <Fragment>
    {error && <ErrorMessage error={error} closeMe={()=>dispatch(clearErrors())} />}
    <Routes>
      {/* <Route path="/" element={<MapboxDeneme/>}/> */}
      <Route path="/fleet" element={<MapFleet/>}/>
    </Routes>
  </Fragment>
}

export default Map;