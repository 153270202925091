import styles from './FormGetirmeyenler.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect } from 'react';
import { clearIzinlerErrors, fetchFormGetirmeyenler } from '../../../../actions/izinler';
import ErrorMessage from '../../../../components/errorMessage/ErrorMessage';
import LoadingWave from '../../../../components/loading_spinners/LoadingWave';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import IzinListItem from '../izin_list_item/IzinListItem';


const FormGetirmeyenler = () => {
  const {formGetirmeyenler, error, isLoading} = useSelector(({izinler}) => izinler);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(fetchFormGetirmeyenler());
  }, [dispatch]);


  const closeMe = () => {
    dispatch(clearIzinlerErrors());
  }

  return <Fragment>
    {error && <ErrorMessage error={error} closeMe={closeMe} />}
    {isLoading && <LoadingWave open={true} />}
    <div className={styles.IzinlerArea}>
      <div className={styles.topBackArea}>
        <a href={'/izinler/menu'}>
          <ArrowLeftIcon height={30} width={30} className={styles.BackIcon} />
          <span>{'Menü'}</span>
        </a>
      </div>
      <h1>
        <span>{formGetirmeyenler.length} Adet Form Getirmeyen var.</span>
      </h1>
      <ul>
        {formGetirmeyenler.map(el => <IzinListItem key={el.id} izin={el}/>)}
      </ul>
    </div>
  </Fragment>
}

export default FormGetirmeyenler;