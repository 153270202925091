export const getBounds = features => {
  let minLng = undefined;
  let minLat = undefined;
  let maxLng = undefined;
  let maxLat = undefined;
  
  features.forEach(el => {
    minLng = minLng === undefined || minLng > +el.properties.lng ? +el.properties.lng : minLng;
    minLat = minLat === undefined || minLat > +el.properties.lat ? +el.properties.lat : minLat;
    maxLng = maxLng === undefined || maxLng < +el.properties.lng ? +el.properties.lng : maxLng;
    maxLat = maxLat === undefined || maxLat < +el.properties.lat ? +el.properties.lat : maxLat;
  });

  return [
    [minLng, minLat],
    [maxLng, maxLat]
  ]
}