import { NOTIFICATION_ADD, NOTIFICATION_CLEAR_ALL, NOTIFICATION_REMOVE } from "../reducers/notification";



export const addNotification = (text, type='info', duration=5000) => dispatch => {
  const id = new Date().getTime();
  const notification = {id, text, type, duration};
  dispatch({type:NOTIFICATION_ADD, notification});
}

export const removeNotification = (id) => dispatch => {
  dispatch({type:NOTIFICATION_REMOVE, id});
}

export const clearAllNotifications = () => dispatch => {
  dispatch({type:NOTIFICATION_CLEAR_ALL});
}