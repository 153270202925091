import { combineReducers } from "redux";

export const SETTINGS_LEFTMENU_TOGGLE = 'SETTINGS_LEFTMENU_TOGGLE';


const defaultState = {
  isMenuOpen: false
}

const initState = localStorage.getItem('settings') ?
  JSON.parse(localStorage.getItem('settings')) :
  defaultState;

const createSettingsReducer = () => {

  const isMenuOpen = (state=initState.isMenuOpen, action) => {
    switch(action.type){
      case SETTINGS_LEFTMENU_TOGGLE:
        initState.isMenuOpen = !state;
        localStorage.setItem('settings', JSON.stringify(initState));
        return !state;
      default:
        return state;
    }
  }


  return combineReducers({
    isMenuOpen,
  })
}


export default createSettingsReducer();