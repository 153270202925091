import { combineReducers } from "redux";

export const VISITINGS_FETCH_ALL_INIT = 'VISITINGS_FETCH_ALL_INIT';
export const VISITINGS_FETCH_ALL_SUCCESS = 'VISITINGS_FETCH_ALL_SUCCESS';
export const VISITINGS_FETCH_ALL_ERROR = 'VISITINGS_FETCH_ALL_ERROR';
export const VISITINGS_CLEAR_ERRORS = 'VISITINGS_CLEAR_ERRORS';
export const VISITINGS_PICKED_SHIPID = 'VISITINGS_PICKED_SHIPID';
export const VISITINGS_PICKED_MONTHID = 'VISITINGS_PICKED_MONTHID';
export const VISITINGS_WARN_DAY_COUNT = 'VISITINGS_WARN_DAY_COUNT';
export const VISITINGS_RESET = 'VISITINGS_RESET';


const initWarnDayCount = localStorage.getItem("warnDayCount") ? localStorage.getItem("warnDayCount") : 40;

const createVisitingsReducer = () => {

  const warnDay = (state=initWarnDayCount, action) => {
    switch(action.type){
      case VISITINGS_WARN_DAY_COUNT:
        localStorage.setItem("warnDayCount", action.value);
        return action.value;
      default:
        return state;
    }
  }

  const pickedMonthId = (state=null, action) => {
    switch(action.type){
      case VISITINGS_PICKED_MONTHID:
        return action.mId === state ? null : action.mId;
      case VISITINGS_RESET:
        return null;
      default:
        return state;
    }
  }  
  
  const pickedShipId = (state=null, action) => {
    switch(action.type){
      case VISITINGS_PICKED_SHIPID:
        return action.shipId === state ? null : action.shipId;
      case VISITINGS_RESET:
        return null;
      default:
        return state;
    }
  }  

  const visitings = (state={}, action) => {
    switch(action.type){
      case VISITINGS_FETCH_ALL_SUCCESS:
        const {value, year, month} = action;
        const copyState = {...state};
        Object.keys(value).forEach(shipId => {
          if (!copyState[shipId]){
            copyState[shipId] = {[`${year}-${month < 10 ? "0" : ""}${month}`]: value[shipId]}
          }else{
            copyState[shipId] = {...copyState[shipId], [`${year}-${month < 10 ? "0" : ""}${month}`]:value[shipId]};
          }
        });
        return copyState;
      case VISITINGS_FETCH_ALL_ERROR:
      case VISITINGS_RESET:
        return {};
      case VISITINGS_FETCH_ALL_INIT:
      default:
        return state;
    }
  }

  const monthSet = (state=new Set(), action) => {
    switch(action.type){
      case VISITINGS_FETCH_ALL_SUCCESS:
        const {year, month} = action;
        return new Set([...state, `${year}-${month < 10 ? "0" : ""}${month}`].sort());
      case VISITINGS_RESET:
        return new Set();
      default:
        return state;
    }
  }

  const error = (state=null, action) => {
    switch(action.type){
      case VISITINGS_FETCH_ALL_ERROR:
        return action.error;
      case VISITINGS_FETCH_ALL_SUCCESS:
      case VISITINGS_FETCH_ALL_INIT:
      case VISITINGS_CLEAR_ERRORS:
      case VISITINGS_RESET:
        return null;
      default:
        return state;
    }
  }

  const isLoading = (state=false, action) => {
    switch(action.type){
      case VISITINGS_FETCH_ALL_INIT:
        return true;
      case VISITINGS_FETCH_ALL_ERROR:
      case VISITINGS_FETCH_ALL_SUCCESS:
      case VISITINGS_RESET:
        return false;
      default:
        return state;
    }
  }

  const lastFetchedYear = (state=null, action) => {
    switch(action.type){
      case VISITINGS_FETCH_ALL_SUCCESS:
        return action.year;
      case VISITINGS_RESET:
        return null;
      default:
        return state;
    }
  }

  const lastFetchedMonth = (state=null, action) => {
    switch(action.type){
      case VISITINGS_FETCH_ALL_SUCCESS:
        return action.month;
      case VISITINGS_RESET:
        return null;
      default:
        return state;
    }
  }



  return combineReducers({
    error,
    isLoading,
    visitings,
    pickedShipId,
    pickedMonthId,
    lastFetchedMonth,
    lastFetchedYear,
    monthSet,
    warnDay,
  });
}

export default createVisitingsReducer();