import { combineReducers } from "redux";

export const IZINLER_WEEKLY_FETCH_INIT = "IZINLER_WEEKLY_FETCH_INIT";
export const IZINLER_WEEKLY_FETCH_SUCCESS = "IZINLER_WEEKLY_FETCH_SUCCESS";
export const IZINLER_WEEKLY_FETCH_ERROR = "IZINLER_WEEKLY_FETCH_ERROR";
export const IZINLER_WEEKLY_PICKED_IZINID = "IZINLER_WEEKLY_PICKED_IZINID";

export const IZINLER_WEEKLY_FILTER_TOGGLE = "IZINLER_WEEKLY_FILTER_TOGGLE";
export const IZINLER_WEEKLY_SET_WEEKNR = "IZINLER_WEEKLY_SET_WEEKNR";
export const IZINLER_WEEKLY_SET_YEAR = "IZINLER_WEEKLY_SET_YEAR";

export const IZINLER_WEEKLY_CLEAR_ERRORS = "IZINLER_WEEKLY_CLEAR_ERRORS";

const initFilterOptions = {
  gorevYeriId: new Set(),
  izinTypeId: new Set(),
  gorevYeriShipId: new Set()
}

const createIzinlerWeeklyReducer = () => {

  const error = (state=null, action) => {
    switch(action.type){
      case IZINLER_WEEKLY_FETCH_ERROR:
        return action.error;
      case IZINLER_WEEKLY_FETCH_INIT:
      case IZINLER_WEEKLY_FETCH_SUCCESS:
      case IZINLER_WEEKLY_CLEAR_ERRORS:
        return null;
      default:
        return state;
    }
  }

  const isLoading = (state=false, action) => {
    switch(action.type){
      case IZINLER_WEEKLY_FETCH_INIT:
        return true;
      case IZINLER_WEEKLY_FETCH_ERROR:
      case IZINLER_WEEKLY_FETCH_SUCCESS:
        return false;
      default:
        return state;
    }
  }

  const weeklyData = (state=null, action) => {
    switch(action.type){
      case IZINLER_WEEKLY_FETCH_SUCCESS:
        return action.value;
      case IZINLER_WEEKLY_FETCH_INIT:
      case IZINLER_WEEKLY_FETCH_ERROR:
        return null;
      default:
        return state;
    }
  }

  const pickedIzinId = (state=null, action) => {
    switch (action.type) {
      case IZINLER_WEEKLY_PICKED_IZINID:
        const id = +action.id;
        return state === id ? null : id;
      case IZINLER_WEEKLY_FETCH_INIT:
        return null;
      default:
        return state;
    }
  }

  const filterOptions = (state=initFilterOptions, action) => {
    switch(action.type){
      case IZINLER_WEEKLY_FETCH_SUCCESS:
        const value = action.value;
        const newState = {
          gorevYeriId: new Set(),
          izinTypeId: new Set(),
          gorevYeriShipId: new Set()
        };
        if (!!value) Object.keys(value).forEach(day => {
          value[day].forEach(izin => {
            if (+izin.izinType.id !== 1) newState.izinTypeId.add(+izin.izinType.id);
            if (!!izin.gorevYeri && +izin.gorevYeri.id !== 1) newState.gorevYeriId.add(+izin.gorevYeri.id);
            if (!!izin.gorevYeriShipId) newState.gorevYeriShipId.add(+izin.gorevYeriShipId);
          });
        });
        return newState;
      case IZINLER_WEEKLY_FETCH_INIT:
      case IZINLER_WEEKLY_FETCH_ERROR:
        return initFilterOptions;
      default:
        return state;
    }
  }
  
  const filterHidings = (state=initFilterOptions, action) => {
    switch(action.type){
      case IZINLER_WEEKLY_FILTER_TOGGLE:
        const {forWhat, id} = action;
        const copySet = new Set([...state[forWhat]]);
        if (copySet.has(id)) {
          copySet.delete(id);
        }else{
          copySet.add(id);
        }
        return {...state, [forWhat]:copySet};
      case IZINLER_WEEKLY_FETCH_INIT:
      case IZINLER_WEEKLY_FETCH_ERROR:
      case IZINLER_WEEKLY_FETCH_SUCCESS:        
        return initFilterOptions;
      default:
        return state;
    }
  }

  return combineReducers({
    error,
    isLoading,
    pickedIzinId,
    weeklyData,
    filterOptions,
    filterHidings,
  });
}

export default createIzinlerWeeklyReducer();