import styles from './LoadingWave.module.css';

const LoadingWave = ({open, msg}) => {

  return <div className={`${styles.Wrapper} ${open ? styles.Active : styles.Passive}`}>
    <div className={styles.ldsRipple}>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <h3>{msg || "Please Wait..."}</h3>
  </div>
}

export default LoadingWave;