import styles from './styles.module.css';
import logo from '../../assets/logo/logo.png';

const NotFoundPage = () => {


  return <main className={styles.Main}>
    <div className={styles.LogoContainer}>
      <img src={logo} alt="Scot Tanker" />
    </div>
    <div>
      <h1 style={{fontSize:'100px'}}>404</h1>
      <h2 style={{fontSize:'36px'}}>Page Not Found</h2>
    </div>
    <a 
      className={styles.Link}
      href='/'>
      Ana Sayfa
    </a>
  </main>
  
}

export default NotFoundPage;