import styles from './UserIzinler.module.css';
import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { clearIzinlerErrors, fetchBekleyenIzinler, fetchTamamlananIzinler, resetIzinlerData, toggleFilters } from '../../../../actions/izinler';
import IzinListItem from '../izin_list_item/IzinListItem';
import ErrorMessage from '../../../../components/errorMessage/ErrorMessage';
import LoadingWave from '../../../../components/loading_spinners/LoadingWave';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { fetchIzinTypes } from '../../../../actions/izinBasics';


const FilterOptionsButton = ({forWhat, value}) => {
  const dispatch = useDispatch();
  const { filterSelecteds } = useSelector(({izinler}) => izinler);
  const { izinTypes } = useSelector(({izinlerBasics}) => izinlerBasics);

  const isActive = filterSelecteds[forWhat].has(value);

  let text = "text belli değil";
  if (forWhat === "statuses"){
    if (value === "Approved") text = "Onaylananlar";
    if (value === "Canceled") text = "İptal Edilenler";
    if (value === "Declined") text = "Reddedilenler";
  
  }else if (forWhat === "izinTypes"){
    const izinType = izinTypes.find(el => +el.id === +value);
    if (!!izinType) text = izinType.name;
  
  }

  return <button
    onClick={()=>dispatch(toggleFilters(forWhat, value))}
    className={`${styles.FilterOptionButton} ${isActive ? styles.Active : ''}`}
  >
    {isActive ? <EyeIcon width={20} height={20} /> : <EyeOffIcon  width={20} height={20} />}
    <span>{text}</span>
  </button>
}

const UserIzinler = () => {
  const { 
    bekleyenIzinlerOfUser, 
    tamamlananIzinlerOfUser, 
    error, 
    isLoading, 
    filterOptions, 
    filterSelecteds,
    kalanIzinSayisi
  } = useSelector(({izinler}) => izinler);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const {userId} = useParams();
  const [ filteredIzinler, setFilteredIzinler ] = useState([]);

  useEffect(()=>{
    if (!!userId) {
      dispatch(fetchIzinTypes());
      dispatch(fetchBekleyenIzinler(userId));
      dispatch(fetchTamamlananIzinler(userId, true));
    }
    return () => dispatch(resetIzinlerData());
  }, [dispatch, userId]);

  const dahaFazlaYukle = () => {
    dispatch(fetchTamamlananIzinler(userId));
  };

  const closeMe = () => {
    dispatch(clearIzinlerErrors());
    navigate('../menu', {replace:true});
  }


  useEffect(()=>{
    const izinler = [];
    tamamlananIzinlerOfUser.forEach(izin => {
      if (
        filterSelecteds.statuses.has(izin.status)
        && filterSelecteds.izinTypes.has(izin.izinType.id)
      ) {
        izinler.push(izin);
      }
    });
    setFilteredIzinler(izinler);
  }, [filterSelecteds, tamamlananIzinlerOfUser]);


  return <Fragment>
    {error && <ErrorMessage error={error} closeMe={closeMe} />}
    {isLoading && <LoadingWave open={true} />}
    <div className={styles.UserIzinler}>
      
      {bekleyenIzinlerOfUser.length>0 && <div className={styles.IzinlerArea}>
        <h1>
          <span>Bekleyen İzinler</span>
          <span>{bekleyenIzinlerOfUser.length} Adet İzin</span>
        </h1>
        <ul>
          {bekleyenIzinlerOfUser.map(el => <IzinListItem key={el.id} izin={el}/>)}
        </ul>
      </div>}

      <div className={styles.IzinlerArea}>
        <h1>
          <span>Tamamlanan İzinler</span>
          <span>Toplam {filteredIzinler.length} Adet İzin</span>
        </h1>
        <div className={styles.FilterArea}>
          <div className={styles.FilterArea_Row}>
            {[...filterOptions.statuses].map(el => <FilterOptionsButton key={el} forWhat={'statuses'} value={el} />)}
            {[...filterOptions.izinTypes].map(el => <FilterOptionsButton key={el} forWhat={'izinTypes'} value={el} />)}
          </div>
        </div>
        <ul>
          {filteredIzinler.map(el => <IzinListItem key={+el.id} izin={el}/>)}
        </ul>
      </div>
      <div className={styles.Actions}>
        {+kalanIzinSayisi !== 0 && <button 
          onClick={dahaFazlaYukle} 
        >Daha Fazla Göster</button>}
      </div>

    </div>
  </Fragment>
}

export default UserIzinler;