import { 
  AUTH_CHANGE_PASSWORD_ERROR,
  AUTH_CHANGE_PASSWORD_INIT,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CLEAR_ERRORS,
  AUTH_FORGOT_PASSWORD_ERROR,
  AUTH_FORGOT_PASSWORD_INIT, 
  AUTH_FORGOT_PASSWORD_SUCCESS, 
  AUTH_LOGIN_ERROR, 
  AUTH_LOGIN_INIT, 
  AUTH_LOGIN_SUCCESS, 
  AUTH_LOGOUT, 
  AUTH_RESET_LINK_DIED, 
  AUTH_RESET_PASSWORD_ERROR, 
  AUTH_RESET_PASSWORD_INIT, 
  AUTH_RESET_PASSWORD_SUCCESS, 
  AUTH_RESET_REQUEST_ENDING,
  AUTH_USER_TYPE 
} from "../reducers/auth";
import myfetch from "../utils/myFetch";
import success from '../assets/sounds/success.mp3';
import failed from '../assets/sounds/failed.mp3';

export const login = (email, password) => async (dispatch, getState) => {
  dispatch({type:AUTH_LOGIN_INIT});
  const audio = new Audio();
  try {
    const userType = getState().auth.userType;

    const data = await dispatch(myfetch({
      url: userType === 'OFFICE' ? '/users' : '/shipusers', 
      method: 'POST', 
      body: { email, password, userType }
    }));
    audio.src = success;
    audio.play().then(dispatch({type:AUTH_LOGIN_SUCCESS, ...data}));
  } catch (error) {
    audio.src = failed;
    audio.play().then(dispatch({type:AUTH_LOGIN_ERROR, error}));
  }
}

export const resetPassword = (resetKey, newPassword, callback) => async (dispatch, getState) => {
  dispatch({type:AUTH_RESET_PASSWORD_INIT});
  try {
    const userType = getState().auth.userType;
    const data = await dispatch(myfetch({
      url: userType === 'OFFICE' ? '/users' : '/shipusers', 
      method: 'PUT', 
      body: { resetKey, newPassword }
    }));
    if (!!data && data.isResetLinkDied) {
      dispatch({type: AUTH_RESET_LINK_DIED});      
    }
    dispatch({type: AUTH_RESET_PASSWORD_SUCCESS});
    if (callback) callback();
  } catch (error) {
    dispatch({type:AUTH_RESET_PASSWORD_ERROR, error});
  }
}

export const logout = () => dispatch => {
  dispatch({type:AUTH_LOGOUT});
}

export const forgotPassword = (email) => async (dispatch, getState) => {
  dispatch({type:AUTH_FORGOT_PASSWORD_INIT});
  try {
    const userType = getState().auth.userType;
    await dispatch(myfetch({
      url: userType === 'OFFICE' ? '/users' : '/shipusers', 
      method: 'PUT', 
      body: { email, userType }
    }));
    dispatch({type: AUTH_FORGOT_PASSWORD_SUCCESS});
  } catch (error) {
    dispatch({type:AUTH_FORGOT_PASSWORD_ERROR, error});
  }
}

export const changePassword = (oP, nP, callback) => async (dispatch, getState) => {
  dispatch({type: AUTH_CHANGE_PASSWORD_INIT});
  try {
    const userType = getState().auth.userType;
    await dispatch(myfetch({
      url: userType === 'OFFICE' ? '/users' : '/shipusers', 
      method: 'PATCH', 
      body: { oP, nP }
    }));
    dispatch({type: AUTH_CHANGE_PASSWORD_SUCCESS});
    if (callback) callback();
  } catch (error) {
    dispatch({type:AUTH_CHANGE_PASSWORD_ERROR, error});
  }
}

export const changeUserType = userType => dispatch => {
  dispatch({type:AUTH_USER_TYPE, userType});
}

export const clearAuthErrors = () => dispatch => {
  dispatch({type:AUTH_CLEAR_ERRORS});
}

export const resetRequestRefresh = () => dispatch => {
  dispatch({type:AUTH_RESET_REQUEST_ENDING, value:null});
}