import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchShips } from '../../../../actions/ships';
import { clearErrors, fetchVisitings, resetVisitings, setWarnDayCount } from '../../../../actions/visitings';
import ErrorMessage from '../../../../components/errorMessage/ErrorMessage';
import LoadingWave from '../../../../components/loading_spinners/LoadingWave';
import styles from './Visitings.module.css';


const IzinBox = ({izin}) => {
  const arr1 = izin.eStartDate.split(' ')[0].split('-');
  const tarih = `(${arr1[2]}/${arr1[1]})`;
  const navigate = useNavigate();

  const izineGit = () => {
    navigate(`../izin/${izin.id}`, {replace:false});
  }

  const classes = [styles.IzinBox];
  if (izin.status === "Approving") {
    classes.push(styles.Approving)
  }else if(+izin.deptId === 4){
    classes.push(styles.Engine);
  }else if(+izin.deptId === 2 || +izin.deptId === 3){
    classes.push(styles.Deck);
  }


  return <ul 
      onDoubleClick={izineGit} 
      className={classes.join(' ')}
    >
      <li>{izin.username}</li>
      <li>{tarih}</li>
    </ul>
}

const ShipVisitings = ({shipId, list, warnDay}) => {
  const { monthSet, pickedShipId, pickedMonthId } = useSelector(({visitings}) => visitings);
  const { ships } = useSelector(({ships}) => ships);
  const ship = ships.find(el => +el.id === +shipId);

  
  const months = [...monthSet].sort();
  const currentMonthId = (new Date()).getFullYear() + "-" + ((+((new Date()).getMonth())+1) < 10 ? "0" : "") + (+((new Date()).getMonth())+1);
  

  let lastVisit = null;
  for (let a = months.length-1; a >= 0 ; a--) {
    if (!!lastVisit) break;
    const visitsInMonth = list[months[a]];
    if (!!visitsInMonth && visitsInMonth.length > 0){
      lastVisit = visitsInMonth[0];
    }
  }

  let errorForCurrentMonth = !lastVisit;
  let warnMonthId = null;
  let warnText = "";
  
  if (!!lastVisit){
    const dt = new Date(lastVisit.eStartDate);
    dt.setDate(dt.getDate() + warnDay*1);

    if (dt.getTime() < (new Date()).getTime()) {
      errorForCurrentMonth = true;
    }else{
      warnText = dt.getDate() + "/" + (+dt.getMonth()+1) + " da " + warnDay + " gün doluyor!";
    }
    
    warnMonthId = (new Date(dt)).getFullYear() + "-" + ((+((new Date(dt)).getMonth())+1) < 10 ? "0" : "") + (+((new Date(dt)).getMonth())+1);
  }
  

  return <tr className={`${styles.GemiRow} ${+shipId === +pickedShipId ? styles.ActiveShip : ""}`}>
    <td key={0} className={styles.ShipName}>{!!ship && ship.name.replace("SCOT ", "")}</td>
    {!!monthSet &&
      [...monthSet].map(mId => <td 
        key={mId} 
        className={`${styles.MonthCell} ${+mId === +pickedMonthId ? styles.ActiveMonth : ''}`}
      > 
        {errorForCurrentMonth && mId===currentMonthId && <div className={styles.Danger}><span>Planlama Yap</span></div>}
        {!errorForCurrentMonth && !!warnMonthId && mId===warnMonthId && <div className={styles.Warning}><span>{warnText}</span></div>}
        {!!list[mId] && list[mId].map(izin => <IzinBox key={izin.id} izin={izin} />)}
      </td>)
    }
  </tr>
}



const Visitings = () => {
  const { visitings, isLoading, monthSet, error, lastFetchedYear, lastFetchedMonth, warnDay } = useSelector(({visitings}) => visitings);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [content, setContent] = useState(null);
  const [ prevMonth, setPrevMonth ] = useState("Bir Önceki Ayı")

  useEffect(()=>{
    const newContent = [];
    Object.keys(visitings).forEach(shipId => {
      newContent.push(<ShipVisitings key={shipId} shipId={shipId} list={visitings[shipId]} warnDay={warnDay} />);
    });
    setContent(newContent);
  }, [visitings, warnDay]);

  useEffect(()=>{
    dispatch(fetchShips());
    let month = (new Date()).getMonth() * 1 + 3;
    let year = (new Date()).getFullYear() * 1;
    month = month > 12 ? month%12 : month
    year = month > 12 ? year + 1 : year;

    dispatch(fetchVisitings(year, month, 5));

    return () => dispatch(resetVisitings());
  }, [dispatch]);


  const loadPrevMonthData = ()=>{
    if (!lastFetchedMonth || !lastFetchedYear) return;
    const month = +lastFetchedMonth === 1 ? 12 : +lastFetchedMonth-1;
    const year = +lastFetchedMonth === 1 ? +lastFetchedYear-1 : +lastFetchedYear;
    dispatch(fetchVisitings(year, month));
  };


  const closeMe = useCallback(()=>{
    dispatch(clearErrors());
    navigate('../menu');
  }, [dispatch, navigate]);

  const format = (mId) => {
    const aylar = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
    const arr = mId.split("-");
    return aylar[arr[1]-1] + " " + arr[0].substr(2,2);
  }

  useEffect(()=>{
    if (!lastFetchedMonth || !lastFetchedYear) return;
    const aylar = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
    const m = +lastFetchedMonth === 1 ? 12 : +lastFetchedMonth-1;
    const y = m === 12 ? +lastFetchedYear-1 : lastFetchedYear
    setPrevMonth(aylar[m-1] + " " + y);
  }, [lastFetchedMonth, lastFetchedYear]);
  

  return <Fragment>
    {error && <ErrorMessage error={error} closeMe={closeMe} />}
    {isLoading && <LoadingWave open={true} />}
    <div className={styles.Visitings}>
      <div className={styles.Header}>
        <h2>Gemi Ziyaretleri Tablosu</h2>
        <div className={styles.WarnDaySelect}>
          <label htmlFor='warnDayCount'>Uyarı Gün</label>
          <select id='warnDayCount' name='warnDayCount' value={warnDay} onChange={e => dispatch(setWarnDayCount(e.target.value))}>
            <option>30</option>
            <option>40</option>
            <option>50</option>
            <option>60</option>
            <option>70</option>
            <option>80</option>
            <option>90</option>
          </select>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Gemiler</th>
            {!!monthSet && [...monthSet].map(mId => <th key={mId}>{format(mId)}</th>)}
          </tr>
        </thead>
        <tbody>
          {content}
        </tbody>
      </table>

      <div className={styles.Actions}>
        <button onClick={loadPrevMonthData}>{`${prevMonth} getir`}</button>
      </div>

    </div>
  </Fragment>
}

export default Visitings;