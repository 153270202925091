import Modal from '../modal/Modal';
import styles from './ErrorMessage.module.css';
import { XIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
/* import { useDispatch, useSelector } from "react-redux";
import { closePendingError } from "../../actions/app"; */

const ErrorMessage = props => {
  /* const error = useSelector(({app}) => app.pendingError);
  const dispatch = useDispatch();
  if (!error) return null;

  const closeMe = () => dispatch(closePendingError()); */

  //if (props.error === null) return null;

  return <Modal>
    <div className={styles.ErrorMessage}>
      <div className={styles.Heading}>
        <h3>{props.header || 'Scoty App'}</h3>
        <div className={styles.CloseButtonBox}>
          <XIcon className={styles.CloseButton} width={20} onClick={props.closeMe} />
        </div>
      </div>
      <div className={styles.Body}>
        <ExclamationCircleIcon width={60} height={60} style={{color:'darkRed', minWidth:'40px'}} />
        <p>
          {props.error.message || 'Unknown Error'}
        </p>
      </div>
      <div className={styles.Actions}>
        <button autoFocus onClick={props.closeMe} >
          {props.noButtonText || 'Close'}
        </button>
      </div>
    </div>
  </Modal>
}

export default ErrorMessage;