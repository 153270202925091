import { IZINLER_BASICS_FETCH_GOREV_TURLERI_ERROR, IZINLER_BASICS_FETCH_GOREV_TURLERI_INIT, IZINLER_BASICS_FETCH_GOREV_TURLERI_SUCCESS, IZINLER_BASICS_FETCH_GOREV_YERLERI_ERROR, IZINLER_BASICS_FETCH_GOREV_YERLERI_INIT, IZINLER_BASICS_FETCH_GOREV_YERLERI_SUCCESS, IZINLER_BASICS_FETCH_TYPES_ERROR, IZINLER_BASICS_FETCH_TYPES_INIT, IZINLER_BASICS_FETCH_TYPES_SUCCESS } from "../reducers/izinlerBasics";
import myfetch from "../utils/myFetch";

export const fetchIzinTypes = () => async dispatch => {
  dispatch({type:IZINLER_BASICS_FETCH_TYPES_INIT});
  try {
    const izinTypes = await dispatch(myfetch({url:'/izinler', params:{izinTypes:1}}));
    dispatch({type:IZINLER_BASICS_FETCH_TYPES_SUCCESS, izinTypes});
  } catch (error) {
    dispatch({type:IZINLER_BASICS_FETCH_TYPES_ERROR, error});
  }
}

export const fetchIzinGorevTurleri = () => async dispatch => {
  dispatch({type:IZINLER_BASICS_FETCH_GOREV_TURLERI_INIT});
  try {
    const gorevTurleri = await dispatch(myfetch({url:'/izinler', params:{gorevTurleri:1}}));
    dispatch({type:IZINLER_BASICS_FETCH_GOREV_TURLERI_SUCCESS, gorevTurleri});
  } catch (error) {
    dispatch({type:IZINLER_BASICS_FETCH_GOREV_TURLERI_ERROR, error});
  }
}

export const fetchIzinGorevYerleri = () => async dispatch => {
  dispatch({type:IZINLER_BASICS_FETCH_GOREV_YERLERI_INIT});
  try {
    const gorevYerleri = await dispatch(myfetch({url:'/izinler', params:{gorevYerleri:1}}));
    dispatch({type:IZINLER_BASICS_FETCH_GOREV_YERLERI_SUCCESS, gorevYerleri});
  } catch (error) {
    dispatch({type:IZINLER_BASICS_FETCH_GOREV_YERLERI_ERROR, error});
  }
}