import Button from '../button/Button';
import Modal from '../modal/Modal';
import styles from './Confirmation.module.css';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';


const Confirmation = ({ question, onConfirm, onCancel }) => {

  return <Modal>
    <div className={styles.Confirmation}>
      <p>
        <QuestionMarkCircleIcon width={100} height={50} />
        <span color='#d6e9fa'>{question}</span>
      </p>
      <div className={styles.Actions}>
        <Button onClick={onConfirm}>Evet</Button>
        <Button btnType='Danger' onClick={onCancel}>İptal</Button>
      </div>
    </div>
  </Modal>
}

export default Confirmation;