
import { 
  DAHILI_NUMARALAR_CLEAR_ERRORS,
  DAHILI_NUMARALAR_DELETE_ERROR,
  DAHILI_NUMARALAR_DELETE_INIT,
  DAHILI_NUMARALAR_DELETE_SUCCESS,
  DAHILI_NUMARALAR_FETCH_ALL_ERROR, 
  DAHILI_NUMARALAR_FETCH_ALL_INIT, 
  DAHILI_NUMARALAR_FETCH_ALL_SUCCESS, 
  DAHILI_NUMARALAR_FETCH_ONE_ERROR, 
  DAHILI_NUMARALAR_FETCH_ONE_INIT,
  DAHILI_NUMARALAR_FETCH_ONE_SUCCESS,
  DAHILI_NUMARALAR_SAVE_ERROR,
  DAHILI_NUMARALAR_SAVE_INIT,
  DAHILI_NUMARALAR_SAVE_SUCCESS,
  DAHILI_NUMARALAR_SELECTED_ID,
  DAHILI_NUMARALAR_SELECTED_OFIS_KAT,
  DAHILI_NUMARALAR_UPDATE_ERROR,
  DAHILI_NUMARALAR_UPDATE_INIT,
  DAHILI_NUMARALAR_UPDATE_SUCCESS
} from "../reducers/dahiliNumaralar";
import myfetch from "../utils/myFetch";

export const fetchDahiliNumaralar = () => async dispatch => {
  dispatch({type:DAHILI_NUMARALAR_FETCH_ALL_INIT});
  try {
    const items = await dispatch(myfetch({url:'/dahili_numaralar'}));
    dispatch({type:DAHILI_NUMARALAR_FETCH_ALL_SUCCESS, items});
  } catch (error) {
    dispatch({type:DAHILI_NUMARALAR_FETCH_ALL_ERROR, error});
  }
}

export const fetchDahiliNumara = id => async dispatch => {
  dispatch({type:DAHILI_NUMARALAR_FETCH_ONE_INIT});
  try {
    const item = await dispatch(myfetch({url:'/dahili_numaralar', params:{id}}));
    dispatch({type:DAHILI_NUMARALAR_FETCH_ONE_SUCCESS, item});
  } catch (error) {
    dispatch({type:DAHILI_NUMARALAR_FETCH_ONE_ERROR, error});
  }
}

export const saveDahiliNumara = (dahili, onSuccess) => async dispatch => {
  dispatch({type:DAHILI_NUMARALAR_SAVE_INIT});
  try {
    const item = await dispatch(myfetch({
      url:'/dahili_numaralar', 
      method:'POST', 
      body:{...dahili}
    }));
    dispatch({type:DAHILI_NUMARALAR_SAVE_SUCCESS, item});
    if (onSuccess) onSuccess();
  } catch (error) {
    dispatch({type:DAHILI_NUMARALAR_SAVE_ERROR, error});
  }
}

export const updateDahiliNumara = (dahili, onSuccess) => async dispatch => {
  dispatch({type:DAHILI_NUMARALAR_UPDATE_INIT});
  try {
    const item = await dispatch(myfetch({
      url:'/dahili_numaralar', 
      method:'PATCH', 
      body:{...dahili}
    }));
    dispatch({type:DAHILI_NUMARALAR_UPDATE_SUCCESS, item});
    if (onSuccess) onSuccess();
  } catch (error) {
    dispatch({type:DAHILI_NUMARALAR_UPDATE_ERROR, error});
  }
}

export const deleteDahiliNumara = (id, onSuccess) => async dispatch => {
  dispatch({type:DAHILI_NUMARALAR_DELETE_INIT});
  try {
    await dispatch(myfetch({
      url:'/dahili_numaralar',
      method:'DELETE',
      body:{id}
    }));
    dispatch({type:DAHILI_NUMARALAR_DELETE_SUCCESS, id});
    if (onSuccess) onSuccess();
  } catch (error) {
    dispatch({type:DAHILI_NUMARALAR_DELETE_ERROR, error});
  }
}

export const clearErrors = () => dispatch => {
  dispatch({type:DAHILI_NUMARALAR_CLEAR_ERRORS});
}

export const toggleSelectedDahili = id => dispatch => {
  dispatch({type:DAHILI_NUMARALAR_SELECTED_ID, id});
}

export const toggleSelectedOfisKat = ofisKat => dispatch => {
  dispatch({type:DAHILI_NUMARALAR_SELECTED_OFIS_KAT, ofisKat});
}