import { 
  IZINLER_WEEKLY_CLEAR_ERRORS,
  IZINLER_WEEKLY_FETCH_ERROR,
  IZINLER_WEEKLY_FETCH_INIT,
  IZINLER_WEEKLY_FETCH_SUCCESS,
  IZINLER_WEEKLY_FILTER_TOGGLE,
  IZINLER_WEEKLY_PICKED_IZINID
} from "../reducers/izinlerWeekly"
import myfetch from "../utils/myFetch";


export const clearErrors = () => dispatch => {
  dispatch({type:IZINLER_WEEKLY_CLEAR_ERRORS});
}

export const fetchWeeklyData = (year, weekNr) => async dispatch => {
  dispatch({type:IZINLER_WEEKLY_FETCH_INIT});
  try {
    const value = await dispatch(myfetch({url:'/izinler', params:{year, weekNr}}));
    dispatch({type:IZINLER_WEEKLY_FETCH_SUCCESS, value});
  } catch (error) {
    dispatch({type:IZINLER_WEEKLY_FETCH_ERROR, error});
  }
}

export const setPickedIzinId = id => dispatch => {
  dispatch({type:IZINLER_WEEKLY_PICKED_IZINID, id});
}


export const setFilter = (forWhat, id) => dispatch => {
  dispatch({type:IZINLER_WEEKLY_FILTER_TOGGLE, forWhat, id});
}