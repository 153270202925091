import { USER_AUTHORIZATION_GET_ERROR, USER_AUTHORIZATION_GET_INIT, USER_AUTHORIZATION_GET_SUCCESS, USER_CLEAR_ALL_ERROR, USER_LOCATION_SAVE_ERROR, USER_LOCATION_SAVE_INIT, USER_LOCATION_SAVE_SUCCESS } from "../reducers/user"
import myfetch from "../utils/myFetch";

export const clearAllError = () => dispatch => {
  dispatch({type:USER_CLEAR_ALL_ERROR});
}

export const saveLocation = (location) => async (dispatch, getState) => {
  dispatch({type:USER_LOCATION_SAVE_INIT});
  try {
    const userType = getState().auth.userType;
    await dispatch(myfetch({
      url: userType === 'OFFICE' ? '/users' : '/shipusers', 
      method: 'POST', 
      body: {...location}
    }));
    dispatch({type:USER_LOCATION_SAVE_SUCCESS, location});
  } catch (error) {
    dispatch({type:USER_LOCATION_SAVE_ERROR, error});
  }
}

export const getAuthorization = authString => async dispatch => {
  dispatch({type:USER_AUTHORIZATION_GET_INIT});
  try {
    const value = await dispatch(myfetch({url:'/users', params:{authString}}));
    dispatch({type:USER_AUTHORIZATION_GET_SUCCESS, authString, value});
  } catch (error) {
    dispatch({type:USER_AUTHORIZATION_GET_ERROR, error});
  }
}