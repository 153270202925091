import { useSelector } from 'react-redux';
import Notification from './Notification';
import styles from './Notification.module.css';


const NotificationBox = () => {
  const { notifications } = useSelector(({notification}) => notification);


  return <div className={styles.NotificationBox}>
    {notifications.map(el => <Notification key={el.id} notification={el} />)}
  </div>
}

export default NotificationBox;