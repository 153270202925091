import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { clearErrors, fetchUserYerler, toggleSelectedYerId } from "../../../actions/userYerler";
import ErrorMessage from "../../../components/errorMessage/ErrorMessage";
import styles from './Employees.module.css';

const Yerler = () => {
  const { yerler, selectedUserYerId, error, isLoading } = useSelector(({userYerler}) => userYerler);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(fetchUserYerler());
  }, [dispatch]);

  return <Fragment>
    {error && <ErrorMessage error={error} closeMe={()=>dispatch(clearErrors())} />}
    {isLoading ? <p>Lütfen Bekleyiniz</p> : 
    <ul>
      {yerler && [...yerler].map(el => <li key={el.id}
        onClick={()=>dispatch(toggleSelectedYerId(el.id))}
        className={`${selectedUserYerId === el.id ? styles.Active : ''}`}
      >
        <span>{el.name}</span>
      </li>)}
    </ul>}
  </Fragment>
}

export default Yerler;