import { createRoot} from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import configureStore from './store';
import { Provider } from 'react-redux';
import App from './App';
import NotificationBox from './components/notification/NotificationBox';
import { lazy, Suspense } from 'react';
import LoadingWave from './components/loading_spinners/LoadingWave';
import Error from './pages/errors/Error';
import Map from './pages/map/Map';

const AuthPage = lazy(() => import('./pages/auth/AuthPage'));

const container = document.getElementById('root');
const root = createRoot(container);
const store = configureStore();


root.render(
  <Provider store={store}>
    <NotificationBox />
    <BrowserRouter>
      <Routes>
        <Route path="auth/*" element={
          <Suspense fallback={<LoadingWave />}>
            <AuthPage />
          </Suspense>
        } />
        <Route path="errors/*" element={
          <Suspense fallback={<LoadingWave />}>
            <Error />
          </Suspense>
        } />
        <Route path='map/*' element={
          <Suspense fallback={<LoadingWave/>}>
            <Map />
          </Suspense>
        }/>
        <Route path='*' element={
          <Suspense fallback={<LoadingWave />}>
            <App />
          </Suspense>
        }/>
      </Routes>
    </BrowserRouter>
  </Provider>
);