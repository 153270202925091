import { EMPLOYEES_CLEAR_ERROR, EMPLOYEES_FETCH_ERROR, EMPLOYEES_FETCH_INIT, EMPLOYEES_FETCH_SUCCESS, EMPLOYEES_SELECTED_ID } from "../reducers/employees"
import myfetch from "../utils/myFetch";


export const fetchEmployees = () => async dispatch => {
  dispatch({type:EMPLOYEES_FETCH_INIT});
  try {
    const items = await dispatch(myfetch({url:'/employees'}));
    dispatch({type:EMPLOYEES_FETCH_SUCCESS, items});
  } catch (error) {
    dispatch({type:EMPLOYEES_FETCH_ERROR, error})
  }
}

export const toggleEmployee = id => dispatch => {
  dispatch({type:EMPLOYEES_SELECTED_ID, id});
}

export const clearErrors = () => dispatch => {
  dispatch({type:EMPLOYEES_CLEAR_ERROR});
}