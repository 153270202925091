import { combineReducers } from "redux";

export const SHIPS_FETCH_ALL_INIT = 'SHIPS_FETCH_ALL_INIT';
export const SHIPS_FETCH_ALL_SUCCESS = 'SHIPS_FETCH_ALL_SUCCESS';
export const SHIPS_FETCH_ALL_ERROR = 'SHIPS_FETCH_ALL_ERROR';

export const SHIPS_RESET_DATA = 'SHIPS_RESET_DATA';


const defaultState = {
  ships: [],
  error: null,
  isLoading: false,
}

const initState = localStorage.getItem('ships') ?
  JSON.parse(localStorage.getItem('ships')) : 
  defaultState;


const createShipsReducer = () => {

  const ships = (state=initState.ships, action) => {
    switch(action.type){
      case SHIPS_FETCH_ALL_SUCCESS:
        initState.ships = action.ships;
        localStorage.setItem('ships', JSON.stringify(initState));
        return action.ships;
      case SHIPS_FETCH_ALL_INIT:
      case SHIPS_FETCH_ALL_ERROR:
      case SHIPS_RESET_DATA:
        initState.ships = [];
        localStorage.setItem('ships', JSON.stringify(initState));
        return [];
      default:
        return state;
    }
  }

  const error = (state=initState.error, action) => {
    switch(action.type){
      case SHIPS_FETCH_ALL_ERROR:
        initState.error = action.error;
        localStorage.setItem('ships', JSON.stringify(initState));
        return action.error;
      case SHIPS_FETCH_ALL_INIT:
      case SHIPS_FETCH_ALL_SUCCESS:
      case SHIPS_RESET_DATA:
        initState.error = null;
        localStorage.setItem('ships', JSON.stringify(initState));
        return null;
      default:
        return state;
    }
  }

  const isLoading = (state=initState.isLoading, action) => {
    switch(action.type){
      case SHIPS_FETCH_ALL_INIT:
        initState.isLoading = true;
        localStorage.setItem('ships', JSON.stringify(initState));
        return true;
      case SHIPS_FETCH_ALL_ERROR:
      case SHIPS_FETCH_ALL_SUCCESS:
      case SHIPS_RESET_DATA:
        initState.isLoading = false;
        localStorage.setItem('ships', JSON.stringify(initState));
        return false;
      default:
        return state;
    }
  }

  return combineReducers({
    ships,
    error,
    isLoading
  })
}


export default createShipsReducer();