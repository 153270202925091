import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, fetchEmployees, toggleEmployee } from '../../../actions/employees';
import { toggleDeptHidden } from '../../../actions/departments';
import ErrorMessage from '../../../components/errorMessage/ErrorMessage';
import LoadingWave from '../../../components/loading_spinners/LoadingWave';
import DepartmentList from './DepartmentList';
import styles from './Employees.module.css';
import { XIcon } from '@heroicons/react/outline'
import { getAuthorization } from '../../../actions/user';
import { Link } from 'react-router-dom';
import Yerler from './Yerler';
import whatsappIcon from '../../../assets/logo/whatsapp.svg';
import { PhoneIcon, PaperAirplaneIcon } from '@heroicons/react/outline';
import { phoneFormat } from '../../../utils/functions';

const Employee = ({el}) => {
  const dispatch = useDispatch();
  const { selectedEmployeeId } = useSelector(({employees}) => employees);
  const { user } = useSelector(({auth}) => auth);
  const { authorizations } = useSelector(({user}) => user);

  if (!el || !user) return null;

  const canSee = (!!authorizations && +authorizations.IZINLER > 0)
    || +user.id === +el.id
    || (+user.isManager === 1 && +user.deptId === +el.deptId)
    || !!el.amISuperior;


  let whatsapp = null;
  if (el.phone) {
    el.phone = el.phone.replaceAll(/\D/g, '');
    if (el.phone.slice(0, 1)*1 === 0) {
      el.phone = `9${el.phone}`;
    }else if (el.phone.slice(0, 1)*1 === 5){
      el.phone = `90${el.phone}`;
    }
    if (el.phone.match(/90\d{10}/g)) whatsapp = `https://wa.me/${el.phone}`;
  }

  return <div
    className={`
      ${styles.Employee} 
      ${+el.id === +selectedEmployeeId ? styles.ActiveEmployee : ''}
      ${+el.isManager === 1 ? styles.Manager : ''}
      ${el.izinDurumu ? styles.Izinli : ''}
    `}
    onClick={()=>dispatch(toggleEmployee(el.id))}
  >
    {el.izinDurumu && <div className={styles.EmployeeIzinDurumu}>{el.izinDurumu}</div>}
    <div className={styles.EmployeeBody}>
      <div className={styles.Employee_Left}>
        <div className={styles.Employee_Left_data}>
          {el.name}{!!el.middleName && ` ${el.middleName}`} {el.surname}
        </div>

        <div className={styles.Employee_Left_data}>
          {el.userYer.name}
        </div>
        
        {!!el.dahiliNumaralar && el.dahiliNumaralar.map((d,i) => <div 
          className={styles.Employee_Left_data} 
          key={i}>Dahili: {d.numara} / {d.ofisKat}
        </div>)}

        {el.phone && <div className={`${styles.Employee_Left_data} ${styles.Button}`}>
          <a href={`tel:+${el.phone}`}>
            <PhoneIcon width={20} />
            <span>{phoneFormat(el.phone)}</span>
          </a>
        </div>}
        
        {whatsapp && <div className={`${styles.Employee_Left_data} ${styles.Button}`}>
          <a href={whatsapp} target='_blank' rel='noreferrer'>
            <img src={whatsappIcon} alt='' />
          </a>
        </div>}

        <div className={`${styles.Employee_Left_data} ${styles.Button}`}>
          <a href={`mailto:${el.email}`}>
            <PaperAirplaneIcon width={20} />
            <span>Mail Gönder</span>
          </a>
        </div>

        {canSee && el.userType !== "DISARIDAN" && <div className={`${styles.Employee_Left_data} ${styles.Button}`}>
          <Link to={`/izinler/user/${el.id}`} >İzinlerini Görüntüle</Link>
        </div>}
      </div>

      <div className={styles.Employee_Right}>
        <span>{el.dept.name}</span>
        <span>{el.jobTitle}</span>
      </div>
    </div>

  </div>
}


const Employees = () => {
  const dispatch = useDispatch();
  const { employees, error, isLoading } = useSelector(({employees}) => employees);
  const { isHidden, selectedDeptId } = useSelector(({departments}) => departments);
  const { selectedUserYerId } = useSelector(({userYerler}) => userYerler);
  const [ content, setContent ] = useState();

  useEffect(()=>{
    dispatch(fetchEmployees());
  }, [dispatch]);

  useEffect(()=>{
    dispatch(getAuthorization('IZINLER'));
  }, [dispatch]);


  useEffect(()=>{
    //console.log(employees);
    setContent(employees.filter(el => {
      if (!selectedDeptId && !selectedUserYerId) return true;
      if (!!selectedDeptId && +el.deptId === +selectedDeptId) return true;
      if (!!selectedUserYerId && el.userYerId === selectedUserYerId) return true;
      return false
    }).map((el, i) => <Employee key={i} el={el} />));
  }, [employees, selectedDeptId, selectedUserYerId]);

  return <Fragment>
    {error && <ErrorMessage error={error} closeMe={()=>dispatch(clearErrors())} />}
    {isLoading && <LoadingWave open={true} />}
    <main className={styles.Main}>
      <div className={styles.Employees}>
        <div className={styles.Header}>
          <h1 className='baslik'>Scot Üyeleri</h1>
        </div>
        <div className={styles.Tablo}>
          {content}
        </div >
      </div>
      {!isHidden && <div onClick={()=>dispatch(toggleDeptHidden())} className={styles.Backdrop}/>}
      <button className={`${styles.MenuButton} ${isHidden ? styles.Shown : ''}`} onClick={()=>dispatch(toggleDeptHidden())} >Departman Seç</button>
      <nav className={`${styles.Departments} ${isHidden ? styles.Hidden : ''}`}>
        <button onClick={()=>dispatch(toggleDeptHidden())} className={styles.CloseButton}>
          <XIcon width={30} height={30} />
        </button>
        <h2 className='baslik'>Departmanlar</h2>
        <DepartmentList />
        <h2 className='baslik'>Yerler</h2>
        <Yerler />
      </nav>
    </main>
  </Fragment>
}

export default Employees;