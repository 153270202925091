import { useEffect, useState } from 'react';
import styles from './Notification.module.css';
import iconChecked from '../../assets/icons/notify/checked.svg';
import iconWarning from '../../assets/icons/notify/warning.svg';
import iconError from '../../assets/icons/notify/error.svg';
import { useDispatch } from 'react-redux';
import { removeNotification } from '../../actions/notification';


const Notification = ({notification}) => {
  const [status, setStatus] = useState('starting');
  const dispatch = useDispatch();
  const {text, duration, type, onClick} = notification;
  const classes = [styles.Notification];

  if (status === 'starting') classes.push(styles.Starting);
  if (status === 'idle') classes.push(styles.Idle);
  if (status === 'ending') classes.push(styles.Ending);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setStatus('idle');
    }, 500);
    const timer2 = setTimeout(() => {
      setStatus('ending');
    }, duration - 300);
    const timer3 = setTimeout(() => {
      dispatch(removeNotification(notification.id));
    }, duration)
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    }
  }, [duration, dispatch, notification.id]);

  let icon = iconChecked;
  if (type === 'danger') {
    classes.push(styles.Danger);
    icon = iconError;
  }else if (type === 'warn') {
    classes.push(styles.Warn);
    icon = iconWarning;
  }else {
    classes.push(styles.Info);
  } 

  const clicked = () => {
    if (onClick) onClick();
    dispatch(removeNotification(notification.id));
  }

  return <div className={classes.join(' ')} >
    <div className={styles.Content} onClick={clicked} >
      <img src={icon} alt='' />
      <span>{text}</span>
    </div>
  </div>
}

export default Notification;