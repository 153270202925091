import styles from './Topbar.module.css';
import logo from '../../../src/assets/logo/logo.png';
import { LogoutIcon, MenuIcon, HomeIcon, XIcon } from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions/auth';
import { Fragment, useState } from 'react';
import Confirmation from '../confirmation/Confirmation';
import { Link } from 'react-router-dom';
import { toggleLeftMenu } from '../../actions/settings';

const Topbar = () => {
  const dispatch = useDispatch();
  const [ onay, setOnay ] = useState();
  const { user } = useSelector(({auth}) => auth);
  const { isMenuOpen } = useSelector(({settings}) => settings);

  const cikis = () => {
    setOnay({
      question:'Çıkış yapmak istediğinize emin misiniz?',
      onCancel: () => setOnay(null),
      onConfirm: () => dispatch(logout())
    });
  }


  return <Fragment>
    {onay && <Confirmation {...onay}/>}
    <div className={styles.NavWrapper}>
      <div className={styles.Nav} >
        
        <ul className={styles.LeftSide}>
          
          <div className={`${styles.Menu} ${isMenuOpen ? styles.MenuOpen : ''}`} onClick={()=>dispatch(toggleLeftMenu())}>
            {isMenuOpen ?
              <XIcon width={25} height={25} color='red' /> :
              <MenuIcon width={25} height={25}/>
            }
            <span className='hide500'>{`${isMenuOpen ? 'Close' : 'Menu'}`}</span>
          </div>

          {!isMenuOpen && <Link className={styles.MyPage} to='user/my-page' >
            <HomeIcon width={25} height={25} />
            <span className='hide500'>Benim Sayfam</span>
          </Link>}

        </ul>

        <div className={styles.LogoWrapper}>
          <img src={logo} alt='Scot' />
        </div>
        
        <ul className={styles.RightSide}>
          <p className='hide700'>
            <span>{!!user && user.name}</span>
            <span>{!!user && user.surname}</span>
          </p>

          <div className={styles.Logout} onClick={cikis}>
            <span className='hide500'>Çıkış</span>
            <LogoutIcon height={25} width={25} />
          </div>
        </ul>
      
      </div>
    </div>
  </Fragment>
}

export default Topbar;