import { combineReducers } from "redux";
import { AUTH_LOGIN_INIT, AUTH_LOGOUT } from "./auth";
import { IZIN_ANSWER_SUCCESS, IZIN_CANCEL_SUCCESS, IZIN_FORM_GETIRDIMI_SUCCESS } from "./izin";

export const IZINLER_FETCH_MYWAITINGS_INIT = "IZINLER_FETCH_MYWAITINGS_INIT";
export const IZINLER_FETCH_MYWAITINGS_SUCCESS = "IZINLER_FETCH_MYWAITINGS_SUCCESS";
export const IZINLER_FETCH_MYWAITINGS_ERROR = "IZINLER_FETCH_MYWAITINGS_ERROR";

export const IZINLER_FETCH_WAITINGFORME_INIT = "IZINLER_FETCH_WAITINGFORME_INIT";
export const IZINLER_FETCH_WAITINGFORME_SUCCESS = "IZINLER_FETCH_WAITINGFORME_SUCCESS";
export const IZINLER_FETCH_WAITINGFORME_ERROR = "IZINLER_FETCH_WAITINGFORME_ERROR";

export const IZINLER_FETCH_FORMGETIRMEYENLER_INIT = "IZINLER_FETCH_FORMGETIRMEYENLER_INIT";
export const IZINLER_FETCH_FORMGETIRMEYENLER_SUCCESS = "IZINLER_FETCH_FORMGETIRMEYENLER_SUCCESS";
export const IZINLER_FETCH_FORMGETIRMEYENLER_ERROR = "IZINLER_FETCH_FORMGETIRMEYENLER_ERROR";

export const IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_INIT = "IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_INIT";
export const IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_SUCCESS = "IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_SUCCESS";
export const IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_ERROR = "IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_ERROR";

export const IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_INIT = "IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_INIT";
export const IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_SUCCESS = "IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_SUCCESS";
export const IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_ERROR = "IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_ERROR";

export const IZINLER_RESET = "IZINLER_RESET";

export const IZINLER_SELECTED_IZINID = "IZINLER_SELECTED_IZINID";
export const IZINLER_FILTERS_TOGGLE = "IZINLER_FILTERS_TOGGLE";
export const IZINLER_FILTERS_SET = "IZINLER_FILTERS_SET";

export const IZINLER_CLEAR_ALL_ERRORS = "IZINLER_CLEAR_ALL_ERRORS";


const createIzinlerReducer = () => {

  const error = (state=null, action) => {
    switch(action.type){
      case IZINLER_FETCH_MYWAITINGS_ERROR:
      case IZINLER_FETCH_WAITINGFORME_ERROR:
      case IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_ERROR:
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_ERROR:
      case IZINLER_FETCH_FORMGETIRMEYENLER_ERROR:
        return action.error;
      case IZINLER_FETCH_WAITINGFORME_INIT:
      case IZINLER_FETCH_MYWAITINGS_INIT:
      case IZINLER_FETCH_WAITINGFORME_SUCCESS:
      case IZINLER_FETCH_MYWAITINGS_SUCCESS:
      case IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_SUCCESS:
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_SUCCESS:
      case IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_INIT:
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_INIT:
      case IZINLER_FETCH_FORMGETIRMEYENLER_INIT:
      case IZINLER_FETCH_FORMGETIRMEYENLER_SUCCESS:
      case IZINLER_CLEAR_ALL_ERRORS:
        return null;
      default:
        return state;
    }
  }
  


  const isLoading = (state=false, action) => {
    switch(action.type) {
      case IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_INIT:
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_INIT:
      case IZINLER_FETCH_WAITINGFORME_INIT:
      case IZINLER_FETCH_MYWAITINGS_INIT:
      case IZINLER_FETCH_FORMGETIRMEYENLER_INIT:
        return true;
      case IZINLER_FETCH_MYWAITINGS_SUCCESS:
      case IZINLER_FETCH_WAITINGFORME_SUCCESS:
      case IZINLER_FETCH_WAITINGFORME_ERROR:
      case IZINLER_FETCH_MYWAITINGS_ERROR:
      case IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_ERROR:
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_ERROR:
      case IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_SUCCESS:
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_SUCCESS:
      case IZINLER_FETCH_FORMGETIRMEYENLER_SUCCESS:
      case IZINLER_FETCH_FORMGETIRMEYENLER_ERROR:
      case IZINLER_RESET:
        return false;
      default:
        return state;
    }
  }

  

  const filterOptions = (state={statuses:new Set(), izinTypes:new Set()}, action) => {
    switch (action.type) {
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_SUCCESS:
        const copyState = {...state};
        action.izinler.forEach(el => {
          copyState.statuses.add(el.status);
          copyState.izinTypes.add(el.izinType.id);
        });
        return copyState;
      case AUTH_LOGIN_INIT:
      case AUTH_LOGOUT:
      case IZINLER_RESET:
        return {statuses:new Set(), izinTypes:new Set()};
      default:
        return state;
    }
  }

  const filterSelecteds = (state={statuses:new Set(), izinTypes:new Set()}, action) => {
    switch(action.type) {
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_SUCCESS:
        const copyState2 = {...state};
        action.izinler.forEach(el => {
          copyState2.statuses.add(el.status);
          copyState2.izinTypes.add(el.izinType.id);
        });
        return copyState2;
      case IZINLER_FILTERS_TOGGLE:
        const { forWhat, value } = action;
        const copyState = {...state};
        if (copyState[forWhat].has(value)){
          copyState[forWhat].delete(value)
        }else{
          copyState[forWhat].add(value)
        }
        return copyState;
      default:
        return state;
    }
  }

  const limit = (state=100, action) => {
    switch(action.type){
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_SUCCESS:
        return action.limit;
      case IZINLER_RESET:
        return 100;
      default:
        return state;
    }
  }
  
  const offset = (state=0, action) => {
    switch(action.type){
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_SUCCESS:
        return action.offset;
      case IZINLER_RESET:
        return 0;
      default:
        return state;
    }
  }
  
  const toplamIzinSayisi = (state=0, action) => {
    switch(action.type){
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_SUCCESS:
        return action.toplamIzinSayisi;
      default:
        return state;
    }
  }
  
  const kalanIzinSayisi = (state=0, action) => {
    switch(action.type){
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_SUCCESS:
        return action.kalanIzinSayisi;
      default:
        return state;
    }
  }

  const bekleyenIzinlerOfUser = (state=[], action) => {
    switch(action.type){
      case IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_SUCCESS:
        return action.izinler;
      case IZIN_ANSWER_SUCCESS:
      case IZIN_CANCEL_SUCCESS:
        const { izin, izinId } = action;
        const copyState = [...state];
        let i = copyState.findIndex(el => +el.id === +izinId);
        if (i === -1) return state;
        if (!!izin && (izin.status === "Approving" || izin.status === "Canceling")){
            copyState[i] = izin;
            return copyState;
        }
        copyState.splice(i,1);
        return copyState;
      case IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_INIT:
      case IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_ERROR:
      case AUTH_LOGIN_INIT:
      case AUTH_LOGOUT:
      case IZINLER_RESET:
        return [];
      default:
        return state;
    }
  }
  
  const tamamlananIzinlerOfUser = (state=[], action) => {
    switch(action.type){
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_SUCCESS:
        return action.firstFetch ? action.izinler : [...state, ...action.izinler];
      case IZIN_ANSWER_SUCCESS:
      case IZIN_CANCEL_SUCCESS:
        const { izin, izinId } = action;
        const copyState = [...state];
        let i = copyState.findIndex(el => +el.id === +izinId);
        if (i === -1) {
          if (izin.status === "Approved" || izin.status === "Canceled" || izin.status === "Declined"){
            copyState.push(izin);
          }else{
            return state;
          }
        }else if (!!izin) {
          copyState[i] = izin;
        }else{
          copyState.splice(i,1);
        }
        return copyState;
      case AUTH_LOGIN_INIT:
      case AUTH_LOGOUT:
      case IZINLER_RESET:
        return [];
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_ERROR:
      case IZINLER_FETCH_TAMAMLANAN_IZINLER_OFUSER_INIT:
      default:
        return state;
    }
  }

  const selectedIzinId = (state=null, action) => {
    switch(action.type){
      case IZINLER_SELECTED_IZINID:
        return state === action.izinId ? null : action.izinId;
      case IZINLER_FETCH_BEKLEYEN_IZINLER_OFUSER_INIT:
      case IZINLER_RESET:
      case AUTH_LOGIN_INIT:
        return null;
      default:
        return state;
    }
  }

  const myWaitings = (state=[], action) => {
    switch(action.type){
      case IZINLER_FETCH_MYWAITINGS_SUCCESS:
        return action.izinler;
      case IZIN_ANSWER_SUCCESS:
      case IZIN_CANCEL_SUCCESS:
        const { izin, izinId } = action;
        if (!izin || izin.status === "Approved" || izin.status === "Canceled" || izin.status === "Declined"){
          return [...state].filter(el => +el.id !== +izinId);        
        }else{
          const i = state.findIndex(el => +el.id === +izin.id);
          if (i === -1) return state;
          const copyState = [...state];
          copyState[i] = izin;
          return copyState;
        }
      case IZINLER_FETCH_MYWAITINGS_INIT:
      case IZINLER_FETCH_MYWAITINGS_ERROR:
      case IZINLER_RESET:
        return [];
      default:
        return state;
    }
  }

  const formGetirmeyenler = (state=[], action) => {
    switch(action.type){
      case IZINLER_FETCH_FORMGETIRMEYENLER_SUCCESS:
        return action.items;
      case IZIN_FORM_GETIRDIMI_SUCCESS:
        const {izinId, formGetirdimi} = action;
        const copyState = [...state];
        const i = copyState.find(el => +el.id === +izinId);
        if (i > -1) copyState[i].formGetirdimi = formGetirdimi;
        return copyState;
      default:
        return state;
    }
  }

  const waitingForMe = (state=[], action) => {
    switch(action.type){
      case IZINLER_FETCH_WAITINGFORME_SUCCESS:
        return action.izinler;
      case IZIN_ANSWER_SUCCESS:
      case IZIN_CANCEL_SUCCESS:
        return [...state].filter(el => +el.id !== +action.izinId);
      case IZINLER_FETCH_WAITINGFORME_ERROR:
      case IZINLER_RESET:
      case AUTH_LOGOUT:
      case AUTH_LOGIN_INIT:
        return [];
      default:
        return state;
    }
  }


  return combineReducers({
    error,
    isLoading,
    myWaitings,
    waitingForMe,
    selectedIzinId,
    limit,
    offset,
    kalanIzinSayisi,
    toplamIzinSayisi,
    bekleyenIzinlerOfUser,
    tamamlananIzinlerOfUser,
    filterOptions,
    filterSelecteds,
    formGetirmeyenler,
  });
}

export default createIzinlerReducer();