import styles from './DahiliNumaralar.module.css';
import { useParams, useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployees } from '../../../actions/employees';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';
import { addNotification } from '../../../actions/notification';
import { fetchDahiliNumara, saveDahiliNumara, updateDahiliNumara } from '../../../actions/dahiliNumaralar';
import Confirmation from '../../../components/confirmation/Confirmation';

let isEmployeesFetched = false;

const DahiliEditor = () => {
  const { id } = useParams();
  const { employees } = useSelector(({employees}) => employees);
  const { ofisKatlari, dahiliNumara } = useSelector(({dahiliNumaralar}) => dahiliNumaralar);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ userOptions, setUserOptions ] = useState([]);
  const [ ofisKatlarOptions, setOfisKatlarOptions ] = useState([]);
  const [ onay, setOnay ] = useState();
  const [ formData, setFormData ] = useState({
    data:  {ofisKat:'', place:'', userId:'', numara:''},
    toucheds: {},
    errors: {},
    formIsTouched: false,
    formsIsValid: false
  });

  useEffect(()=>{
    if (!!id) dispatch(fetchDahiliNumara(id));
  }, [dispatch, id]);

  useEffect(()=>{
    setFormData(prev => ({...prev, data:{...dahiliNumara}}));
  }, [dahiliNumara]);

  useEffect(()=>{
    if (isEmployeesFetched) return;
    dispatch(fetchEmployees());
  }, [dispatch]);

  useEffect(()=>{
    const arr = employees.filter(el => el.userType === "OFFICE").map(el => ({value:el.id, text:`${el.name} ${el.surname}`}));
    arr.unshift({value:'', text:'Kullanıcı Seçebilirsiniz'});
    setUserOptions(arr);
  }, [employees]);

  useEffect(()=>{
    let arr = ofisKatlari.map(el => ({value:el, text:el}));
    arr.unshift({value:'', text:'Kat Seçiniz'});
    setOfisKatlarOptions(arr);
  }, [ofisKatlari]);  

  const changeHandler = e => {
    const { value, name } = e.target;
    setFormData(prev => {
      const newData = {...prev.data};
      if (name === 'userId' && value !== '') newData.place = '';
      if (name === 'place' && value !== '') newData.userId = '';
      newData[name] = value;
      return {
        ...prev,
        data: newData,
        formIsTouched: true
      }
    });
    validateForm();
  }

  const blurHandler = e => {
    setFormData(prev => {
      return {
        ...prev,
        toucheds:{...prev.toucheds, [e.target.name]:true},
        formIsTouched: true
      }
    });
    validateForm();
  }

  const validateForm = () => {
    setFormData(prev => {
      const newErrors = {}
      if (prev.data.ofisKat === '') newErrors.ofisKat = "Ofis Katını Seçmelisiniz!";
      if (prev.data.userId === '' && prev.data.place === '') {
        newErrors.userId = "Kullanıcı/Bulunan Yer Seçmelisiniz!";
        newErrors.place = "Kullanıcı/Bulunan Yer Seçmelisiniz!";
      }
      if (prev.data.numara === '') newErrors.numara = 'Numara Eksik!';
      return {
        ...prev,
        errors: newErrors,
        formsIsValid: Object.keys(newErrors).length === 0
      }
    });
  }

  const submitHandler = e => {
    e.preventDefault();
    setFormData(prev => {
      const newToucheds = {};
      Object.keys(formData.data).forEach(el => newToucheds[el] = true);
      return {
        ...prev, 
        formIsTouched:true, 
        toucheds:newToucheds
      }
    });
    if (!formData.formsIsValid) {
      const msg = Object.keys(formData.errors).map(el => formData.errors[el]).join(', ');
      dispatch(addNotification(msg, "danger"));
    }

    const onSuccess = () => {
      navigate('../', {replace:true});
    }

    let islem = () => dispatch(saveDahiliNumara(formData.data, onSuccess));
    if (!!id) islem = () => dispatch(updateDahiliNumara({...formData.data, id}, onSuccess));

    setOnay({
      question: "Kaydetmek istediğinize emin misiniz?",
      onCancel: ()=>setOnay(null),
      onConfirm: ()=>{
        islem();
        setOnay(null);
      }
    });
  }

  return <Fragment>
    {onay && <Confirmation {...onay} />}
    <div className={styles.DahiliEditor}>
      <h2 className='baslik'>Dahili Editör</h2>
      <form onSubmit={submitHandler}>
        <Input 
          id="ofisKat"
          name='ofisKat'
          type='select'
          label='Ofis Katı'
          value={formData.data.ofisKat}
          error={formData.errors.ofisKat}
          isTouched={formData.toucheds.ofisKat}
          onChange={changeHandler}
          onBlur={blurHandler}
          options={ofisKatlarOptions}
        />
        <Input 
          id='userId'
          name='userId'
          type='select'
          label='Kullanıcı'
          placeholder='Kullanıcı Seçebilirsiniz'
          value={formData.data.userId}
          error={formData.errors.userId}
          isTouched={formData.toucheds.userId}
          onChange={changeHandler}
          onBlur={blurHandler}
          options={userOptions}
        />
        <Input
          id='place'
          name='place'
          type='text'
          label='Bulunan Yer'
          placeholder='Yer belirtebilirsiniz'
          value={formData.data.place}
          error={formData.errors.place}
          isTouched={formData.toucheds.place}
          onChange={changeHandler}
          onBlur={blurHandler}
        />
        <Input 
          id='numara'
          name='numara'
          label='Dahili Numara'
          type='text'
          value={formData.data.numara}
          error={formData.errors.numara}
          isTouched={formData.toucheds.numara}
          onChange={changeHandler}
          onBlur={blurHandler}   
        />
        <div className={styles.FormActions}>
          <Button disabled={!formData.formIsTouched} type='submit' >Kaydet</Button>
        </div>
      </form>
    </div>
  </Fragment>
}

export default DahiliEditor;