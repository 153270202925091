import styles from './IzinEditor.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearIzinErrors, fetchIzin, saveIzin, setEditingIzin } from '../../../../actions/izin';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { emptyEditingIzin } from '../../../../reducers/izin';
import Confirmation from '../../../../components/confirmation/Confirmation';
import Input from '../../../../components/input/Input';
import Button from '../../../../components/button/Button';
import { addNotification } from '../../../../actions/notification';
import ErrorMessage from '../../../../components/errorMessage/ErrorMessage';
import LoadingWave from '../../../../components/loading_spinners/LoadingWave';
import { fetchIzinGorevTurleri, fetchIzinGorevYerleri, fetchIzinTypes } from '../../../../actions/izinBasics';

const IzinEditor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editingIzin, izin, isLoading, error } = useSelector(({izin}) => izin);
  const { izinTypes, gorevTurleri, gorevYerleri } = useSelector(({izinlerBasics}) => izinlerBasics);

  const { ships } = useSelector(({ships}) => ships);
  const { izinId } = useParams();
  const [ onay, setOnay ] = useState();
  const [ birOncekiFormSorulduMu, setBirOncekiFormSorulduMu ] = useState(false);
  const [ optsIzinTypes, setOptIzinTypes ] = useState([]);
  const [ optsGorevYerleri, setOptGorevYerleri ] = useState([]);
  const [ optsGorevTurleri, setOptGorevTurleri ] = useState([]);
  const [ optsGemiler, setOptGemiler ] = useState([]);
  const [ optSaatler, setOptSaatler ] = useState([]);


  useEffect(()=>{
    if (izinTypes.length === 0) dispatch(fetchIzinTypes());
    if (gorevYerleri.length === 0) dispatch(fetchIzinGorevYerleri());
    if (gorevTurleri.length === 0) dispatch(fetchIzinGorevTurleri());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  useEffect(()=>{
    if (!birOncekiFormSorulduMu && !izinId && localStorage.getItem('editingIzin')) {
      const prevEditingIzin = JSON.parse(localStorage.getItem('editingIzin'));
      if (!prevEditingIzin.isEmpty) {
        setOnay({
          question: 'Daha önce yarıda kalan izin formunuza devam etmek istermisiniz?',
          onCancel: () => {
            localStorage.setItem('editingIzin', JSON.stringify(emptyEditingIzin));
            dispatch(setEditingIzin(emptyEditingIzin));
            setOnay(null);
          },
          onConfirm: ()=>{
            dispatch(setEditingIzin(prevEditingIzin));
            setOnay(null);
          }
        });
      }
    }
    setBirOncekiFormSorulduMu(true);

    return () => {
      if (!izinId) {
        localStorage.setItem('editingIzin', JSON.stringify(editingIzin));
      }
    }
  }, [birOncekiFormSorulduMu, dispatch, editingIzin, izinId]);

  useEffect(()=>{
    if (!!izinId && !izin) dispatch(fetchIzin(izinId));
  }, [dispatch, izinId, izin]);

  useEffect(()=>{
    if (!izinId || !izin) return;
    const values = {
      id: izin.id,
      izinTypeId: izin.izinType.id,
      eStartDate: izin.eStartDate.substr(0, 10),
      eStartTime: izin.eStartDate.substr(11, 5),
      eEndDate: izin.eEndDate.substr(0, 10),
      eEndTime: izin.eEndDate.substr(11, 5),
      explanation: izin.explanation
    }
    let fixedGorevYeriId = null;
    if (izin.gorevTuru) {
      values.gorevTuruId = izin.gorevTuru.id;
      const gorevTuru = gorevTurleri.find(el => +el.id === +izin.gorevTuru.id);
      if (!!gorevTuru) fixedGorevYeriId = gorevTuru.gorevYeriId;
    }
    if (izin.gorevTuruDiger) values.gorevTuruDiger = izin.gorevTuruDiger;
    if (izin.gorevYeri) values.gorevYeriId = izin.gorevYeri.id;
    if (izin.gorevYeriShipId) values.gorevYeriShipId = izin.gorevYeriShipId;
    if (izin.gorevYeriDiger) values.gorevYeriDiger = izin.gorevYeriDiger;
    if (izin.gemiStartDate) values.gemiStartDate = izin.gemiStartDate;
    if (izin.gemiEndDate) values.gemiEndDate = izin.gemiEndDate;
    if (!izin.gemiStartDate) values.gemiStartDateUnknown = true;
    if (!izin.gemiEndDate) values.gemiEndDateUnknown = true;
    
    dispatch(setEditingIzin({...emptyEditingIzin, values, fixedGorevYeriId, isEmpty:false}));
  }, [dispatch, izin, gorevTurleri, izinId]);


  useEffect(()=>{
    if (izinTypes && izinTypes.length > 0){
      const arr = izinTypes.map(el => ({value:el.id, text:el.name}));
      arr.unshift({value:'', text:'Izin Türü Seçiniz'});
      setOptIzinTypes(arr);
    }
  }, [izinTypes]);
  
  useEffect(()=>{
    if (gorevYerleri && gorevYerleri.length > 0){
      const arr = gorevYerleri.map(el => ({value:el.id, text:el.name}));
      arr.unshift({value:'', text:'Görev Yeri Seçiniz'});
      setOptGorevYerleri(arr);
    }
  }, [gorevYerleri]);

  useEffect(()=>{
    if (gorevTurleri && gorevTurleri.length > 0){
      const arr = gorevTurleri.map(el => ({value:el.id, text:el.name}));
      arr.unshift({value:'', text:'Görev Türü Seçiniz'});
      setOptGorevTurleri(arr);
    }
  }, [gorevTurleri]);
  
  useEffect(()=>{
    if (ships && ships.length > 0){
      const arr = ships.map(el => ({value:el.id, text:el.name}));
      arr.unshift({value:'', text:'Gemi Seçiniz'});
      setOptGemiler(arr);
    }
  }, [ships]);

  const createSaatler = useCallback(()=>{
    const saatOptions = [];
    for (let i = 9; i < 19; i += 0.5) {
      let h = (new Date(i*60*60*1000)).getUTCHours();
      if (h < 10) h = '0' + h;
      let m = (new Date(i*60*60*1000)).getUTCMinutes();
      if (m < 10) m = '0' + m
      saatOptions.push({
        value: h + ":" + m,
        text: h + ":" + m
      });
    }
    return saatOptions;
  }, []);

  useEffect(()=>{
    setOptSaatler(createSaatler());
  }, [createSaatler]);


  const onBlurHandler = e => {
    const {name} = e.target;
    dispatch(setEditingIzin({
      ...editingIzin,
      toucheds: {
        ...editingIzin.toucheds,
        [name]:true
      }
    }));
  }

  const changeHandler = e => {
    const {name, value} = e.target;
    const copyEditingIzin = {
      ...editingIzin,
      isEmpty:false,
      values:{
        ...editingIzin.values,
        izinTypeId: !editingIzin.values.izinTypeId ? null : editingIzin.values.izinTypeId,
        eStartDate: !editingIzin.values.eStartDate ? null : editingIzin.values.eStartDate,
        eEndDate: !editingIzin.values.eEndDate ? null : editingIzin.values.eEndDate,
        explanation: !editingIzin.values.explanation ? null : editingIzin.values.explanation,
        eStartTime: editingIzin.values.eStartTime ? editingIzin.values.eStartTime : "09:00", 
        eEndTime: editingIzin.values.eEndTime ? editingIzin.values.eEndTime : "09:00", 
        [name]: value === '' ? null : value
      },
      toucheds:{
        ...editingIzin.toucheds,
        [name]:true
      },
      formIsTouched:true,
    };
    if (name === 'gemiStartDateUnknown') {
      copyEditingIzin.values.gemiStartDateUnknown = e.target.checked;
      copyEditingIzin.values.gemiStartDate = null;
    
    }else if (name === 'gemiEndDateUnknown') {
      copyEditingIzin.values.gemiEndDateUnknown = e.target.checked;
      copyEditingIzin.values.gemiEndDate = null;
    
    }else if (name === 'gorevTuruId') {
      const gorevTuru = gorevTurleri.find(el => +el.id === +value);
      if (!!gorevTuru){
        copyEditingIzin.fixedGorevYeriId = gorevTuru.gorevYeriId;
      }
      copyEditingIzin.values.gorevYeriId = !!gorevTuru ? gorevTuru.gorevYeriId : null;
      copyEditingIzin.errors.gorevYeriId = undefined;
      copyEditingIzin.toucheds.gorevYeriId = false;
      copyEditingIzin.values.gorevTuruDiger = undefined;
      copyEditingIzin.errors.gorevTuruDiger = undefined;
      copyEditingIzin.toucheds.gorevTuruDiger = false;
    
    }else if (name === 'gorevYeriId') {
      copyEditingIzin.values.gorevYeriDiger = undefined
      copyEditingIzin.values.gorevYeriShipId = undefined
      copyEditingIzin.errors.gorevYeriDiger = undefined
      copyEditingIzin.errors.gorevYeriShipId = undefined
      copyEditingIzin.toucheds.gorevYeriDiger = false
      copyEditingIzin.toucheds.gorevYeriShipId = false
    
    }else if (name === 'izinTypeId' && value === 2) {
      copyEditingIzin.values.eStartTime = "09:00"
      copyEditingIzin.values.eEndTime = "09:00"
      copyEditingIzin.errors.eStartTime = undefined
      copyEditingIzin.errors.eEndTime = undefined
      copyEditingIzin.toucheds.eStartTime = true
      copyEditingIzin.toucheds.eEndTime = true
    
    }
    validateForm(copyEditingIzin);
    dispatch(setEditingIzin(copyEditingIzin));
  }

  const validateForm = (copyEditingIzin) => {
    copyEditingIzin.formIsTouched = true;

    if (+copyEditingIzin.values.izinTypeId === 2 && !copyEditingIzin.values.explanation) {
      copyEditingIzin.values.explanation = "Yıllık izin talep ediyorum";
      copyEditingIzin.errors.explanation = undefined;
      copyEditingIzin.toucheds.explanation = true;
    }

    copyEditingIzin.errors.izinTypeId = (
      !copyEditingIzin.values.izinTypeId 
      || +copyEditingIzin.values.izinTypeId === 0
    ) ? "İzin Türü Seçmelisiniz" : null;
    if (+copyEditingIzin.values.izinTypeId !== 1){
      copyEditingIzin.values = {
        ...copyEditingIzin.values,
        gorevTuruId: undefined,
        gorevTuruDiger: undefined,
        gorevYeriId: undefined,
        gorevYeriShipId: undefined,
        gorevYeriDiger: undefined
      }
      copyEditingIzin.errors = {
        ...copyEditingIzin.errors,
        gorevTuruId: undefined,
        gorevTuruDiger: undefined,
        gorevYeriId: undefined,
        gorevYeriShipId: undefined,
        gorevYeriDiger: undefined
      }
      copyEditingIzin.toucheds = {
        ...copyEditingIzin.toucheds,
        gorevTuruId: undefined,
        gorevTuruDiger: undefined,
        gorevYeriId: undefined,
        gorevYeriShipId: undefined,
        gorevYeriDiger: undefined
      }
      
    }else{
      copyEditingIzin.errors.gorevTuruId = (
        !copyEditingIzin.values.gorevTuruId
        || copyEditingIzin.values.gorevTuruId === ''
        || +copyEditingIzin.values.gorevTuruId === 0
      ) ? "Görev Türü Seçmelisiniz" : null;
      copyEditingIzin.errors.gorevTuruDiger = (
        +copyEditingIzin.values.gorevTuruId === 11 
        && (
          !copyEditingIzin.values.gorevTuruDiger
          || copyEditingIzin.values.gorevTuruDiger.length < 3
        )
      ) ? "Diğer Görev Türünü belirtmelisiniz" : null;
      copyEditingIzin.errors.gorevYeriId = (
        !copyEditingIzin.values.gorevYeriId
        || +copyEditingIzin.values.gorevYeriId === 0 
        || copyEditingIzin.values.gorevYeriId === ''
      )? "Görev Yeri Seçmelisiniz" : null;
      copyEditingIzin.errors.gorevYeriDiger = (
        +copyEditingIzin.values.gorevYeriId === 4 
        && (
          !copyEditingIzin.values.gorevYeriDiger
          || copyEditingIzin.values.gorevYeriDiger.length < 3
        )
      ) ? "Diğer Görev Yerini belirtmelisiniz" : null;
      copyEditingIzin.errors.gorevYeriShipId = (
        +copyEditingIzin.values.gorevYeriId === 1 
        && (
          !copyEditingIzin.values.gorevYeriShipId
          || copyEditingIzin.values.gorevYeriShipId === ''
          || +copyEditingIzin.values.gorevYeriShipId === 0
        )
      ) ? "Gemi Seçmelisiniz" : null;
    }
    copyEditingIzin.errors.eStartDate = (
      !copyEditingIzin.values.eStartDate 
      || copyEditingIzin.values.eStartDate === ''
    ) ? "Başlangıç Zamanını Seçmelisiniz" : null;
    copyEditingIzin.errors.eEndDate = (
      !copyEditingIzin.values.eEndDate 
      || copyEditingIzin.values.eEndDate === ''
    ) ? "Bitiş Zamanını Seçmelisiniz" : null;
    copyEditingIzin.errors.explanation = (
      +copyEditingIzin.values.izinTypeId !== 2 && (
        !copyEditingIzin.values.explanation 
        || copyEditingIzin.values.explanation.length < 3
      )
    ) ? "Kısa bir açıklama giriniz lütfen" : null;

    if (!!copyEditingIzin.values.eStartDate && !!copyEditingIzin.values.eEndDate){
      const eStart = new Date(copyEditingIzin.values.eStartDate + 'T' + copyEditingIzin.values.eStartTime).getTime();
      const eEnd = new Date(copyEditingIzin.values.eEndDate + 'T' + copyEditingIzin.values.eEndTime).getTime();
      if (eStart > eEnd) {
        copyEditingIzin.errors.eStartDate = "Bitiş Tarihinden Önce Olmalı!";
        copyEditingIzin.errors.eEndDate = "Başlangıç Tarihinden Sonra Olmalı!";
      }else if (eStart === eEnd){
        copyEditingIzin.errors.eStartDate = "İzin göz açıp kapayıncaya kadar bitecek :)";
        copyEditingIzin.errors.eEndDate = "Başlangıç ve Bitiş zamanı aynı :)";
      }else if (copyEditingIzin.values.izinTypeId === 2 && eEnd-eStart > 10*60*60*1000){
        copyEditingIzin.errors.eStartDate = "Yıllık izin 10 günden uzun olamaz";
        copyEditingIzin.errors.eEndDate = "Yıllık izin 10 günden uzun olamaz";
      }
    }
    
    copyEditingIzin.errors.gemiStartDate = (
      !copyEditingIzin.values.gemiStartDateUnknown &&
      !!copyEditingIzin.values.gorevYeriShipId &&
      (!copyEditingIzin.values.gemiStartDate || 
        copyEditingIzin.values.gemiStartDate === ''  
      ) ? "Gemiye çıkış zamanını giriniz" : null
    );
    copyEditingIzin.errors.gemiEndDate = (
      !copyEditingIzin.values.gemiEndDateUnknown &&
      !!copyEditingIzin.values.gorevYeriShipId &&
      (!copyEditingIzin.values.gemiEndDate || 
        copyEditingIzin.values.gemiEndDate === ''  
      ) ? "Gemiden iniş zamanını giriniz" : null
    );
    let allClear = true;
    Object.keys(copyEditingIzin.errors).forEach(k => {
      if (!!copyEditingIzin.errors[k]) allClear = false;
    });
    //burdaki amaç formu ilk doldururuken her validate'de formu hatalı işlemesin
    //onu submit edildiğinde yapsın
    copyEditingIzin.formHasError = allClear ? false : copyEditingIzin.formHasError;
  }

  
  const submitHandler = e => {
    e.preventDefault();
    const copyEditingIzin = {...editingIzin};

    Object.keys(copyEditingIzin.values).forEach(k => copyEditingIzin.toucheds[k] = true);
    dispatch(setEditingIzin(copyEditingIzin));
    
    validateForm(copyEditingIzin);
    copyEditingIzin.formHasError = false;
    Object.keys(copyEditingIzin.errors).forEach(k => {
      if (!!copyEditingIzin.errors[k]) copyEditingIzin.formHasError = true;
    });
    dispatch(setEditingIzin(copyEditingIzin));

    if (copyEditingIzin.formHasError) {
      let msg = [];
      Object.keys(copyEditingIzin.errors).forEach(k => {
        if (copyEditingIzin.errors[k]) msg.push(copyEditingIzin.errors[k])
      });
      dispatch(addNotification(msg.join(', '), "danger"));
      return;
    }
    const onSuccess = (id) => navigate(`../izin/${id}`);
    const izin = {
      ...editingIzin.values,
      eStartDate: editingIzin.values.eStartDate + ' ' + editingIzin.values.eStartTime,
      eEndDate: editingIzin.values.eEndDate + ' ' + editingIzin.values.eEndTime,
      gemiStartDate: (
        !!editingIzin.values.gemiStartDate 
        && editingIzin.values.gemiStartDate !== ''
      ) ? editingIzin.values.gemiStartDate.replace('T', ' ').slice(0,16) : null,
      gemiEndDate: (
        !!editingIzin.values.gemiEndDate 
        && editingIzin.values.gemiEndDate !== ''
      ) ? editingIzin.values.gemiEndDate.replace('T', ' ').slice(0,16) : null,
    };
    dispatch(saveIzin(izin, onSuccess));
  }

  const askVazgec = () => {
    const path = !!izinId ? `../izin/${izinId}` : '../';
    if (!!editingIzin.formIsTouched){
      setOnay({
        question: "Yaptığınız değişiklikler kaybolabilir, yine çıkmak istiyor musunuz?",
        onConfirm: () => navigate(path, {replace:true}),
        onCancel: ()=>setOnay(null)
      });
    }else{
      navigate(path, {replace:true});
    }
  }

  const formClasses = [styles.Form];
  //if (editingIzin.formIsTouched && !editingIzin.formHasError) formClasses.push(styles.Success);
  if (!!editingIzin.formHasError) formClasses.push(styles.Danger);

  return <Fragment>
    {onay && <Confirmation {...onay} />}
    {error && <ErrorMessage error={error} closeMe={()=>dispatch(clearIzinErrors())} />}
    {isLoading && <LoadingWave open={true} />}
    <div className={styles.FormWrapper}>
      <form className={formClasses.join(' ')} onSubmit={submitHandler}>
        <h1>İzin Talep Formu</h1>
        <Input
          id='izinTypeId'
          name='izinTypeId'
          type='select'
          label='İzin Türü'
          disabled={!!izinId}
          value={editingIzin.values.izinTypeId}
          error={editingIzin.errors.izinTypeId}
          isTouched={editingIzin.toucheds.izinTypeId}
          options={optsIzinTypes}
          onChange={changeHandler}
          onBlur={onBlurHandler}
        />
        {!!editingIzin.values.izinTypeId && +editingIzin.values.izinTypeId === 1 && 
          <Input
            id='gorevTuruId'
            name='gorevTuruId'
            type='select'
            label='Görev Türü'
            value={editingIzin.values.gorevTuruId}
            error={editingIzin.errors.gorevTuruId}
            isTouched={editingIzin.toucheds.gorevTuruId}
            options={optsGorevTurleri}
            onChange={changeHandler}
            onBlur={onBlurHandler}
          />
        }
        
        {!!editingIzin.values.gorevTuruId && +editingIzin.values.gorevTuruId === 11 && 
          <Input
            id='gorevTuruDiger'
            name='gorevTuruDiger'
            label='Diğer Görev Türü'
            value={editingIzin.values.gorevTuruDiger}
            error={editingIzin.errors.gorevTuruDiger}
            isTouched={editingIzin.toucheds.gorevTuruDiger}
            onChange={changeHandler}
            onBlur={onBlurHandler}
          />
        }

        {!!editingIzin.values.gorevTuruId && 
          <Input
            id='gorevYeriId'
            name='gorevYeriId'
            type='select'
            label='Görev Yeri'
            disabled={!!editingIzin.fixedGorevYeriId}
            value={editingIzin.fixedGorevYeriId || editingIzin.values.gorevYeriId}
            error={editingIzin.errors.gorevYeriId}
            isTouched={editingIzin.toucheds.gorevYeriId}
            options={optsGorevYerleri}
            onChange={changeHandler}
            onBlur={onBlurHandler}
          />
        }
        
        {!!editingIzin.values.gorevYeriId && +editingIzin.values.gorevYeriId === 1 && 
          <Input
            id='gorevYeriShipId'
            name='gorevYeriShipId'
            type='select'
            label='Gemi Seçiniz'
            value={editingIzin.values.gorevYeriShipId}
            error={editingIzin.errors.gorevYeriShipId}
            isTouched={editingIzin.toucheds.gorevYeriShipId}
            options={optsGemiler}
            onChange={changeHandler}
            onBlur={onBlurHandler}
          />
        }
        
        {!!editingIzin.values.gorevYeriId && +editingIzin.values.gorevYeriId === 4 && 
          <Input
            id='gorevYeriDiger'
            name='gorevYeriDiger'
            label='Diğer Görev Türü'
            value={editingIzin.values.gorevYeriDiger}
            error={editingIzin.errors.gorevYeriDiger}
            isTouched={editingIzin.toucheds.gorevYeriDiger}
            onChange={changeHandler}
            onBlur={onBlurHandler}
          />
        }

        <fieldset>
          <h6>Ofisten Çıkış Zamanı</h6>
          <div className={styles.FieldsetInputs}>
            <Input
              id='eStartDate'
              name='eStartDate'
              label='Tarih'
              type='date'
              value={editingIzin.values.eStartDate}
              error={editingIzin.errors.eStartDate}
              isTouched={editingIzin.toucheds.eStartDate}
              onChange={changeHandler}
              onBlur={onBlurHandler}
            />

            <Input 
              id='eStartTime'
              name='eStartTime'
              label='Saat'
              type='select'
              disabled={+editingIzin.values.izinTypeId === 2}
              options={optSaatler}
              value={editingIzin.values.eStartTime}
              isTouched={editingIzin.toucheds.eStartTime}
              onChange={changeHandler}
              onBlur={onBlurHandler}
            />
          </div>
        </fieldset>
        
        {!!editingIzin.values.gorevYeriId && +editingIzin.values.gorevYeriId === 1 && 
          <Fragment>
            <fieldset>
              <h6>Gemiye Çıkış</h6>
              {!editingIzin.values.gemiStartDateUnknown && <div className={styles.FieldsetInputs}>
                <Input
                  id='gemiStartDate'
                  name='gemiStartDate'
                  label='Tarih'
                  type='datetime-local'
                  value={editingIzin.values.gemiStartDate}
                  error={editingIzin.errors.gemiStartDate}
                  isTouched={editingIzin.toucheds.gemiStartDate}
                  onChange={changeHandler}
                  onBlur={onBlurHandler}
                />               
              </div>}
              <div className={styles.TimeUnknown}>
                <input 
                  type="checkbox" 
                  id="gemiStartDateUnknown" 
                  name="gemiStartDateUnknown"
                  checked={editingIzin.values.gemiStartDateUnknown || false}
                  onChange={changeHandler} />
                <label htmlFor='gemiStartDateUnknown' >Gemiye çıkış zamanı belli değil</label>
              </div>
            </fieldset>

            <fieldset>
              <h6>Gemiden iniş</h6>
              {!editingIzin.values.gemiEndDateUnknown && <div className={styles.FieldsetInputs}>
                <Input
                  id='gemiEndDate'
                  name='gemiEndDate'
                  label='Tarih'
                  type='datetime-local'
                  value={editingIzin.values.gemiEndDate}
                  error={editingIzin.errors.gemiEndDate}
                  isTouched={editingIzin.toucheds.gemiEndDate}
                  onChange={changeHandler}
                  onBlur={onBlurHandler}
                />
              </div>}
              <div className={styles.TimeUnknown}>
                <input 
                  type="checkbox" 
                  id="gemiEndDateUnknown" 
                  name="gemiEndDateUnknown"
                  checked={editingIzin.values.gemiEndDateUnknown || false}
                  onChange={changeHandler} />
                <label htmlFor='gemiEndDateUnknown' >Gemiden iniş zamanı belli değil</label>
              </div>
            </fieldset>
          </Fragment>
        }

        <fieldset>
          <h6>Ofise Dönüş Zamanı</h6>
          <div className={styles.FieldsetInputs}>
            <Input
              id='eEndDate'
              name='eEndDate'
              label='Tarih'
              type='date'
              value={editingIzin.values.eEndDate}
              error={editingIzin.errors.eEndDate}
              isTouched={editingIzin.toucheds.eEndDate}
              onChange={changeHandler}
              onBlur={onBlurHandler}
            />

            <Input 
              id='eEndTime'
              name='eEndTime'
              label='Saat'
              type='select'
              disabled={+editingIzin.values.izinTypeId === 2}
              options={optSaatler}
              value={editingIzin.values.eEndTime}
              isTouched={editingIzin.toucheds.eEndTime}
              onChange={changeHandler}
              onBlur={onBlurHandler}
            />
          </div>
        </fieldset>


        <Input 
          id='explanation'
          name='explanation'
          type='textarea'
          label='Açıklama'
          value={editingIzin.values.explanation}
          error={editingIzin.errors.explanation}
          isTouched={editingIzin.toucheds.explanation}
          onChange={changeHandler}
          onBlur={onBlurHandler}
          rows={5}
        />

        <div className={styles.Actions}>
          <Button type='submit'>
            {!!izinId ? "Güncelle" : "Kaydet"}
          </Button>
          
          <Button 
            onClick={askVazgec} 
            btnType='Danger' 
            type='button'
          >Vazgeç</Button>
        </div>

      </form>
    </div>
  </Fragment>
}

export default IzinEditor;