import { 
  MAP_CLEAR_ERRORS, 
  MAP_FETCH_ALL_ERROR, 
  MAP_FETCH_ALL_INIT, 
  MAP_FETCH_ALL_SUCCESS, 
  MAP_FLEET_FILTERS_POSITION, 
  MAP_FLEET_FILTERS_SHIP, 
  MAP_FLEET_SELECTED_SHIPID, 
  MAP_REFRESH_ALL_DATA, 
  MAP_SHIP_BOUNDS, 
  MAP_SHIP_DAYLIGHT_DATA, 
  MAP_SHIP_FLYING_CENTER, 
  MAP_SHIP_POPUP 
} from "../reducers/map"
import myfetch from "../utils/myFetch";

export const clearErrors = () => dispatch => {
  dispatch({type:MAP_CLEAR_ERRORS});
}

export const fetchShipData = () => async dispatch => {
  dispatch({type:MAP_FETCH_ALL_INIT});
  try {
    const items = await dispatch(myfetch({url:"/ship-positions"}));
    dispatch({type:MAP_FETCH_ALL_SUCCESS, items});
  } catch (error) {
    dispatch({type:MAP_FETCH_ALL_ERROR, error});
  }
}

export const refreshShipData = () => async dispatch => {
  try {
    const items = await dispatch(myfetch({url:"/ship-positions"}));
    if (!!items) dispatch({type:MAP_REFRESH_ALL_DATA, items});
  } catch (error) {
    console.log(error);    
  }
}

export const setShipPopup = value => dispatch => {
  dispatch({type:MAP_SHIP_POPUP, value});
}

export const setBounds = (bounds, padding=null) => dispatch => {
  dispatch({type:MAP_SHIP_BOUNDS, bounds, padding});
}

export const setFlyingCenter = (lng, lat) => dispatch => {
  dispatch({type:MAP_SHIP_FLYING_CENTER, value:[lng, lat]});
}

export const mapFleetFilterShip = (ship) => dispatch => {
  dispatch({type:MAP_FLEET_FILTERS_SHIP, ship});
}

export const mapFleetFilterPosition = (position) => dispatch => {
  dispatch({type:MAP_FLEET_FILTERS_POSITION, position});
}

export const setDayLightData = (value) => dispatch => {
  dispatch({type:MAP_SHIP_DAYLIGHT_DATA, value});
}

export const setSelectedShipId = shipId => dispatch => {
  dispatch({type:MAP_FLEET_SELECTED_SHIPID, shipId});
}



