import { Route, Routes, Navigate } from 'react-router-dom';
import MyPage from './my_page/MyPage';
import styles from './User.module.css';


const User = () => {

  return <main className={styles.Users} >
    <Routes>
      <Route path='my-page' element={<MyPage />}/>
      <Route path="*" element={<Navigate to='/' replace />}/>
    </Routes>

  </main>
}

export default User;