import React, { useState } from 'react';
import styles from './Map.module.css';
import Map, { Marker } from '!react-map-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import pin from '../../assets/img/pin.png';

const MyMap = ({location, username}) => {
  
  const [viewState, setViewState] = useState({
    latitude: location.lat,
    longitude: location.lng,
    zoom: 12
  });

  return <Map 
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: '100%', height: "100%"}}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken='pk.eyJ1Ijoic2NvdHRhbmtlciIsImEiOiJjbDRjdG0xejYwMXczM2Nqbnp0N2tseGN1In0.BR4upR945JYZEiP5erdwuw'
  >
    <Marker longitude={location.lng} latitude={location.lat} anchor='bottom' color="red" >
      <div className={styles.MyMarker}>
        <span>{username}</span>
        <img className={styles.Icon} src={pin} alt={username}/>
      </div>
    </Marker>
  </Map>
}


export default MyMap;


/* import mapboxgl from 'mapbox-gl';
mapboxgl.accessToken =
  'pk.eyJ1Ijoic2NvdHRhbmtlciIsImEiOiJjbDRjdG0xejYwMXczM2Nqbnp0N2tseGN1In0.BR4upR945JYZEiP5erdwuw';

const Map = () => {
  const mapContainerRef = useRef(null);

  const [lng, setLng] = useState(5);
  const [lat, setLat] = useState(34);
  const [zoom, setZoom] = useState(1.5);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    map.on('move', () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });

    // Clean up on unmount
    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className={styles.sidebarStyle}>
        <div>
          Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
        </div>
      </div>
      <div className={styles.mapContainer} ref={mapContainerRef} />
    </div>
  );
};
 */