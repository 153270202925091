import { 
  VISITINGS_CLEAR_ERRORS, 
  VISITINGS_FETCH_ALL_ERROR, 
  VISITINGS_FETCH_ALL_INIT, 
  VISITINGS_FETCH_ALL_SUCCESS, 
  VISITINGS_PICKED_SHIPID, 
  VISITINGS_RESET,
  VISITINGS_WARN_DAY_COUNT
} from "../reducers/visitings";
import myfetch from "../utils/myFetch";

export const setWarnDayCount = value => dispatch => {
  dispatch({type:VISITINGS_WARN_DAY_COUNT, value});
}

export const resetVisitings = () => dispatch => {
  dispatch({type:VISITINGS_RESET});
}

export const clearErrors = () => dispatch => {
  dispatch({type:VISITINGS_CLEAR_ERRORS});
}

export const fetchVisitings = (cYear, cMonth, count=1) => async dispatch => {
  dispatch({type:VISITINGS_FETCH_ALL_INIT});
  try {
    for (let i = 0; i < count; i++){
      const month = cMonth-i < 1 ? 12 : cMonth-i;
      const year = cMonth-i < 1 ? cYear - 1 : cYear;
      const value = await dispatch(myfetch({url:'/izinler', params:{year, month}}));
      dispatch({type:VISITINGS_FETCH_ALL_SUCCESS, value, year, month});
    }
  } catch (error) {
    dispatch({type:VISITINGS_FETCH_ALL_ERROR, error});
  }
}

export const togglePickedShip = shipId => dispatch => {
  dispatch({type:VISITINGS_PICKED_SHIPID, shipId});
}


