import { combineReducers } from "redux";

export const EMPLOYEES_FETCH_INIT = "EMPLOYEES_FETCH_INIT";
export const EMPLOYEES_FETCH_SUCCESS = "EMPLOYEES_FETCH_SUCCESS";
export const EMPLOYEES_FETCH_ERROR = "EMPLOYEES_FETCH_ERROR";
export const EMPLOYEES_SELECTED_ID = "EMPLOYEES_SELECTED_ID";
export const EMPLOYEES_CLEAR_ERROR = "EMPLOYEES_CLEAR_ERROR";


const createEmployeeReducer = () => {

  const employees = (state=[], action) => {
    switch(action.type){
      case EMPLOYEES_FETCH_SUCCESS:
        return action.items;
      case EMPLOYEES_FETCH_ERROR:
      case EMPLOYEES_FETCH_INIT:
        return [];
      default:
        return state;
    }
  }

  const selectedEmployeeId = (state=null, action) => {
    switch(action.type){
      case EMPLOYEES_SELECTED_ID:
        return state === action.id ? null : action.id;
      default:
        return state;
    }
  }

  const error = (state=null, action) => {
    switch(action.type){
      case EMPLOYEES_FETCH_ERROR:
        return action.error;
      case EMPLOYEES_FETCH_INIT:
      case EMPLOYEES_FETCH_SUCCESS:
      case EMPLOYEES_CLEAR_ERROR:
        return null;
      default:
        return state;
    }
  }

  const isLoading = (state=null, action) => {
    switch(action.type){
      case EMPLOYEES_FETCH_INIT:
        return true;
      case EMPLOYEES_FETCH_ERROR:
      case EMPLOYEES_FETCH_SUCCESS:
        return false;
      default:
        return state;
    }
  }
  
  return combineReducers({
    employees,
    selectedEmployeeId,
    error,
    isLoading
  });
}

export default createEmployeeReducer();