import { combineReducers } from "redux";

export const NOTIFICATION_ADD = "NOTIFICATION_ADD";
export const NOTIFICATION_REMOVE = "NOTIFICATION_REMOVE";
export const NOTIFICATION_CLEAR_ALL = "NOTIFICATION_CLEAR_ALL";


const createNotificationReducer = () => {

  const notifications = (state=[], action) => {
    switch(action.type) {
      case NOTIFICATION_ADD:
        const notify = action.notification;
        const isExists = state.find(el => +el.id === notify.id || el.text === notify.text);
        return isExists ? state : [...state, notify];
      case NOTIFICATION_REMOVE:
        return state.filter(el => +el.id !== +action.id);
      case NOTIFICATION_CLEAR_ALL:
        return [];
      default:
        return state;
    }
  }

  return combineReducers({
    notifications
  });
}

export default createNotificationReducer();

