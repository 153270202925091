import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import styles from './Izinler.module.css';
import IzinEditor from './editor/IzinEditor';
import Izin from './izin/Izin';
import IzinlerMenu from './IzinlerMenu';
import UserIzinler from './user_izinler/UserIzinler';
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from 'react';
import { logout } from '../../../actions/auth';
import WaitingForMe from './user_izinler/WaitingForMe';
import Weekly from './weekly/Weekly';
import Visitings from './visitings/Visitings';
import { fetchIzinGorevTurleri, fetchIzinGorevYerleri, fetchIzinTypes } from '../../../actions/izinBasics';
import { getAuthorization } from '../../../actions/user';
import FormGetirmeyenler from './form_getirmeyenler/FormGetirmeyenler';


const Izinler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const {user} = useSelector(({auth}) => auth);
  //const { izinCounts } = useSelector(({izinler}) => izinler);

  useEffect(()=>{
    if (!user) {
      dispatch(logout());
    }else{
      dispatch(fetchIzinGorevYerleri());
      dispatch(fetchIzinGorevTurleri())
      dispatch(fetchIzinTypes());
      dispatch(getAuthorization('IZINLER'));
      dispatch(getAuthorization('IZIN_FORM_GETIRDIMI'));
    }
  }, [user, dispatch, navigate]);


  useEffect(()=>{
    /* dispatch(fetchIzinCounts());
    const a = setInterval(() => {
      dispatch(fetchIzinCounts());
    }, 10000);

    return () => clearInterval(a); */
  }, [dispatch]);

  return <main className={styles.Izinler}>
    {!!user && <Routes>
      <Route path='edit' element={<IzinEditor />} />
      <Route path='edit/:izinId' element={<IzinEditor />} />
      <Route path='izin/:izinId' element={<Izin />} />
      <Route path='menu' element={<IzinlerMenu />} />
      <Route path='waitings' element={<WaitingForMe />} />
      <Route path='weekly/:qYear/:qWeekNr' element={<Weekly />} />
      <Route path='visitings' element={<Visitings />} />
      <Route path='user/:userId' element={<UserIzinler />} />
      <Route path='formGetirmeyenler' element={<FormGetirmeyenler/>} />
      {/* <Route path='all' element={<>} /> */}
      <Route path="*" element={<Navigate to='menu' replace />}/>
    </Routes>}    
  </main>
}

export default Izinler;