import { Navigate, Route, Routes } from "react-router-dom";
import AllowNotificationError from "./AllowNotificationError";
import GeolocationError from "./GeolocationError";
import NotFoundPage from "./NotFoundPage";


const Error = () => {


  return <div>
    <Routes>
      <Route path="/GeolocationError" element={<GeolocationError />} />
      <Route path="/AllowNotificationError" element={<AllowNotificationError />} />
      <Route path="*" element={<NotFoundPage />}/>
    </Routes>
  </div>
}

export default Error;