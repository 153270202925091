import React from 'react';
import styles from './Input.module.css';

const Input = props => {
  let classes = [styles.Input];
  if (props.bigger) classes.push(styles.Bigger);
  
  const Invalid = (!!props.error && props.isTouched) || (props.required && props.isTouched && props.type === 'select' && props.options.length < 2);
  if (Invalid) classes.push(styles.Invalid);

  const options = props.options ? props.options.map(op => {
    return <option key={op.value} value={op.value}>{op.text}</option>
  }) : null;

  return <div className={classes.join(' ')}>
    {props.type === 'textarea' ? 
      <textarea 
        id={props.id}
        name={props.name || props.id}
        placeholder={props.placeholder}
        disabled={props.disabled}
        required={props.required}
        autoFocus={props.autoFocus}
        value={props.value || ''}
        onChange={props.onChange}
        onBlur={props.onBlur}
        rows={props.rows}
      ></textarea> :
    props.type === 'select' ?
      <select
        className={props.firstValid ? styles.FirstValid : ''} 
        id={props.id}
        name={props.name || props.id}
        required={props.required}
        value={props.value || ''}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
      >
        {options || props.children}
      </select> 
    :
      <input 
        type={props.type || 'text'}
        id={props.id}
        name={props.name || props.id}
        placeholder={props.placeholder || props.label}
        disabled={props.disabled}
        required={props.required}
        autoFocus={props.autoFocus}
        value={props.value || ''}
        onChange={props.onChange}
        onBlur={props.onBlur}
        max={props.max}
        min={props.min}
        step={props.step}
      />
    }
    {props.label && <label htmlFor={props.name || props.id}>{props.label}</label>}
    {Invalid && <span>{props.error || 'Invalid Value'}</span>}
  </div>
}

export default Input;