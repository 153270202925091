import styles from './DahiliNumaralar.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { deleteDahiliNumara, toggleSelectedDahili, toggleSelectedOfisKat } from '../../../actions/dahiliNumaralar';
import { useNavigate, Link } from 'react-router-dom';
import Confirmation from '../../../components/confirmation/Confirmation';
import { PlusCircleIcon } from '@heroicons/react/solid';


const Item = ({dahili}) => {
  const dispatch = useDispatch();
  const { selectedId } = useSelector(({dahiliNumaralar}) => dahiliNumaralar);
  const {authorizations} = useSelector(({user}) => user);
  const navigate = useNavigate();
  const [ onay, setOnay ] = useState();

  const isAuth = authorizations["DAHILI_NUMARALAR"] > 0;

  let isim = "Unknown";
  if (dahili.user){
    isim = dahili.user.name + (dahili.user.middleName ? ` ${dahili.user.middleName}`:'') + ` ${dahili.user.surname}`;
  }else if(dahili.place){
    isim = dahili.place;
  }

  const deleteMe = () => {
    setOnay({
      question: "Silmek istediğinize emin misiniz?",
      onCancel: ()=>setOnay(null),
      onConfirm: ()=>{
        dispatch(deleteDahiliNumara(dahili.id));
        setOnay(null);
      }
    })
  }

  return <Fragment>
    {onay && <Confirmation {...onay} />}
    <li 
      className={`
        ${styles.DahiliItem} 
        ${selectedId === dahili.id ? styles.Active : ''}
        ${isAuth ? styles.Yetkili : ''}
      `}
    >
      <div className={styles.Content}
        onClick={()=>dispatch(toggleSelectedDahili(dahili.id))}
      >
        <div>{dahili.ofisKat}</div>
        <div>{isim}</div>
        <div>{dahili.numara}</div>
      </div>
      {selectedId === dahili.id && isAuth && <div className={styles.Actions}>
        <button onClick={()=>navigate(`edit/${dahili.id}`)} >Düzenle</button>
        <button onClick={deleteMe}>Sil</button>
      </div>}
    </li>
  </Fragment>
}


const Dahililer = () => {
  const dispatch = useDispatch();
  const { dahililer, ofisKatlari, selectedOfisKat } = useSelector(({dahiliNumaralar}) => dahiliNumaralar);
  const [ filtereds, setFiltereds ] = useState(dahililer);
  const {authorizations} = useSelector(({user}) => user);

  useEffect(()=>{
    setFiltereds(
      dahililer.filter(
        el => !selectedOfisKat || selectedOfisKat === el.ofisKat
      )
    );    
  }, [selectedOfisKat, dahililer]);

  return <div className={styles.Dahililer}>
    {authorizations.DAHILI_NUMARALAR > 1 && <Link className={styles.YeniDahiliBtn} to={`new`} >
      <PlusCircleIcon width={30} color='#196d00' />
      <span>Yeni Dahili</span>
    </Link>}
    <div className={styles.Filters}>
      {ofisKatlari.sort().map((el,i) => <div 
        className={`${styles.Filter_el} ${selectedOfisKat === el ? styles.Active : ''}`}
        key={i} 
        onClick={()=>dispatch(toggleSelectedOfisKat(el))}>
        {el}
      </div>)}
    </div>
    <h1 className='baslik'>Dahili Numaralar</h1>
    <ul>
      {filtereds.map(dahili => <Item key={dahili.id} dahili={dahili}/>)}
    </ul>
  </div>
}

export default Dahililer;