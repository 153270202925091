import React, { createRef, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Map, {Source, Layer} from 'react-map-gl';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShipData, refreshShipData, setBounds } from '../../../../actions/map';
import { isimlerLayout } from '../layouts';
import LoadingWave from '../../../../components/loading_spinners/LoadingWave';
import { getBounds } from '../../map-functions';
import ShipPopup from '../../popup/ShipPopup';
import ShipMarker from '../../markers/ShipMarker';
import MapFleetMenu from './Menu';
import styles from './styles.module.css';

const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2NvdHRhbmtlciIsImEiOiJjbDRjdG0xejYwMXczM2Nqbnp0N2tseGN1In0.BR4upR945JYZEiP5erdwuw';


const MapFleet = () => {
  const { shipsData, isLoading, bounds, padding, flyingCenter, filters, daylightData } = useSelector(({map}) => map);
  const { user } = useSelector(({auth}) => auth);
  const { showDayLight, isMenuOpen } = useSelector(({mapSettings}) => mapSettings);
  const dispatch = useDispatch();
  const refMap = createRef();
  const [ mapLoadingMesaj, setMapLoadingMesaj ] = useState(+user.sakaci === 1 ? "Haritayı indiriyoruz, az sabırlı olun!" : "Harita Yükleniyor");
  const [ markerSize, setMarkerSize] = useState(7);

  //console.log("user.sakaci:", user.sakaci);

  useEffect(()=>{
    dispatch(fetchShipData());
    setInterval(() => dispatch(refreshShipData()), 60000);
  }, [dispatch]);

  useEffect(()=>{
    if (!refMap.current) return;
    const map = refMap.current.getMap();
    map.fitBounds(bounds, {padding});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, bounds, padding]);

  useEffect(()=>{
    if (!refMap.current || !flyingCenter) return;
    const map = refMap.current.getMap();
    map.flyTo({center: flyingCenter, offset: [0, 150], duration:500, essential:true});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, flyingCenter]);

  //console.log(shipsData);

  const zoomHandler = useCallback(e=>{
    const zoom = e.viewState.zoom;
    const newSize = zoom <= 3 ? 7 : zoom <= 4 ? 10 : zoom <= 8 ? 15 : 20;
    if (newSize !== markerSize) setMarkerSize(newSize);
  }, [markerSize]);

  const markers = useMemo(() => {
    return filters.ships.map(el => <ShipMarker 
      key={el.id} 
      iconSize={markerSize} 
      data={el} 
    />);
  }, [markerSize, filters.ships]);

  return <Fragment>
    <LoadingWave 
      open={isLoading || !!mapLoadingMesaj} 
      msg={isLoading ? (+user.sakaci === 1 ? "Gemi datalarını indirivereyom gari..." : "Gemi Dataları indiriliyor...") : mapLoadingMesaj} 
    />
    <div className={`${styles.MapPage} ${isMenuOpen ? styles.Open : ''}`}>
      {!isLoading && <MapFleetMenu />}
      {!!shipsData && <Map
        ref={refMap}
        mapStyle='mapbox://styles/scottanker/cl7yv6v6100br15n0d9u0l4q5'
        //projection='globe'
        mapboxAccessToken={MAPBOX_TOKEN}
        interactiveLayerIds={['gemiler']}
        onZoomEnd={zoomHandler}
        onLoad={() => {
          //if (!refMap.current || !shipsData) return;
          dispatch(setBounds(getBounds(shipsData.features), 50));
          setMapLoadingMesaj(null);
        }}
      >
        {showDayLight && <Source type="geojson" id='daylights' data={daylightData} >
          <Layer 
            id='daylights'
            type='fill'
            paint={{
              'fill-color': '#000',
              'fill-opacity': 0.1
            }}
          />
        </Source>}
        <Source type="geojson" id='gemiler' data={shipsData}>
          <Layer
            id='gemiler'
            type='symbol'
            paint={{
              'text-color': "#000000"
            }}
            layout={{...isimlerLayout}} 
          />
          {markers}
          <ShipPopup />
        </Source>
      </Map>}
    </div>
  </Fragment>
}

export default React.memo(MapFleet);