import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAnswer } from "../../../../actions/izin";
import Button from "../../../../components/button/Button";
import Confirmation from "../../../../components/confirmation/Confirmation";
import Input from "../../../../components/input/Input";
import { formatDate } from "../../../../utils/functions";
import styles from './Izin.module.css';

const IzinAnswer = ({answer, forCancel}) => {
  const { user } = useSelector(({auth}) => auth);
  const [ onaySirasiSende, setOnaySirasiSende ] = useState(false);
  const [ comment, setComment ] = useState(null);
  const dispatch = useDispatch();
  const [ onay, setOnay ] = useState();

  useEffect(()=>{
    if (!!user && answer.answer === "NoAnswer" && +answer.isNotified && +answer.superiorId === +user.id){
      setOnaySirasiSende(true);
    }else{
      setOnaySirasiSende(false);
    }
  }, [answer.answer, answer.superiorId, answer.isNotified, user]);

  const askOnayla = () => {
    setOnay({
      question: "izni onaylamak istediğinize emin misiniz?",
      onConfirm: () => {dispatch(saveAnswer(answer.izinId, "Approved", comment)); setOnay(null);},
      onCancel: () => setOnay(null)
    });    
  }

  const askReddet = () => {
    setOnay({
      question: "izni iptal etmek istediğinize emin misiniz?",
      onConfirm: () => {dispatch(saveAnswer(answer.izinId, "Declined", comment)); setOnay(null);},
      onCancel: () => setOnay(null)
    });    
  }

  return <Fragment>
    {onay && <Confirmation {...onay} />}
    <li className={`
      ${styles.IzinAnswer} 
      ${answer.answer === "Approved" ? styles.Approved : ''}
      ${answer.answer === "Declined" ? styles.Declined : ''}
      ${answer.answer === "NoAnswer" && +answer.isNotified ? styles.NextOneAnswer : ''}
    `}>
      <div className={styles.AnswerName}>{answer.name} {answer.surname}</div>
      {+answer.isNotified ?
        <div>
          {answer.answerDate && formatDate(answer.answerDate)}
          {!answer.answerDate && answer.lastUpdated && formatDate(answer.lastUpdated)}
          
          {answer.answer === "NoAnswer" && (onaySirasiSende ? " Lütfen Cevaplayınız" : " Bildirildi, Cevap bekleniyor")}
          {answer.answer === "Approved" && `${forCancel ? " İptal işlemini" : ""} Onayladı.`}
          {answer.answer === "Declined" && " Reddetti."}
        </div> :
        <div>Sıradaki Onaylayacak Kişi</div>
      }
      {!!answer.comment && answer.comment !== "" && 
        <div className={styles.AnswerComment}>
          Not: {answer.comment}
        </div>
      }
      {onaySirasiSende && <Fragment>
        <div className={styles.AnswerCommentInput}>
          <Input 
            type='textarea'
            id='comment'
            name='comment'
            label='Yorumunuz (*zorunlu değil)'
            value={comment}
            onChange={e=>setComment(e.target.value)}
          />
        </div>
        <div className={styles.AnswerActions}>
          <Button onClick={askOnayla} >Onayla</Button>
          <Button onClick={askReddet} btnType='Danger' >Reddet</Button>
        </div>
      </Fragment>}
    </li>
  </Fragment>
}

export default IzinAnswer;