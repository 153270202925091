import { combineReducers } from "redux";
import { AUTH_LOGIN_INIT, AUTH_LOGOUT } from "./auth";

export const USER_LOCATION_SAVE_INIT = "USER_LOCATION_SAVE_INIT";
export const USER_LOCATION_SAVE_SUCCESS = "USER_LOCATION_SAVE_SUCCESS";
export const USER_LOCATION_SAVE_ERROR = "USER_LOCATION_SAVE_ERROR";
export const USER_AUTHORIZATION_GET_INIT = "USER_AUTHORIZATION_GET_INIT";
export const USER_AUTHORIZATION_GET_SUCCESS = "USER_AUTHORIZATION_GET_SUCCESS";
export const USER_AUTHORIZATION_GET_ERROR = "USER_AUTHORIZATION_GET_ERROR";
export const USER_CLEAR_ALL_ERROR = "USER_CLEAR_ALL_ERROR";


const createUserReducer = () => {

  const location = (state={lat:null, lng:null, accuracy:null}, action) => {
    switch (action.type) {
      case USER_LOCATION_SAVE_INIT:
      case USER_LOCATION_SAVE_ERROR:
        return {lat:null, lng:null, accuracy:null};
      case USER_LOCATION_SAVE_SUCCESS:
        return action.location;
      default:
        return state;
    }
  }

  const authorizations = (state={}, action) => {
    switch(action.type){
      case USER_AUTHORIZATION_GET_SUCCESS:
        return {...state, [action.authString]:+action.value};
      case AUTH_LOGIN_INIT:
      case AUTH_LOGOUT:
        return {};
      default:
        return state;
    }
  }

  const error = (state=null, action) => {
    switch(action.type) {
      case USER_AUTHORIZATION_GET_ERROR:
      case USER_LOCATION_SAVE_ERROR:
        return action.error;
      case USER_AUTHORIZATION_GET_INIT:
      case USER_AUTHORIZATION_GET_SUCCESS:
      case USER_LOCATION_SAVE_INIT:
      case USER_LOCATION_SAVE_SUCCESS:
      case USER_CLEAR_ALL_ERROR:
        return null;
      default:
        return state;
    }
  }
  
  const isLoading = (state=false, action) => {
    switch(action.type) {
      case USER_AUTHORIZATION_GET_INIT:
      case USER_LOCATION_SAVE_INIT:
        return true;
      case USER_AUTHORIZATION_GET_ERROR:
      case USER_AUTHORIZATION_GET_SUCCESS:
      case USER_LOCATION_SAVE_ERROR:
      case USER_LOCATION_SAVE_SUCCESS:
        return false;
      default:
        return state;
    }
  }

  return combineReducers({
    location,
    authorizations,
    error,
    isLoading
  });
}

export default createUserReducer();