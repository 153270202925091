import { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearError, fetchDepartments, toggleDepartment, toggleDeptHidden } from '../../../actions/departments';
import { toggleEmployee } from '../../../actions/employees';
import ErrorMessage from '../../../components/errorMessage/ErrorMessage';
import styles from './Employees.module.css';


const DepartmentList = () => {
  const { departments, selectedDeptId, error, isLoading } = useSelector(({departments}) => departments);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(fetchDepartments());
  }, [dispatch]);

  const toggleDept = (id) => {
    dispatch(toggleDepartment(id));
    dispatch(toggleDeptHidden());
    dispatch(toggleEmployee(null));
  }

  return <Fragment>
    {error && <ErrorMessage error={error} closeMe={()=>dispatch(clearError())} />}
    {isLoading ? <p>Lütfen Bekleyiniz</p> :
    <ul>
      {departments && departments.map(el => <li key={el.id} 
          onClick={()=>toggleDept(+el.id)}
          className={`${+el.id === +selectedDeptId ? styles.Active : ''}`}>
          <span>{el.name}</span>
        </li>
      )}
    </ul>}
  </Fragment>
}

export default DepartmentList;