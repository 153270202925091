import styles from './popup.module.css';
import iconPropeller from '../../../assets/map/propeller.png';
import iconAnchor from '../../../assets/map/anchor.png';
import iconBollard from '../../../assets/map/bollard.png';
import iconDrift from '../../../assets/map/drift.png';
import iconSpeed from '../../../assets/map/speed.png';
import iconDailyAvgSpeed from '../../../assets/map/speed2.png';
import iconDraft from '../../../assets/map/draft.png';
import iconAirTemp from '../../../assets/map/airTemp.png';
import iconWaves from '../../../assets/map/waves.png';
import iconWind from '../../../assets/map/wind.png';
import iconWindDirection from '../../../assets/map/windDirection.png';
import iconTank from '../../../assets/map/tank2.png';
import iconDistPassed from '../../../assets/map/distanceTravelled.png';
import iconDistToGo from '../../../assets/map/distance.png';
import iconWindBeaufort from '../../../assets/map/wind2.png';
import iconSeaBeaufort from '../../../assets/map/wave2.png';
import iconDrop from '../../../assets/map/drop.png';
import iconOil from '../../../assets/map/oil.png';
import iconWater from '../../../assets/map/water.png';
import iconConsumption from '../../../assets/map/consumption.png';
import { Popup } from 'react-map-gl';
import { setShipPopup } from '../../../actions/map';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

const ShipPopup = () => {
  const dispatch = useDispatch();
  const {shipPopupData } = useSelector(({map}) => map);
  const [ showingPart, setShowingPart ] = useState('Operational');
  

  if (!shipPopupData) return null;

  let icon = iconPropeller;
  if(+shipPopupData.speed > 0) {
    icon = iconPropeller;
  }else if(shipPopupData.icon === "drift"){
    icon = iconDrift;
  }else if(shipPopupData.icon === "anchor"){
    icon = iconAnchor;
  }else if(shipPopupData.icon === "bollard"){
    icon = iconBollard;
  }

  const toggleView = () => {
    setShowingPart(prev => prev === "Operational" ? "Technical" : "Operational");
  };

  const closeMe = () => {
    dispatch(setShipPopup(null));
  }

  return <Popup anchor='bottom' longitude={+shipPopupData.lng} latitude={+shipPopupData.lat} onClose={closeMe} >
    <div className={styles.Header}> 
      <img src={icon} alt="Underway" className={`${styles.Icon} ${shipPopupData.icon === "propeller" ? styles.Spinning : ''}`} style={{'--speed':`${+shipPopupData.speed > 0 ? (10 / shipPopupData.speed) : 0}s`}} />
      <div>{shipPopupData.shipName}</div>
      <div className={styles.Badge}>IMO: {shipPopupData.imo}</div>
    </div>

    <div className={styles.Voyage} style={{"--distRatio":`${+shipPopupData.voyageLeg.distRatio || 0}`}}>
      <div className={styles.PortNamesArea}>
        <div className={`${styles.voyagePort} ${styles.LeftPort}`}>
          {shipPopupData.voyageLeg.portDeparture}
        </div>
        <div className={`${styles.voyagePort} ${styles.RightPort}`}>
          {shipPopupData.voyageLeg.etaPortName || shipPopupData.voyageLeg.portArrival}
        </div>
      </div>
      <div className={styles.Portlines}>
        <div className={styles.startpoint}></div>
        <div className={styles.passedline}></div>
        <div className={styles.shippoint}></div>
        <div className={styles.routeline}></div>
        <div className={styles.endpoint}></div>
      </div>
      <div className={styles.PortInfo}>
        <div className={styles.leftPortinfo}>
          <div className={styles.leftPortInfoRow}>
            <div>ATD:</div>
            <div>{shipPopupData.voyageLeg.ATD}</div>
          </div>
        </div>
        <div className={styles.rightPortInfo}>
          <div className={styles.rightPortInfoRow}>
            <div>{shipPopupData.voyageLeg.arriveType}:</div>
            <div>{shipPopupData.voyageLeg.rightTime}</div>
          </div>
        </div>
      </div>

      <div className={styles.wrapperArea} style={{marginLeft:`${showingPart === "Operational" ? "0" : "-360px"}`}}>
        <div id={styles.wrapperTechnical} className={styles.wrapperAreaPart}>
          <div className={styles.popupTechnicalRow}>
            <table className={styles.engineTable}>
              <tbody>
                <tr>
                  <th></th>
                  <th>Port Engine</th>
                  <th>Stb Engine</th>
                </tr>
                <tr>
                  <td>TC RPM</td>
                  <td>{shipPopupData.TCrpmP}</td>
                  <td>{shipPopupData.TCrpmS}</td>
                </tr>
                <tr>
                  <td>Load</td>
                  <td>{shipPopupData.loadP}</td>
                  <td>{shipPopupData.loadS}</td>
                </tr>
                <tr>
                  <td>Pitch</td>
                  <td>{shipPopupData.pitchP}</td>
                  <td>{shipPopupData.pitchS}</td>
                </tr>
                <tr>
                  <td>Fuel PP Avg index</td>
                  <td>{shipPopupData.fuelindexP}</td>
                  <td>{shipPopupData.fuelindexS}</td>
                </tr>
                <tr>
                  <td>Scavenge Temp</td>
                  <td>{shipPopupData.scavTempP}</td>
                  <td>{shipPopupData.scavTempS}</td>
                </tr>
                <tr>
                  <td>TC Air Pressure</td>
                  <td>{shipPopupData.TCairPressP}</td>
                  <td>{shipPopupData.TCairPressS}</td>
                </tr>
                <tr>
                  <td>Avg Exhoust Temp</td>
                  <td>{shipPopupData.avgExhTempP}</td>
                  <td>{shipPopupData.avgExhTempS}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div id={styles.wrapperOperational} className={styles.wrapperAreaPart}>
          <div className={styles.InfoboxWrapperBlue}>
            <div className={styles.infobox}>
              <img src={iconSpeed} alt='' />
              <div className={styles.infoboxHeader}>Actual Speed</div>
              <div className={styles.infoboxText}>{shipPopupData.speed}</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconDraft} alt='' />
              <div className={styles.infoboxHeader}>Draft</div>
              <div className={styles.infoboxText}>{shipPopupData.draft}mt</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconAirTemp} alt='' />
              <div className={styles.infoboxHeader}>Surface Temp</div>
              <div className={styles.infoboxText}>{`${shipPopupData.surfaceTemp}\u2103`}</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconWaves} alt='' />
              <div className={styles.infoboxHeader}>Sig Wave Ht</div>
              <div className={styles.infoboxText}>{shipPopupData.sigWaveHeight}mt</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconWind} alt='' />
              <div className={styles.infoboxHeader}>Wind Speed</div>
              <div className={styles.infoboxText}>{shipPopupData.windSpeed}</div>
            </div>
            <div className={styles.infobox}>
              <img className={styles.WindDirection} src={iconWindDirection} style={{"--direction":`${shipPopupData.windDirection}deg`}} alt='' />
              <div className={styles.infoboxHeader}>Wind Direction</div>
              <div className={styles.infoboxText}>{`${shipPopupData.windDirection}\u00b0`}</div>
            </div>
          </div>

          <div className={styles.InfoboxWrapperLight}>
            <div className={styles.infobox}>
              <img src={iconDailyAvgSpeed} alt='' />
              <div className={styles.infoboxHeaderDark}>Daily Avg Speed</div>
              <div className={styles.infoboxText}>{shipPopupData.dailyAvgSpeed}</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconTank} alt='' />
              <div className={styles.infoboxHeaderDark}>Total Cargo</div>
              <div className={styles.infoboxText}>{shipPopupData.totalCargo}</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconDistPassed} alt='' />
              <div className={styles.infoboxHeaderDark}>Distance Travelled</div>
              <div className={styles.infoboxText}>{shipPopupData.voyageLeg.distPassed}</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconDistToGo} alt='' />
              <div className={styles.infoboxHeaderDark}>Distance To Go</div>
              <div className={styles.infoboxText}>{shipPopupData.voyageLeg.distToGo}</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconWindBeaufort} alt='' />
              <div className={styles.infoboxHeaderDark}>Wind Beaufort</div>
              <div className={styles.infoboxText}>{shipPopupData.windBeaufort || "?"}</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconSeaBeaufort} alt='' />
              <div className={styles.infoboxHeaderDark}>Sea Beaufort</div>
              <div className={styles.infoboxText}>{shipPopupData.seaBeaufort || "?"}</div>
            </div>
          </div>

          <div className={styles.InfoboxWrapperGray}>
            <div className={styles.infobox}>
              <img src={iconDrop} alt='' />
              <div className={styles.infoboxHeader}>ROB IFO</div>
              <div className={styles.infoboxText}>{shipPopupData.robLFO}</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconDrop} alt='' />
              <div className={styles.infoboxHeader}>ROB MGO</div>
              <div className={styles.infoboxText}>{shipPopupData.robMGO}</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconOil} alt='' />
              <div className={styles.infoboxHeader}>ROB ME SYS OIL</div>
              <div className={styles.infoboxText}>{shipPopupData.robSYSME}</div>      
            </div>
            <div className={styles.infobox}>
              <img src={iconOil} alt='' />
              <div className={styles.infoboxHeader}>ROB DE SYS OIL</div>
              <div className={styles.infoboxText}>{shipPopupData.robSYSDE}</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconWater} alt='' />
              <div className={styles.infoboxHeader}>ROB FW</div>
              <div className={styles.infoboxText}>{shipPopupData.robFW}</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconConsumption} alt='' />
              <div className={styles.infoboxHeader}>Cons. IFO</div>
              <div className={styles.infoboxText}>{shipPopupData.conLFO}</div>
            </div>
            <div className={styles.infobox}>
              <img src={iconConsumption} alt='' />
              <div className={styles.infoboxHeader}>Cons. MGO</div>
              <div className={styles.infoboxText}>{shipPopupData.conMGO}</div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.popupButtons}>
        <button className={styles.popupBtn} onClick={toggleView}>
          {showingPart === "Operational" ? "Show Technical Data" : "Show Operational Data"}
        </button>
      </div>
    </div>
  </Popup>
}

export default ShipPopup;