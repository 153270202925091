import { PlusCircleIcon, CollectionIcon, DocumentReportIcon, ClipboardCheckIcon, ClockIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearIzinlerErrors, fetchFormGetirmeyenler } from '../../../actions/izinler';
import ErrorMessage from '../../../components/errorMessage/ErrorMessage';
import LoadingWave from '../../../components/loading_spinners/LoadingWave';
import { currentWeekNumber } from '../../../utils/functions';
import styles from './Izinler.module.css';
import { fetchMyWaitings, fetchWaitingForMe } from '../../../actions/izinler';


const Badge = ({a}) => {
  if (!a) return null;
  return <span className={styles.Badge}>
    {a}
  </span>
}

const IzinlerMenu = () => {
  const dispatch = useDispatch();
  const {myWaitings, waitingForMe, error, isLoading, formGetirmeyenler } = useSelector(({izinler}) => izinler);
  const {user} = useSelector(({auth}) => auth);
  const { authorizations } = useSelector(({user}) => user);

  const weekNr = currentWeekNumber();
  const year = (new Date()).getFullYear();

  useEffect(()=>{
    dispatch(fetchWaitingForMe());
    dispatch(fetchMyWaitings());
  }, [dispatch]);

  useEffect(()=>{
    if (+authorizations.IZIN_FORM_GETIRDIMI > 2) {
      dispatch(fetchFormGetirmeyenler());
    }
  }, [authorizations.IZIN_FORM_GETIRDIMI, dispatch]);

  return <Fragment>
    {(error) && <ErrorMessage error={error} closeMe={()=>dispatch(clearIzinlerErrors())} />}
    {isLoading && <LoadingWave open={true} />}
    <div className={styles.IzinlerMenu}>
      <ul>
        <h1>İzinler Menüsü</h1>
        <Link className={styles.ListItem} to='../edit'>
          <PlusCircleIcon width={40} height={40} />
          <div>Yeni İzin Talebi</div>
        </Link> 

        <Link className={styles.ListItem} to={`../user/${user.id}`}>
          <CollectionIcon width={40} height={40} />
          <div>Benim İzinlerim</div>
          <Badge a={myWaitings.length} />
        </Link>

        {waitingForMe.length > 0 ? <Link className={styles.ListItem} to='../waitings'>
          <ClockIcon width={40} height={40} />
          <div>Onayımı Bekleyen İzinler</div>
          <Badge a={waitingForMe.length} />
        </Link> : null}

        {/* <Link className={styles.ListItem} to='../all'>
          <CollectionIcon width={40} height={40} />
          <div>Tüm İzinler</div>
        </Link> */}
        
        {+authorizations.IZIN_FORM_GETIRDIMI > 0 ? <Link className={styles.ListItem} to='../formGetirmeyenler'>
          <ExclamationCircleIcon width={40} height={40} />
          <div>İzin Formunu Getirmeyenler</div>
          <Badge a={formGetirmeyenler.length} />
        </Link> : null}

        <Link className={styles.ListItem} to={`../weekly/${year}/${weekNr}`}>
          <DocumentReportIcon width={40} height={40} />
          <div>Haftalık İzinliler Listesi</div>
        </Link>

        <Link className={styles.ListItem} to={`../visitings`}>
          <ClipboardCheckIcon width={40} height={40} />
          <div>Gemi Ziyaretleri</div>
        </Link>
      </ul>
    </div>
  </Fragment>
}

export default IzinlerMenu;