import { 
  SHIPS_FETCH_ALL_ERROR, 
  SHIPS_FETCH_ALL_INIT, 
  SHIPS_FETCH_ALL_SUCCESS, 
  SHIPS_RESET_DATA
} from "../reducers/ships"
import myfetch from "../utils/myFetch";




export const fetchShips = () => async dispatch => {
  dispatch({type:SHIPS_FETCH_ALL_INIT});
  try {
    const ships = await dispatch(myfetch({url:'/ships'}));
    dispatch({type:SHIPS_FETCH_ALL_SUCCESS, ships});
  } catch (error) {
    dispatch({type:SHIPS_FETCH_ALL_ERROR, error});
  }
}

export const resetShipsData = () => dispatch => {
  dispatch({type:SHIPS_RESET_DATA});
}