/* export const gemilerLayout = {
  'icon-image': 'ship',
  'icon-size': ['interpolate', ['linear'], ['zoom'], 1, 0.1, 5, 0.2, 10, 0.3], //zoom seviyesi ve size, çiftleri şeklinde gidiyor
  'icon-rotate': ['get', 'course'],
  'icon-rotation-alignment': 'map',
  'icon-pitch-alignment': 'viewport',
  'icon-anchor':'center',
  'icon-offset':[0,0],
  'icon-allow-overlap': true,
  'icon-ignore-placement': true
} */

export const isimlerLayout = {
  'text-field': [
    'format',
    ['upcase', ['get', 'shipName']],
    { 'font-scale': 1.2 },
    '\n',
    {},
    ['concat', ['get', 'speed'], " knots"],
    { 'font-scale': 1.0 }
    ],
  'text-variable-anchor': ['top'],
  'text-radial-offset': ['get', 'icon-offset'],
  'text-size': ['interpolate', ['linear'], ['zoom'], 1, 10, 5, 11, 10, 13], //zoom seviyesi ve size, çiftleri şeklinde gidiyor
  'text-justify': 'auto',
  'text-allow-overlap': false,
  'text-ignore-placement': false,
}  