import { DAHILI_NUMARALAR_SELECTED_OFIS_KAT } from "../reducers/dahiliNumaralar";
import { 
  DEPARTMENTS_CLEAR_ERROR, 
  DEPARTMENTS_FETCH_ERROR, 
  DEPARTMENTS_FETCH_INIT, 
  DEPARTMENTS_FETCH_SUCCESS, 
  DEPARTMENTS_IS_SHOWN, 
  DEPARTMENTS_SELECTED_ID 
} from "../reducers/departments";
import { USER_YERLER_SELECTED_ID } from "../reducers/userYerler";
import myfetch from "../utils/myFetch";


export const fetchDepartments = () => async dispatch => {
  dispatch({type:DEPARTMENTS_FETCH_INIT});
  try {
    const items = await dispatch(myfetch({url:'/departments'}));
    dispatch({type:DEPARTMENTS_FETCH_SUCCESS, items});
  } catch (error) {
    dispatch({type:DEPARTMENTS_FETCH_ERROR, error})
  }
}

export const toggleDepartment = id => dispatch => {
  dispatch({type:DEPARTMENTS_SELECTED_ID, id});
  dispatch({type:USER_YERLER_SELECTED_ID, id:null});
}

export const clearError = () => dispatch => {
  dispatch({type:DEPARTMENTS_CLEAR_ERROR});
}

export const toggleDeptHidden = () => dispatch => {
  dispatch({type:DEPARTMENTS_IS_SHOWN});
}