import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { fetchShips } from "./actions/ships";
import { saveLocation } from "./actions/user";
import Leftbar from "./components/leftbar/Leftbar";
import Topbar from "./components/topbar/Topbar";
import DahiliNumaralar from "./pages/office_only/dahili_numaralar/DahiliNumaralar";
import Employees from "./pages/office_only/employees/Employees";
import Izinler from "./pages/office_only/izinler/Izinler";
import User from "./pages/office_only/user/User";

const App = () => {
  const {user, tokenExpire} = useSelector(({auth}) => auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [lastPosSavedDate, setLastPostSavedDate] = useState();

  const success = useCallback(pos => {
    const simdi = (new Date()).getTime();
    if (!!lastPosSavedDate && (simdi - lastPosSavedDate) < 1 * 60 * 1000 ) return;
    setLastPostSavedDate(simdi);
    dispatch(saveLocation({
      lat: pos.coords.latitude,
      lng: pos.coords.longitude,
      accuracy: pos.coords.accuracy
    }));
  }, [dispatch, lastPosSavedDate]);

  const errors = useCallback(err => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    navigate("/errors/GeolocationError");
  }, [navigate]);

  useEffect(()=>{
    if (!user || !tokenExpire || new Date() > new Date(tokenExpire)) {
      navigate("auth/login", {replace:false});
      //return <Navigate to="auth/login" />
    }
    if (navigator.geolocation){
      navigator.permissions.query({name: "geolocation"})
      .then(result => {
        if (result.state === "denied") {
          navigate("/errors/GeolocationError", {replace:false});
        }else if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(success, errors, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          });
        }else if (result.state === "granted") {
          navigator.geolocation.getCurrentPosition(success);
        }
      });
    }else{
      navigate("/errors/GeolocationError", {replace:false});
    }

    /* if (!("Notification" in window)){
      console.warn("Browser doesn't support the notifications");
    }else if (Notification.permission === 'granted'){
      //new Notification("Merhaba Scot Üyesi!");
    }else if (Notification.permission !== 'denied' || Notification.permission === "default"){
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          let notification = new Notification("Onay verdiğin için sağol Scot üyesi");
          notification.onclick = e => {
            e.preventDefault();
            navigate("/izinler");
          };
        }else{
          navigate("/errors/AllowNotificationError");
        }
      });
    }else if (Notification.permission === 'denied') {
      navigate("/errors/AllowNotificationError");
    } */
  }, [errors, navigate, success, tokenExpire, user]);

  useEffect(()=>{
    if (!!user) dispatch(fetchShips());
    //return ()=>dispatch(resetShipsData());
  }, [dispatch, user]);

  

  return <Fragment>
    <Topbar/>
    <Leftbar/>
    <div className="AppArea">
      <Routes>
        <Route path="user/*" element={<User />} />
        <Route path="izinler/*" element={<Izinler />} />
        <Route path="employees/*" element={<Employees />} />
        <Route path="dahililer/*" element={<DahiliNumaralar />} />
        <Route path="*" element={<Navigate to='/izinler' replace />}/>
      </Routes>
    </div>
    
  </Fragment>

}

export default App;