import { combineReducers } from "redux";

export const AUTH_USER_TYPE = 'AUTH_USER_TYPE';
export const AUTH_LOGIN_INIT = 'AUTH_LOGIN_INIT';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_FORGOT_PASSWORD_INIT = 'AUTH_FORGOT_PASSWORD_INIT';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_ERROR = 'AUTH_FORGOT_PASSWORD_ERROR';
export const AUTH_CHANGE_PASSWORD_INIT = 'AUTH_CHANGE_PASSWORD_INIT';
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS';
export const AUTH_CHANGE_PASSWORD_ERROR = 'AUTH_CHANGE_PASSWORD_ERROR';
export const AUTH_RESET_PASSWORD_INIT = 'AUTH_RESET_PASSWORD_INIT';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_ERROR = 'AUTH_RESET_PASSWORD_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_RESET_REQUEST_ENDING = 'AUTH_RESET_REQUEST_ENDING';
export const AUTH_CLEAR_ERRORS = "AUTH_CLEAR_ERRORS";
export const AUTH_RESET_LINK_DIED = "AUTH_RESET_LINK_DIED";


const defaultState = {
  user:null, 
  token:null, 
  tokenExpire:null, 
  userType:'OFFICE',
  resetRequestEnding: null,
  error: null,
  isResetLinkDied: true,
};

const initState = localStorage.getItem('auth') ? 
  JSON.parse(localStorage.getItem('auth')) : 
  defaultState;

const initIsResetLinkDied = localStorage.getItem('isResetLinkDied') ? localStorage.getItem('isResetLinkDied') : false;


const createAuthReducer = () => {
  
  const user = (state=initState.user, action) => {
    switch(action.type){
      case AUTH_LOGIN_SUCCESS:
        localStorage.setItem('auth', JSON.stringify({
          user:action.user, 
          token:action.token, 
          tokenExpire:action.tokenExpire,
          error: null, 
          userType:action.user ? action.user.userType : 'OFFICE'
        }));
        return action.user;
      case AUTH_LOGIN_ERROR:
      case AUTH_LOGIN_INIT:
      case AUTH_LOGOUT:
        localStorage.removeItem('auth');
        return null;
      default:
        return state;
    }
  }

  const userType = (state=initState.userType, action) => {
    switch(action.type){
      case AUTH_USER_TYPE:
        return action.userType;
      case AUTH_LOGIN_SUCCESS:
        return action.user.userType;
      case AUTH_LOGOUT:
        return 'OFFICE';
      default:
        return state;
    }
  }
  
  const token = (state=initState.token, action) => {
    switch(action.type){
      case AUTH_LOGIN_SUCCESS:
        return action.token;
      case AUTH_LOGIN_ERROR:
      case AUTH_LOGIN_INIT:
      case AUTH_LOGOUT:
        return null;          
      default:
        return state;
    }
  }
  
  const tokenExpire = (state=initState.tokenExpire, action) => {
    switch(action.type){
      case AUTH_LOGIN_SUCCESS:
        return action.tokenExpire;
      case AUTH_LOGIN_ERROR:
      case AUTH_LOGIN_INIT:
      case AUTH_LOGOUT:
        return null;          
      default:
        return state;
    }
  }

  const error = (state=initState.error, action) => {
    switch(action.type) {
      case AUTH_LOGIN_ERROR:
      case AUTH_RESET_PASSWORD_ERROR:
      case AUTH_CHANGE_PASSWORD_ERROR:
      case AUTH_FORGOT_PASSWORD_ERROR:
        return action.error;
      case AUTH_LOGIN_INIT:
      case AUTH_LOGIN_SUCCESS:
      case AUTH_RESET_PASSWORD_INIT:
      case AUTH_CHANGE_PASSWORD_INIT:
      case AUTH_FORGOT_PASSWORD_INIT:
      case AUTH_RESET_PASSWORD_SUCCESS:
      case AUTH_CHANGE_PASSWORD_SUCCESS:
      case AUTH_FORGOT_PASSWORD_SUCCESS:
      case AUTH_CLEAR_ERRORS:
        return null;
      default:
        return state;
    }
  }
  
  const resetRequestEnding = (state=(localStorage.getItem('resetRequestEnding') || 0), action) => {
    switch(action.type) {
      case AUTH_LOGIN_SUCCESS:
      case AUTH_CHANGE_PASSWORD_SUCCESS:
      case AUTH_RESET_PASSWORD_SUCCESS:
        return null;
      case AUTH_RESET_REQUEST_ENDING:
        localStorage.setItem('resetRequestEnding', action.value);
        return action.value;
      case AUTH_FORGOT_PASSWORD_SUCCESS:
        const value = new Date().getTime() + 1000 * 60 * 2;
        localStorage.setItem('resetRequestEnding', value);
        return value;
      default:
        return state;
    }
  }

  const isLoading = (state=null, action) => {
    switch(action.type) {
      case AUTH_LOGIN_INIT:
      case AUTH_CHANGE_PASSWORD_INIT:
      case AUTH_FORGOT_PASSWORD_INIT:
      case AUTH_RESET_PASSWORD_INIT:
        return true;
      case AUTH_LOGIN_SUCCESS:
      case AUTH_CHANGE_PASSWORD_SUCCESS:
      case AUTH_FORGOT_PASSWORD_SUCCESS:
      case AUTH_RESET_PASSWORD_SUCCESS:
      case AUTH_LOGIN_ERROR:
      case AUTH_CHANGE_PASSWORD_ERROR:
      case AUTH_FORGOT_PASSWORD_ERROR:
      case AUTH_RESET_PASSWORD_ERROR:
      case AUTH_LOGOUT:
        return false;
      default:
        return state;
    }
  }

  const isResetLinkDied = (state=initIsResetLinkDied, action) => {
    switch(action.type) {
      case AUTH_RESET_LINK_DIED:
        localStorage.setItem("isResetLinkDied", true);
        return true;
      case AUTH_FORGOT_PASSWORD_SUCCESS:
        localStorage.removeItem("isResetLinkDied");
        return false;
      default:
        return state;
    }
  }

  return combineReducers({
    user,
    token,
    tokenExpire,
    userType,
    error,
    resetRequestEnding,
    isLoading,
    isResetLinkDied
  });
}

export default createAuthReducer();
