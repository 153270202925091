import styles from './Weekly.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setPickedIzinId } from '../../../../actions/izinlerWeekly';
import { useEffect, useState } from 'react';

const IzinliBox = ({izin, tarih}) => {
  const { pickedIzinId } = useSelector(({izinlerWeekly}) => izinlerWeekly);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const badgeText = izin.izinType.name;
  const [extraBilgi, setExtraBilgi] = useState(null);


  useEffect(()=>{
    if (izin.gorevYeriShipName) {
      let content = <div className={styles.GemiAdi}>{izin.gorevYeriShipName.replace("SCOT ","")}</div>;
      if (izin.gemiEndDate) {
        const endDate = new Date(izin.gemiEndDate);
        const startDate = new Date(izin.gemiStartDate);
        const trh = new Date(tarih);
        if (endDate.getTime() < trh.getTime()) {
          content = <div className={styles.GemiDonusu}>
            Gemi Dönüşü
          </div>;
        } else if (startDate.getTime() > trh.getTime()+(1000*60*60*21)) {
          content = <div className={styles.GemiDonusu}>
            Gemiye Gidiş
          </div>;
        }
      }
      setExtraBilgi(content);
    }
  }, [izin.gemiEndDate, izin.gemiStartDate, izin.gorevYeriShipName, tarih]);
  

  return <div className={`
      ${styles.Izinli} 
      ${izin.izinType.name === "Dış Görev" ? "" : styles.YillikIzin} 
      ${pickedIzinId === +izin.id ? styles.ActiveUser : ''}
    `} 
    onDoubleClick={() => navigate(`/izinler/izin/${izin.id}`)} 
    onClick={()=>dispatch(setPickedIzinId(+izin.id))}
  >
    <span className={styles.Badge}>{badgeText}</span>
    <div className={styles.Body}>
      <div className={styles.IsimAlani}>
        <span>{izin.username}</span>
        {extraBilgi}
      </div>
    </div>
    <span className={styles.Dept}>{izin.departmentName}</span>
  </div>
}

export default IzinliBox;
