import { combineReducers } from "redux";

export const DEPARTMENTS_FETCH_INIT = "DEPARTMENTS_FETCH_INIT";
export const DEPARTMENTS_FETCH_SUCCESS = "DEPARTMENTS_FETCH_SUCCESS";
export const DEPARTMENTS_FETCH_ERROR = "DEPARTMENTS_FETCH_ERROR";
export const DEPARTMENTS_SELECTED_ID = "DEPARTMENTS_SELECTED_ID";
export const DEPARTMENTS_CLEAR_ERROR = "DEPARTMENTS_CLEAR_ERROR";
export const DEPARTMENTS_IS_SHOWN = "DEPARTMENTS_IS_SHOWN";

const createDepartmentReducer = () => {

  const departments = (state=[], action) => {
    switch(action.type){
      case DEPARTMENTS_FETCH_SUCCESS:
        return action.items;
      case DEPARTMENTS_FETCH_ERROR:
      case DEPARTMENTS_FETCH_INIT:
        return [];
      default:
        return state;
    }
  }

  const selectedDeptId = (state=null, action) => {
    switch(action.type){
      case DEPARTMENTS_SELECTED_ID:
        return state === action.id ? null : action.id;
      default:
        return state;
    }
  }

  const error = (state=null, action) => {
    switch(action.type){
      case DEPARTMENTS_FETCH_ERROR:
        return action.error;
      case DEPARTMENTS_FETCH_INIT:
      case DEPARTMENTS_FETCH_SUCCESS:
      case DEPARTMENTS_CLEAR_ERROR:
        return null;
      default:
        return state;
    }
  }
  
  const isLoading = (state=null, action) => {
    switch(action.type){
      case DEPARTMENTS_FETCH_INIT:
        return true;
      case DEPARTMENTS_FETCH_ERROR:
      case DEPARTMENTS_FETCH_SUCCESS:
        return false;
      default:
        return state;
    }
  }

  const isHidden = (state=true, action) => {
    switch(action.type){
      case DEPARTMENTS_IS_SHOWN:
        return !state;
      default:
        return state;
    }
  }

  return combineReducers({
    departments,
    selectedDeptId,
    error,
    isLoading,
    isHidden
  });
}

export default createDepartmentReducer();