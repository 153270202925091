import { Fragment } from 'react';
import {createPortal} from 'react-dom';
import Backdrop from '../backdrop/Backdrop';
import styles from './Modal.module.css';

const Modal = props => {
  return createPortal(
    <Fragment>
      <Backdrop style={{zIndex:9999}}/>
      <div 
        className={styles.modal}
        styles={{...props.style}}
      >{props.children}</div>
    </Fragment>, 
    document.getElementById('modal')
  );
};

export default Modal;