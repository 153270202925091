import { Marker } from 'react-map-gl';
import { setShipPopup } from '../../../actions/map';
import styles from './Markers.module.css';
import shipImage from '../../../assets/map/ship_yeni.png';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment } from 'react';
import pop from '../../../assets/sounds/pop.mp3';

const ShipMarker = ({data, iconSize}) => {
  const { shipsData } = useSelector(({map}) => map);
  const dispatch = useDispatch();
  const audio = new Audio(pop);
  audio.loop = false;

  return <Fragment>
    <Marker
      longitude={+data.lng}
      latitude={+data.lat}
      anchor='center'
      pitchAlignment='viewport'
      rotation={+data.course}
      rotationAlignment='map'
      onClick={e => {
        audio.play().then(()=>{
          e.originalEvent.stopPropagation();
          const shipData = shipsData.features.find(el => +el.properties.shipId === +data.id);
          if (!!shipData) dispatch(setShipPopup(shipData.properties));
        });
      }}
    >
      <img src={shipImage} style={{height:`${iconSize || 10}px`}} className={styles.Image} alt='' />
    </Marker>
  </Fragment>
}

export default ShipMarker;