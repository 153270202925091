import { combineReducers } from "redux";

export const DAHILI_NUMARALAR_FETCH_ONE_INIT = "DAHILI_NUMARALAR_FETCH_ONE_INIT";
export const DAHILI_NUMARALAR_FETCH_ONE_SUCCESS = "DAHILI_NUMARALAR_FETCH_ONE_SUCCESS";
export const DAHILI_NUMARALAR_FETCH_ONE_ERROR = "DAHILI_NUMARALAR_FETCH_ONE_ERROR";

export const DAHILI_NUMARALAR_FETCH_ALL_INIT = "DAHILI_NUMARALAR_FETCH_ALL_INIT";
export const DAHILI_NUMARALAR_FETCH_ALL_SUCCESS = "DAHILI_NUMARALAR_FETCH_ALL_SUCCESS";
export const DAHILI_NUMARALAR_FETCH_ALL_ERROR = "DAHILI_NUMARALAR_FETCH_ALL_ERROR";

export const DAHILI_NUMARALAR_SAVE_INIT = "DAHILI_NUMARALAR_SAVE_INIT";
export const DAHILI_NUMARALAR_SAVE_SUCCESS = "DAHILI_NUMARALAR_SAVE_SUCCESS";
export const DAHILI_NUMARALAR_SAVE_ERROR = "DAHILI_NUMARALAR_SAVE_ERROR";
export const DAHILI_NUMARALAR_UPDATE_INIT = "DAHILI_NUMARALAR_UPDATE_INIT";
export const DAHILI_NUMARALAR_UPDATE_SUCCESS = "DAHILI_NUMARALAR_UPDATE_SUCCESS";
export const DAHILI_NUMARALAR_UPDATE_ERROR = "DAHILI_NUMARALAR_UPDATE_ERROR";
export const DAHILI_NUMARALAR_DELETE_INIT = "DAHILI_NUMARALAR_DELETE_INIT";
export const DAHILI_NUMARALAR_DELETE_SUCCESS = "DAHILI_NUMARALAR_DELETE_SUCCESS";
export const DAHILI_NUMARALAR_DELETE_ERROR = "DAHILI_NUMARALAR_DELETE_ERROR";

export const DAHILI_NUMARALAR_SELECTED_ID = "DAHILI_NUMARALAR_SELECTED_ID";
export const DAHILI_NUMARALAR_SELECTED_OFIS_KAT = "DAHILI_NUMARALAR_SELECTED_OFIS_KAT";
export const DAHILI_NUMARALAR_CLEAR_ERRORS = "DAHILI_NUMARALAR_CLEAR_ERRORS";


const createDahiliNumaralarReducer = () => {

  const ofisKatlari = (state=[], action) => {
    switch(action.type){
      case DAHILI_NUMARALAR_FETCH_ALL_SUCCESS:
        return [...(new Set([...action.items.map(el => el.ofisKat)]))];
      case DAHILI_NUMARALAR_FETCH_ALL_INIT:
        return [];
      default:
        return state;
    }
  }

  const dahililer = (state=[], action) => {
    switch(action.type){
      case DAHILI_NUMARALAR_FETCH_ALL_SUCCESS:
        return action.items;
      case DAHILI_NUMARALAR_FETCH_ALL_INIT:
      case DAHILI_NUMARALAR_FETCH_ALL_ERROR:
        return [];
      case DAHILI_NUMARALAR_SAVE_SUCCESS:
        return [...state, action.item];
      case DAHILI_NUMARALAR_DELETE_SUCCESS:
        const copyState = [...state];
        const i = copyState.findIndex(el => +el.id === +action.id);
        if (i > -1) copyState.splice(i, 1);
        return copyState;
      case DAHILI_NUMARALAR_UPDATE_SUCCESS:
        const { item } = action;
        const copyState2 = [...state];
        const a = copyState2.findIndex(el => +el.id === +item.id);
        if (a > -1) {
          copyState2[a] = item;
        }else{
          copyState2.push(item);
        }
        return copyState2;
      default:
        return state;
    }
  }

  const dahiliNumara = (state=null, action) => {
    switch(action.type){
      case DAHILI_NUMARALAR_FETCH_ONE_SUCCESS:
        return action.item;
      case DAHILI_NUMARALAR_FETCH_ONE_INIT:
      case DAHILI_NUMARALAR_FETCH_ONE_ERROR:
      case DAHILI_NUMARALAR_SAVE_SUCCESS:
      case DAHILI_NUMARALAR_UPDATE_SUCCESS:
      case DAHILI_NUMARALAR_DELETE_SUCCESS:
        return null;
      default:
        return state;
    }
  }



  const error = (state=null, action) => {
    switch(action.type){
      case DAHILI_NUMARALAR_SAVE_ERROR:
      case DAHILI_NUMARALAR_DELETE_ERROR:
      case DAHILI_NUMARALAR_UPDATE_ERROR:
      case DAHILI_NUMARALAR_FETCH_ALL_ERROR:
      case DAHILI_NUMARALAR_FETCH_ONE_ERROR:
        return action.error;
      case DAHILI_NUMARALAR_SAVE_SUCCESS:
      case DAHILI_NUMARALAR_DELETE_SUCCESS:
      case DAHILI_NUMARALAR_UPDATE_SUCCESS:
      case DAHILI_NUMARALAR_FETCH_ALL_SUCCESS:
      case DAHILI_NUMARALAR_FETCH_ONE_SUCCESS:
      case DAHILI_NUMARALAR_SAVE_INIT:
      case DAHILI_NUMARALAR_DELETE_INIT:
      case DAHILI_NUMARALAR_UPDATE_INIT:
      case DAHILI_NUMARALAR_FETCH_ALL_INIT:
      case DAHILI_NUMARALAR_FETCH_ONE_INIT:
      case DAHILI_NUMARALAR_CLEAR_ERRORS:
        return null;
      default:
        return state;
    }
  }

  const isLoading = (state=false, action) => {
    switch(action.type){
      case DAHILI_NUMARALAR_SAVE_INIT:
      case DAHILI_NUMARALAR_DELETE_INIT:
      case DAHILI_NUMARALAR_UPDATE_INIT:
      case DAHILI_NUMARALAR_FETCH_ALL_INIT:
      case DAHILI_NUMARALAR_FETCH_ONE_INIT:
        return true;
      case DAHILI_NUMARALAR_SAVE_SUCCESS:
      case DAHILI_NUMARALAR_DELETE_SUCCESS:
      case DAHILI_NUMARALAR_UPDATE_SUCCESS:
      case DAHILI_NUMARALAR_FETCH_ALL_SUCCESS:
      case DAHILI_NUMARALAR_FETCH_ONE_SUCCESS:
      case DAHILI_NUMARALAR_SAVE_ERROR:
      case DAHILI_NUMARALAR_DELETE_ERROR:
      case DAHILI_NUMARALAR_UPDATE_ERROR:
      case DAHILI_NUMARALAR_FETCH_ALL_ERROR:
      case DAHILI_NUMARALAR_FETCH_ONE_ERROR:
        return false;
      default:
        return state;
    }    
  }

  const selectedId = (state=null, action) => {
    switch(action.type){
      case DAHILI_NUMARALAR_SELECTED_ID:
        return state === action.id ? null : action.id;
      case DAHILI_NUMARALAR_FETCH_ALL_SUCCESS:
        return null;
      default:
        return state;
    }
  }
  
  const selectedOfisKat = (state=null, action) => {
    switch(action.type){
      case DAHILI_NUMARALAR_SELECTED_OFIS_KAT:
        return state === action.ofisKat ? null : action.ofisKat;
      case DAHILI_NUMARALAR_FETCH_ALL_SUCCESS:
        return null;
      default:
        return state;
    }
  }


  return combineReducers({
    ofisKatlari,
    selectedOfisKat,
    dahililer,
    dahiliNumara,
    error,
    isLoading,
    selectedId
  });
}

export default createDahiliNumaralarReducer();