import { combineReducers } from "redux";

export const USER_YERLER_FETCH_ALL_INIT = 'USER_YERLER_FETCH_ALL_INIT';
export const USER_YERLER_FETCH_ALL_SUCCESS = 'USER_YERLER_FETCH_ALL_SUCCESS';
export const USER_YERLER_FETCH_ALL_ERROR = 'USER_YERLER_FETCH_ALL_ERROR';
export const USER_YERLER_FETCH_ONE_INIT = 'USER_YERLER_FETCH_ONE_INIT';
export const USER_YERLER_FETCH_ONE_SUCCESS = 'USER_YERLER_FETCH_ONE_SUCCESS';
export const USER_YERLER_FETCH_ONE_ERROR = 'USER_YERLER_FETCH_ONE_ERROR';
export const USER_YERLER_SELECTED_ID = 'USER_YERLER_SELECTED_ID';
export const USER_YERLER_CLEAR_ERRORS = 'USER_YERLER_CLEAR_ERRORS';


const createUserYerlerReducer = () => {

  const yerler = (state=[], action) => {
    switch(action.type){
      case USER_YERLER_FETCH_ALL_SUCCESS:
        return action.items;
      case USER_YERLER_FETCH_ALL_INIT:
      case USER_YERLER_FETCH_ALL_ERROR:
        return [];
      default:
        return state;
    }
  }
  
  const yer = (state=null, action) => {
    switch(action.type){
      case USER_YERLER_FETCH_ONE_SUCCESS:
        return action.item;
      case USER_YERLER_FETCH_ONE_INIT:
      case USER_YERLER_FETCH_ONE_ERROR:
      case USER_YERLER_FETCH_ALL_INIT:
        return null;
      default:
        return state;
    }
  }

  const selectedUserYerId = (state=null, action) => {
    switch(action.type){
      case USER_YERLER_SELECTED_ID:
        return state === action.id ? null : action.id;
      case USER_YERLER_FETCH_ALL_INIT:
        return null;
      default:
        return state;
    }
  }

  const error = (state=null, action) => {
    switch(action.type) {
      case USER_YERLER_FETCH_ALL_ERROR:
      case USER_YERLER_FETCH_ONE_ERROR:
        return action.error;
      case USER_YERLER_FETCH_ALL_INIT:
      case USER_YERLER_FETCH_ALL_SUCCESS:
      case USER_YERLER_FETCH_ONE_INIT:
      case USER_YERLER_FETCH_ONE_SUCCESS:
      case USER_YERLER_CLEAR_ERRORS:
        return null;
      default:
        return state;
    }
  }

  const isLoading = (state=false, action) => {
    switch(action.type) {
      case USER_YERLER_FETCH_ALL_INIT:
      case USER_YERLER_FETCH_ONE_INIT:
        return true;
      case USER_YERLER_FETCH_ALL_ERROR:
      case USER_YERLER_FETCH_ALL_SUCCESS:
      case USER_YERLER_FETCH_ONE_ERROR:
      case USER_YERLER_FETCH_ONE_SUCCESS:
        return false;
      default:
        return state;
    }
  }

  return combineReducers({
    yerler,
    yer,
    selectedUserYerId,
    error,
    isLoading,
  });
}

export default createUserYerlerReducer();