import { 
  IZIN_ANSWER_ERROR, 
  IZIN_ANSWER_INIT, 
  IZIN_ANSWER_SUCCESS, 
  IZIN_CANCEL_ERROR, 
  IZIN_CANCEL_INIT, 
  IZIN_CANCEL_SUCCESS, 
  IZIN_CLEAR_ERRORS, 
  IZIN_DELETE_ERROR, 
  IZIN_DELETE_INIT, 
  IZIN_DELETE_SUCCESS, 
  IZIN_EDITING_IZIN, 
  IZIN_FETCH_ERROR, 
  IZIN_FETCH_INIT, 
  IZIN_FETCH_SUCCESS, 
  IZIN_FORM_GETIRDIMI_ERROR, 
  IZIN_FORM_GETIRDIMI_INIT, 
  IZIN_FORM_GETIRDIMI_SUCCESS, 
  IZIN_GEMI_DATE_ERROR, 
  IZIN_GEMI_DATE_INIT, 
  IZIN_GEMI_DATE_SUCCESS, 
  IZIN_SAVE_ERROR, 
  IZIN_SAVE_INIT, 
  IZIN_SAVE_SUCCESS 
} from "../reducers/izin";
import myfetch from "../utils/myFetch";

export const toggleFormGetirdimi = (formGetirdimi, izinId) => async dispatch => {
  dispatch({type:IZIN_FORM_GETIRDIMI_INIT});
  try {
    await dispatch(myfetch({url:"/izinler", method:'PUT', body:{izinId, formGetirdimi}}));
    dispatch({type:IZIN_FORM_GETIRDIMI_SUCCESS, formGetirdimi});
  } catch (error) {
    dispatch({type:IZIN_FORM_GETIRDIMI_ERROR, error});
  }
}

export const putGemiStartDate = (gemiStartDate, izinId) => async dispatch => {
  dispatch({type:IZIN_GEMI_DATE_INIT});
  try {
    await dispatch(myfetch({url:"/izinler", method:'PUT', body:{gemiStartDate, izinId}}));
    dispatch({type:IZIN_GEMI_DATE_SUCCESS, value:gemiStartDate, dateType:'gemiStartDate'});
  } catch (error) {
    dispatch({type:IZIN_GEMI_DATE_ERROR, error});
  }
}

export const putGemiEndDate = (gemiEndDate, izinId) => async dispatch => {
  dispatch({type:IZIN_GEMI_DATE_INIT});
  try {
    await dispatch(myfetch({url:"/izinler", method:'PUT', body:{gemiEndDate, izinId}}));
    dispatch({type:IZIN_GEMI_DATE_SUCCESS, value:gemiEndDate, dateType:'gemiEndDate'});
  } catch (error) {
    dispatch({type:IZIN_GEMI_DATE_ERROR, error});
  }
}


export const clearIzinErrors = () => dispatch => {
  dispatch({type:IZIN_CLEAR_ERRORS});
}

export const fetchIzin = id => async dispatch => {
  dispatch({type:IZIN_FETCH_INIT});
  try {
    const izin = await dispatch(myfetch({url:'/izinler', params:{id}}));
    dispatch({type:IZIN_FETCH_SUCCESS, izin});
  } catch (error) {
    dispatch({type:IZIN_FETCH_ERROR, error});
  }
}

export const saveIzin = (values, onSuccess=null) => async dispatch => {
  dispatch({type:IZIN_SAVE_INIT});
  try {
    const izin = await dispatch(myfetch({
      url:'/izinler', 
      method:'POST',
      body:{...values}
    }));
    dispatch({type:IZIN_SAVE_SUCCESS, izin});
    if (onSuccess) onSuccess(izin.id);
  } catch (error) {
    dispatch({type:IZIN_SAVE_ERROR, error});
  }
}

export const cancelIzin = id => async dispatch => {
  dispatch({type:IZIN_CANCEL_INIT});
  try {
    let izin = await dispatch(myfetch({
      url:'/izinler', 
      method:'PATCH',
      body:{id, iptal:1}
    }));
    if (!izin) izin = null;
    dispatch({type:IZIN_CANCEL_SUCCESS, izin, izinId:id});
  } catch (error) {
    dispatch({type:IZIN_CANCEL_ERROR, error});
  }
}

export const deleteIzin = id => async dispatch => {
  dispatch({type:IZIN_DELETE_INIT});
  try {
    await dispatch(myfetch({
      url:'/izinler', 
      method:'DELETE',
      body:{id}
    }));
    dispatch({type:IZIN_DELETE_SUCCESS, izinId:id});
  } catch (error) {
    dispatch({type:IZIN_DELETE_ERROR, error});
  }
}

export const saveAnswer = (izinId, answer, comment, onSuccess=null) => async dispatch => {
  dispatch({type:IZIN_ANSWER_INIT});
  try {
    const izin = await dispatch(myfetch({
      url:'/izinler', 
      method:'PUT', 
      body:{izinId, answer, comment}
    }));
    if (onSuccess) onSuccess();
    dispatch({type:IZIN_ANSWER_SUCCESS, izin, izinId})
  } catch (error) {
    dispatch({type:IZIN_ANSWER_ERROR, error});
  }
}


export const setEditingIzin = (editingIzin) => dispatch => {
  dispatch({type:IZIN_EDITING_IZIN, editingIzin});
}