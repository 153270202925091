import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toggleLeftMenu } from '../../actions/settings';
import Backdrop from '../backdrop/Backdrop';
import styles from './Leftbar.module.css';
import { ChevronRightIcon } from '@heroicons/react/outline';

const Leftbar = () => {
  const { isMenuOpen } = useSelector(({settings}) => settings);
  const dispatch = useDispatch();

  return <Fragment>
    {isMenuOpen && <Backdrop onClick={()=>dispatch(toggleLeftMenu())} />}
    <nav className={`${styles.LeftMenuWrapper} ${isMenuOpen ? '' : styles.Closed}`}>
      <ul className={styles.LeftMenu}>
        <Link to='/izinler' className={styles.MenuItem} onClick={()=>dispatch(toggleLeftMenu())}>
          <ChevronRightIcon width={20} height={20} color='white'/>
          <span>İzinler</span>
        </Link>
        <Link to='/employees' className={styles.MenuItem} onClick={()=>dispatch(toggleLeftMenu())}>
          <ChevronRightIcon width={20} height={20} color='white'/>
          <span>Çalışanlar</span>
        </Link>
        <Link to='/dahililer' className={styles.MenuItem} onClick={()=>dispatch(toggleLeftMenu())}>
          <ChevronRightIcon width={20} height={20} color='white'/>
          <span>Dahili Numaralar</span>
        </Link>
        <Link to='/map/fleet' className={styles.MenuItem} onClick={()=>dispatch(toggleLeftMenu())}>
          <ChevronRightIcon width={20} height={20} color='white'/>
          <span>Harita Fleet</span>
        </Link>
      </ul>
    </nav>
  </Fragment>
}

export default Leftbar;