import { combineReducers } from 'redux';

export const MAP_SETTINGS_MENU_TOGGLE = "MAP_SETTINGS_MENU_TOGGLE";
export const MAP_SETTINGS_SHOW_SETTINGS = "MAP_SETTINGS_SHOW_SETTINGS";
export const MAP_SETTINGS_SHOW_DAYLIGHT = "MAP_SETTINGS_SHOW_DAYLIGHT";

const defaultSettings = {
  showDayLight: true,
  isMenuOpen: true,
  showSettings: false,
}


const initSettings = !!localStorage.getItem("map-settings") ? JSON.parse(localStorage.getItem("map-settings")) : defaultSettings;

const createMapSettingsReducer = () => {

  const isMenuOpen = (state=initSettings.isMenuOpen, action) => {
    switch(action.type){
      case MAP_SETTINGS_MENU_TOGGLE:
        return !state;
      default:
        return state;
    }
  }

  const showSettings = (state=initSettings.showSettings, action) => {
    switch(action.type){
      case MAP_SETTINGS_SHOW_SETTINGS:
        return !state;
      case MAP_SETTINGS_MENU_TOGGLE:
        return false;
      default:
        return state;
    }
  }
  
  const showDayLight = (state=initSettings.showDayLight, action) => {
    switch(action.type){
      case MAP_SETTINGS_SHOW_DAYLIGHT:
        return !state;
      default:
        return state;
    }
  }
  return combineReducers({
    showDayLight,
    isMenuOpen,
    showSettings
  })
}

export default createMapSettingsReducer();