import { DEPARTMENTS_SELECTED_ID } from "../reducers/departments";
import { USER_YERLER_CLEAR_ERRORS, USER_YERLER_FETCH_ALL_ERROR, USER_YERLER_FETCH_ALL_INIT, USER_YERLER_FETCH_ALL_SUCCESS, USER_YERLER_FETCH_ONE_ERROR, USER_YERLER_FETCH_ONE_INIT, USER_YERLER_FETCH_ONE_SUCCESS, USER_YERLER_SELECTED_ID } from "../reducers/userYerler"
import myfetch from "../utils/myFetch";

export const fetchUserYerler = () => async dispatch => {
  dispatch({type:USER_YERLER_FETCH_ALL_INIT});
  try {
    const items = await dispatch(myfetch({url:"/user_yerler"}));
    dispatch({type:USER_YERLER_FETCH_ALL_SUCCESS, items});
  } catch (error) {
    dispatch({type:USER_YERLER_FETCH_ALL_ERROR, error});
  }
}

export const fetchUserYer = (id) => async dispatch => {
  dispatch({type:USER_YERLER_FETCH_ONE_INIT});
  try {
    const item = await dispatch(myfetch({url:"/user_yerler", params:{id}}));
    dispatch({type:USER_YERLER_FETCH_ONE_SUCCESS, item});
  } catch (error) {
    dispatch({type:USER_YERLER_FETCH_ONE_ERROR, error});
  }
}

export const toggleSelectedYerId = id => dispatch => {
  dispatch({type:USER_YERLER_SELECTED_ID, id});
  dispatch({type:DEPARTMENTS_SELECTED_ID, id:null});
}

export const clearErrors = () => dispatch => {
  dispatch({type:USER_YERLER_CLEAR_ERRORS});
}