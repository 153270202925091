import { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import IzinliBox from './IzinliBox';
import styles from './Weekly.module.css';
import { clearErrors, fetchWeeklyData, setFilter } from '../../../../actions/izinlerWeekly';
import ErrorMessage from '../../../../components/errorMessage/ErrorMessage';
import LoadingWave from '../../../../components/loading_spinners/LoadingWave';


const WeekDay = ({tarih, arr}) => {
  const days = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"];
  const dt = new Date(tarih);
  const tarihArr = tarih.split("-");

  return <li key={tarih}>
    <div className={styles.Day_Header}>
      <span>{tarihArr[2]}.{tarihArr[1]}.{tarihArr[0]}</span>
      <span>{days[dt.getDay()]}</span>
    </div>
    <div className={styles.Izinliler}>
      {arr.length === 0 ? <p>İzinli Yok.</p> : arr.map(izin => <IzinliBox key={izin.id} izin={izin} tarih={tarih} />)}
    </div>
  </li>
}

const FilterOptionsButton = ({forWhat, value}) => {
  const dispatch = useDispatch();
  const { izinTypes, gorevYerleri } = useSelector(({izinlerBasics}) => izinlerBasics);
  const { ships } = useSelector(({ships}) => ships);
  const { filterHidings } = useSelector(({izinlerWeekly}) => izinlerWeekly);

  const isActive = !filterHidings[forWhat].has(value);

  let text = "text belli değil";
  
  if (forWhat === "izinTypeId"){
    const izinType = izinTypes.find(el => +el.id === +value);
    if (!!izinType) text = izinType.name;
  
  }else if (forWhat === "gorevYeriId") {
    const gorevYeri = gorevYerleri.find(el => +el.id === +value);
    if (!!gorevYeri) text = gorevYeri.name;
  
  }else if (forWhat === "gorevYeriShipId"){
    const ship = ships.find(el => +el.id === +value);
    if (!!ship) text = ship.name;
  }

  return <button
    onClick={()=>dispatch(setFilter(forWhat, +value))}
    className={`${styles.FilterOptionButton} ${isActive ? styles.Active : ''}`}
  >
    {isActive ? <EyeIcon width={20} height={20} /> : <EyeOffIcon  width={20} height={20} />}
    <span>{text}</span>
  </button>
}


const Weekly = () => {
  const { qWeekNr, qYear } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { weeklyData, filterOptions, filterHidings, isLoading, error } = useSelector(({izinlerWeekly}) => izinlerWeekly);
  
  const [ filteredResults, setFilteredResults ] = useState(null)

  useEffect(()=>{
    dispatch(fetchWeeklyData(qYear, qWeekNr));
  }, [qWeekNr, qYear, dispatch]);

  useEffect(()=>{
    const filteredData = [];
    if (!!weeklyData) Object.keys(weeklyData).forEach(tarih => {
      const izinler = [...weeklyData[tarih]].filter(izin => {
        if (filterHidings.izinTypeId.has(+izin.izinType.id)) return false;
        if (filterHidings.gorevYeriId.size > 0 && !!izin.gorevYeri && filterHidings.gorevYeriId.has(+izin.gorevYeri.id)) return false;
        if (filterHidings.gorevYeriShipId.size > 0 && !!izin.gorevYeriShipId && filterHidings.gorevYeriShipId.has(+izin.gorevYeriShipId)) return false;
        
        return true;
      });
      filteredData.push(<WeekDay key={tarih} tarih={tarih} arr={izinler} />)
    });
    setFilteredResults(filteredData);
  }, [weeklyData, filterHidings]);


  const closeMe = useCallback(()=>{
    dispatch(clearErrors());
    navigate('../menu');
  }, [dispatch, navigate]);


  return <Fragment>
    {error && <ErrorMessage error={error} closeMe={closeMe} />}
    {isLoading && <LoadingWave open={true} />}
    <div className={styles.WeeklyPage}>

      <div className={styles.FilterArea}>
        <div className={styles.FilterArea_Row}>
          {!!filterOptions && filterOptions.izinTypeId.size > 0 && 
            [...filterOptions.izinTypeId].map(el => <FilterOptionsButton key={`izinTypeId${el}`} forWhat='izinTypeId' value={el} />)
          }
          {!!filterOptions && filterOptions.gorevYeriId.size > 0 && 
            [...filterOptions.gorevYeriId].map(el => <FilterOptionsButton key={`gorevYeriId${el}`} forWhat='gorevYeriId' value={el} />)
          }
          {!!filterOptions && filterOptions.gorevYeriShipId.size > 0 && 
            [...filterOptions.gorevYeriShipId].map(el => <FilterOptionsButton key={`gorevYeriShipId${el}`} forWhat='gorevYeriShipId' value={el} />)
          }
        </div>
      </div>

      <div className={styles.Actions}>
        <ul>
          <Link to={`../weekly/${qYear}/${+qWeekNr-1}`} className={styles.ActionItem}>
            <ChevronDoubleLeftIcon className={styles.IconLeft} width={30} height={30} />
            <span>Önceki Hafta</span>
          </Link>
          <Link to={`../weekly/${qYear}/${+qWeekNr+1}`} className={styles.ActionItem}>
            <span>Sonraki Hafta</span>
            <ChevronDoubleRightIcon className={styles.IconRight} width={30} height={30} />
          </Link>
        </ul>
      </div>
      <div className={styles.WeekArea}>
        <ul className={styles.DayList}>
          {filteredResults}
        </ul>
      </div>
    </div>
  </Fragment>
}

export default Weekly;