import { useSelector } from 'react-redux';
import styles from './styles.module.css';
import iconPropeller from '../../../../assets/map/propeller.png';
import iconAnchor from '../../../../assets/map/anchor.png';
import iconBollard from '../../../../assets/map/bollard.png';
import iconDrift from '../../../../assets/map/drift.png';
import iconSpeed from '../../../../assets/map/speed.png';


const MenuItemShip = ({ship}) => {
  const { selectedShipId } = useSelector(({map}) => map);
  let icon = null;

  if (ship.icon === "propeller") {
    icon = iconPropeller;
  }else if (ship.icon === "anchor"){
    icon = iconAnchor;
  }else if (ship.icon === "bollard"){
    icon = iconBollard;
  }else if (ship.icon === "drift") {
    icon = iconDrift;
  }

  //console.log(ship);



  return <li className={styles.MenuItemShip}>
    <div className={styles.Left}>
      <img src={icon} alt='' />
    </div>
    <div className={styles.Center}>
      <div className={styles.CenterTop}>{ship.shipName}</div>
      <div className={styles.CenterTop}>{ship.shipName}</div>
      <div className={styles.CenterBottom}></div>
    </div>
    <div className={styles.Right}></div>
  </li>
}

export default MenuItemShip;
