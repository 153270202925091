import styles from './IzinListItem.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { activateIzinListItem } from '../../../../actions/izinler';
import { cancelIzin, saveAnswer } from '../../../../actions/izin';
import { BanIcon, BadgeCheckIcon, ClockIcon, PencilAltIcon, ZoomInIcon, ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline';
import { formatDate } from '../../../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { Fragment, useCallback, useEffect, useState } from 'react';
import Confirmation from '../../../../components/confirmation/Confirmation';

const IzinListItem = ({izin}) => {
  const dispatch = useDispatch();
  const { selectedIzinId } = useSelector(({izinler}) => izinler);
  const { user } = useSelector(({auth}) => auth);
  const navigate = useNavigate();
  const [ editBtn, setEditBtn ] = useState(null);
  const [ cancelBtn, setCancelBtn ] = useState(null);
  const [ onay, setOnay ] = useState();
  const [ onaySirasiSende, setOnaySirasiSende ] = useState(false);

  useEffect(()=>{
    if (!!izin.answers && izin.answers.length > 0){
      setOnaySirasiSende(false);
      izin.answers.forEach(el => {
        if (+el.superiorId === +user.id && el.answer === "NoAnswer" && !!+el.isNotified){
          setOnaySirasiSende(true);
        }
      });
    }
  }, [izin, user]);

  const askCancel = useCallback(() => {
    setOnay({
      question: "iznin iptalini isteyeceğiz. Onaylıyor musunuz?",
      onConfirm: () => {dispatch(cancelIzin(izin.id)); setOnay(null);},
      onCancel: () => setOnay(null)
    });
  }, [dispatch, izin.id]);

  useEffect(()=>{
    if (+user.id === +izin.userId && (izin.status === "Approving" || izin.status === "Approved")){
      setEditBtn(
        <button 
          className={styles.BtnEdit}
          onClick={()=>navigate(`../edit/${izin.id}`)}
        >
          <PencilAltIcon height={25} width={25} className={styles.BtnIcon} />
          <span>Düzenle</span>
        </button>
      );
      setCancelBtn(
        <button 
          className={styles.BtnCancel}
          onClick={askCancel}
        >
          <BanIcon height={25} width={25} className={styles.BtnIcon} />
          <span>İptal Et</span>
        </button>
      )
    }
  }, [user, izin, navigate, askCancel]);

  

  let izinStatus = "Unknown";
  let icon = null;

  const classes = [styles.IzinListItem];
  if (+selectedIzinId === +izin.id) classes.push(styles.Active);
  if (izin.status === "Approved") {
    classes.push(styles.Approved);
    izinStatus = "Onaylandı";
    icon = <BadgeCheckIcon className={styles.Icon} height={40} width={40} />
  }
  if (izin.status === "Approving") {
    classes.push(styles.Approving);
    izinStatus = "Onay Aşamasında";
    icon = <ClockIcon className={styles.Icon} height={40} width={40} />
  }
  if (izin.status === "Canceled") {
    classes.push(styles.Canceled);
    izinStatus = "İptal Edildi.";
    icon = <BanIcon className={styles.Icon} height={40} width={40} />
  }
  if (izin.status === "Declined") {
    classes.push(styles.Canceled);
    izinStatus = "Reddedildi.";
    icon = <BanIcon className={styles.Icon} height={40} width={40} />
  }
  if (izin.status === "Canceling") {
    classes.push(styles.Canceling);
    izinStatus = "İptal Aşamasında";
    icon = <ClockIcon className={styles.Icon} height={40} width={40} />
  }

  const pickMe = () => {
    dispatch(activateIzinListItem(izin.id));
  }

  const showMe = () => {
    navigate(`../izin/${izin.id}`);
  }


  const askApprove = () => {
    setOnay({
      question: "izni onaylamak istediğinize emin misiniz?",
      onConfirm: () => {dispatch(saveAnswer(izin.id, "Approved")); setOnay(null);},
      onCancel: () => setOnay(null)
    });
  }

  const askDecline = () => {
    setOnay({
      question: "izni iptal etmek istediğinize emin misiniz?",
      onConfirm: () => {dispatch(saveAnswer(izin.id, "Declined")); setOnay(null);},
      onCancel: () => setOnay(null)
    });
  }

  return <Fragment>
    {onay && <Confirmation {...onay} />}
    <li className={classes.join(' ')}>
      <div className={styles.StatusArea} onClick={pickMe} onDoubleClick={showMe}>
        {icon}
        <span>{izinStatus}</span>
      </div>
      
      <div className={styles.Body} onClick={pickMe} onDoubleClick={showMe}>
        <div className={styles.InnerList}>
          <span>{izin.username}</span>
          <span>{izin.izinType.name} Talebi</span>
        </div>
        <div className={styles.InnerList}>
          <span>Başlangıç:</span>
          <span>{formatDate(izin.eStartDate)}</span>
        </div>
        <div className={styles.InnerList}>
          <span>Bitiş:</span>
          <span>{formatDate(izin.eEndDate)}</span>
        </div>
        {+izin.izinType.id !== 2 && <div className={styles.InnerList}>
          <span>{izin.gorevTuruDiger || (!!izin.gorevTuru && izin.gorevTuru.name)}</span>
          <span>{izin.gorevYeriDiger || izin.gorevYeriShipName || (!!izin.gorevYeri && izin.gorevYeri.name)}</span>
        </div>}
        {+izin.izinType.id === 2 && (izin.status === "Approving" || izin.status === "Approved") && <div className={styles.IzinFormGetirdimi}>
          {+izin.formGetirdimi === 0 ? "İzin formunu teslim etmedi." : "Form Teslim Edildi"}
        </div>}
        {/* <div className={styles.Explanation}>
          {izin.explanation}
        </div> */}
      </div>
      
      <div className={styles.RightActions}>
        <button className={styles.BtnShow} onClick={showMe}>
          <ZoomInIcon height={25} width={25} className={styles.BtnIcon} />
          <span>Göster</span>
        </button>
        {editBtn && editBtn}
        {cancelBtn && cancelBtn}
        {onaySirasiSende && 
          <button className={styles.BtnApprove} onClick={askApprove} >
            <ThumbUpIcon height={25} width={25} className={styles.BtnIcon} />
            <span>Onayla</span>
          </button>
        }
        {onaySirasiSende && 
          <button className={styles.BtnDecline} onClick={askDecline} >
            <ThumbDownIcon height={25} width={25} className={styles.BtnIcon} />
            <span>Reddet</span>
          </button>
        }
      </div>
    </li>
  </Fragment> 
}

export default IzinListItem;