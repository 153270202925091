import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { clearAllError } from "../../../../actions/user";
import ErrorMessage from "../../../../components/errorMessage/ErrorMessage";
import LoadingWave from "../../../../components/loading_spinners/LoadingWave";
import { phoneFormat } from "../../../../utils/functions";
import styles from './MyPage.module.css';


const MyPage = () => {
  const { user } = useSelector(({auth}) => auth);
  const { error, isLoading } = useSelector(({user}) => user);
  const dispatch = useDispatch();


  const closeMe = () => {
    dispatch(clearAllError());
  }

  console.log(user);

  if (!user) return null;

  return <Fragment>
    {error && <ErrorMessage error={error} closeMe={closeMe} />}
    {isLoading && <LoadingWave open={true} />}
    <div className={styles.MyPage}>
      <div className={styles.UserArea}>
        <h1>Bilgilerim</h1>
        <table>
          <tbody>
            <tr>
              <td>Adı Soyadı</td>
              <td>{`${user.name} ${user.middleName || ''} ${user.surname}`}</td>
            </tr>
            <tr>
              <td>Görevi</td>
              <td>{user.jobTitle}</td>
            </tr>
            <tr>
              <td>Departman</td>
              <td>{!!user.dept && user.dept.name}</td>
            </tr>
            <tr>
              <td>İşe Başlangıç Tarihi</td>
              <td></td>
            </tr>
            <tr>
              <td>Cep Telefonu:</td>
              <td>{phoneFormat(user.phone)}</td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>{user.email}</td>
            </tr>
          </tbody>
        </table>  
      </div>
      <nav>
        <h2>İşlemler</h2>
        <ul>
          <Link className={styles.NavElement} to='/auth/change-password'>Şifre Değiştir</Link>
        </ul>
      </nav>

    </div>
  </Fragment>
}

export default MyPage;