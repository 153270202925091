import { 
  MAP_SETTINGS_MENU_TOGGLE, 
  MAP_SETTINGS_SHOW_DAYLIGHT, 
  MAP_SETTINGS_SHOW_SETTINGS
} from "../reducers/mapSettings";

export const mapFleetMenuToggle = () => dispatch => {
  dispatch({type:MAP_SETTINGS_MENU_TOGGLE});
}

export const showSettingsToggle = () => dispatch => {
  dispatch({type:MAP_SETTINGS_SHOW_SETTINGS});
}

export const showDayLightToggle = () => dispatch => {
  dispatch({type:MAP_SETTINGS_SHOW_DAYLIGHT});
}