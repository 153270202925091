import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { clearErrors, fetchDahiliNumaralar } from '../../../actions/dahiliNumaralar';
import { getAuthorization } from '../../../actions/user';
import ErrorMessage from '../../../components/errorMessage/ErrorMessage';
import LoadingWave from '../../../components/loading_spinners/LoadingWave';
import DahiliEditor from './DahiliEditor';
import Dahililer from './Dahililer';


const DahiliNumaralar = () => {
  const { error, isLoading } = useSelector(({dahiliNumaralar}) => dahiliNumaralar);
  const { authorizations } = useSelector(({user}) => user);
  const dispatch = useDispatch();

  useEffect(()=>{
    if (authorizations["DAHILI_NUMARALAR"] === undefined)
      dispatch(getAuthorization("DAHILI_NUMARALAR"));
  }, [dispatch, authorizations]);

  useEffect(()=>{ //bunun burda olması gerekli 
    dispatch(fetchDahiliNumaralar());
  }, [dispatch]);

  return <Fragment>
    {error && <ErrorMessage error={error} closeMe={()=>dispatch(clearErrors())} />}
    {isLoading && <LoadingWave open={true} />}
    <Routes>
      <Route path='/' element={<Dahililer/>}/>
      <Route path='new' element={<DahiliEditor/>}/>
      <Route path='edit/:id' element={<DahiliEditor/>}/>
    </Routes>
  </Fragment>
}

export default DahiliNumaralar;