import { applyMiddleware, createStore, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import authReducer from '../reducers/auth';
import notificationReducer from '../reducers/notification';
import settings from '../reducers/settings';
import user from '../reducers/user';
import izinler from '../reducers/izinler';
import ships from '../reducers/ships';
import izinlerWeekly from '../reducers/izinlerWeekly';
import visitings from '../reducers/visitings';
import izinlerBasics from '../reducers/izinlerBasics';
import izin from '../reducers/izin';
import employees from '../reducers/employees';
import departments from '../reducers/departments';
import dahiliNumaralar from '../reducers/dahiliNumaralar';
import userYerler from '../reducers/userYerler';
import mapReducer from '../reducers/map';
import mapSettingsReducer from '../reducers/mapSettings';

export default function configureStore(){

  const middlewares = [
    thunkMiddleware,
  ]

  const store = createStore(
    combineReducers({
      auth: authReducer,
      notification: notificationReducer,
      settings: settings,
      user: user,
      izin: izin,
      izinler: izinler,
      izinlerBasics : izinlerBasics,
      izinlerWeekly: izinlerWeekly,
      visitings:visitings,
      ships: ships,
      employees: employees,
      departments: departments,
      dahiliNumaralar: dahiliNumaralar,
      userYerler: userYerler,
      map: mapReducer,
      mapSettings: mapSettingsReducer
    }),
    applyMiddleware(...middlewares)
  );

  return store;
}