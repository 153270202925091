import { ArrowLeftIcon } from '@heroicons/react/outline';
import { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearIzinlerErrors, fetchWaitingForMe } from '../../../../actions/izinler';
import ErrorMessage from '../../../../components/errorMessage/ErrorMessage';
import LoadingWave from '../../../../components/loading_spinners/LoadingWave';
import IzinListItem from '../izin_list_item/IzinListItem';
import styles from './UserIzinler.module.css';

const WaitingForMe = () => {
  const { waitingForMe, error, isLoading } = useSelector(({izinler}) => izinler);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
    dispatch(fetchWaitingForMe());
  },[dispatch]);

  const closeMe = () => {
    dispatch(clearIzinlerErrors());
    navigate('../menu', {replace:true});
  }

  return <Fragment>
    {error && <ErrorMessage error={error} closeMe={closeMe} />}
    {isLoading && <LoadingWave open={true} />}
    {waitingForMe.length > 0 && <div className={styles.IzinlerArea}>
      <div className={styles.topBackArea}>
        <a href={'/izinler/menu'}>
          <ArrowLeftIcon height={30} width={30} className={styles.BackIcon} />
          <span>{'İzinler Menü'}</span>
        </a>
      </div>
      <h1>
        <span>{waitingForMe.length} Adet Onayınızı Bekleyen İzin</span>
      </h1>
      <ul>
        {waitingForMe.map(el => <IzinListItem key={el.id} izin={el}/>)}
      </ul>
    </div>}
  </Fragment>
}

export default WaitingForMe;