import styles from './Button.module.css';

const Button = props => {

  const type = props.type ? props.type : 'submit';

  return <button 
      className={`
        ${styles.Button}
        ${!!props.btnType && props.btnType === "Danger" ? styles.Danger : ''}
        ${!!props.btnType && props.btnType === "Warn" ? styles.Warn : ''}
      `}
      disabled={props.disabled ? props.disabled : false} 
      type={type} 
      style={{...props.style}} 
      onClick={props.onClick}
    >
      {props.icon && <span>{props.icon}</span>}
      {props.children}
    </button>
}

export default Button;