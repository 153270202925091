import { logout } from "../actions/auth";
import { addNotification } from "../actions/notification";

const _URL = process.env.NODE_ENV === 'development' ? 'http://localhost/api/V3' : 'https://www.scottanker.net/api/V3';
//const _URL = 'http://localhost/api/V3';

const myfetch = (config) => async (dispatch, getState) => {
  let {url, body, params, method} = config;
  const token = (getState().auth && getState().auth.token) ? getState().auth.token : undefined;

  if (!method || method === 'GET') {
    method = 'GET'
    if (token) params = params ? {...params, token} : {token}
    body = undefined;
  }else {
    if (!body) body = {};
    if (method !== 'POST') {
      params = {_method:method}
      method = 'POST'
    }
    if (token) body = body ? {...body, token} : {token};
  }


  if (params) Object.keys(params).forEach((key, i) => {
    url +=  `${i === 0 ? '?' : '&'}${key}=${params[key]}`;
  });
  
  let res = await fetch(`${_URL}${url}`, {
    method: method,
    body: body ? JSON.stringify(body) : undefined 
  });
  if (res.status === 401) return dispatch(logout());
  if (res.status === 403) throw new Error('You have not permission!');
  if (res.status === 204) return true;
  res = await res.json();
  if (res.result === 'ERROR') {
    if (!!res.errors && Array.isArray(res.errors) && res.errors.length > 0){
      res.errors.forEach(el => dispatch(addNotification(el, 'danger')));
    }
    throw new Error(res.message);
  }
  if (res.result === 'OK' && !!res.message) dispatch(addNotification(res.message));
  return res.data;
}

export default myfetch;