import { combineReducers } from "redux";
import { IZINLER_RESET } from "./izinler";

export const IZINLER_BASICS_FETCH_TYPES_INIT = "IZINLER_BASICS_FETCH_TYPES_INIT";
export const IZINLER_BASICS_FETCH_TYPES_SUCCESS = "IZINLER_BASICS_FETCH_TYPES_SUCCESS";
export const IZINLER_BASICS_FETCH_TYPES_ERROR = "IZINLER_BASICS_FETCH_TYPES_ERROR";

export const IZINLER_BASICS_FETCH_GOREV_TURLERI_INIT = "IZINLER_BASICS_FETCH_GOREV_TURLERI_INIT";
export const IZINLER_BASICS_FETCH_GOREV_TURLERI_SUCCESS = "IZINLER_BASICS_FETCH_GOREV_TURLERI_SUCCESS";
export const IZINLER_BASICS_FETCH_GOREV_TURLERI_ERROR = "IZINLER_BASICS_FETCH_GOREV_TURLERI_ERROR";

export const IZINLER_BASICS_FETCH_GOREV_YERLERI_INIT = "IZINLER_BASICS_FETCH_GOREV_YERLERI_INIT";
export const IZINLER_BASICS_FETCH_GOREV_YERLERI_SUCCESS = "IZINLER_BASICS_FETCH_GOREV_YERLERI_SUCCESS";
export const IZINLER_BASICS_FETCH_GOREV_YERLERI_ERROR = "IZINLER_BASICS_FETCH_GOREV_YERLERI_ERROR";

export const IZINLER_BASICS_CLEAR_ALL_ERRORS = "IZINLER_BASICS_CLEAR_ALL_ERRORS";

const createIzinlerBasicsReducer = () => {
  
  const izinTypes = (state=[], action) => {
    switch(action.type){
      case IZINLER_BASICS_FETCH_TYPES_SUCCESS:
        return action.izinTypes;
      case IZINLER_BASICS_FETCH_TYPES_INIT:
      case IZINLER_BASICS_FETCH_TYPES_ERROR:
      case IZINLER_RESET:
        return [];
      default:
        return state;
    }
  }

  const gorevTurleri = (state=[], action) => {
    switch(action.type){
      case IZINLER_BASICS_FETCH_GOREV_TURLERI_SUCCESS:
        return action.gorevTurleri;
      case IZINLER_BASICS_FETCH_GOREV_TURLERI_INIT:
      case IZINLER_BASICS_FETCH_GOREV_TURLERI_ERROR:
      case IZINLER_RESET:
        return [];
      default:
        return state;
    }
  }

  const gorevYerleri = (state=[], action) => {
    switch(action.type){
      case IZINLER_BASICS_FETCH_GOREV_YERLERI_SUCCESS:
        return action.gorevYerleri;
      case IZINLER_BASICS_FETCH_GOREV_YERLERI_INIT:
      case IZINLER_BASICS_FETCH_GOREV_YERLERI_ERROR:
      case IZINLER_RESET:
        return [];
      default:
        return state;
    }
  }

  const error = (state=null, action) => {
    switch(action.type){
      case IZINLER_BASICS_FETCH_TYPES_ERROR:
      case IZINLER_BASICS_FETCH_GOREV_TURLERI_ERROR:
      case IZINLER_BASICS_FETCH_GOREV_YERLERI_ERROR:
        return action.error;
      case IZINLER_BASICS_FETCH_TYPES_INIT:
      case IZINLER_BASICS_FETCH_GOREV_TURLERI_INIT:
      case IZINLER_BASICS_FETCH_GOREV_YERLERI_INIT:
      case IZINLER_BASICS_FETCH_TYPES_SUCCESS:
      case IZINLER_BASICS_FETCH_GOREV_TURLERI_SUCCESS:
      case IZINLER_BASICS_FETCH_GOREV_YERLERI_SUCCESS:
        return null;
      default:
        return state;      
    }
  }

  const isLoading = (state=false, action) => {
    switch(action.type) {
      case IZINLER_BASICS_FETCH_TYPES_INIT:
      case IZINLER_BASICS_FETCH_GOREV_TURLERI_INIT:
      case IZINLER_BASICS_FETCH_GOREV_YERLERI_INIT:
        return true;
      case IZINLER_BASICS_FETCH_TYPES_SUCCESS:
      case IZINLER_BASICS_FETCH_GOREV_TURLERI_SUCCESS:
      case IZINLER_BASICS_FETCH_GOREV_YERLERI_SUCCESS:
      case IZINLER_BASICS_FETCH_TYPES_ERROR:
      case IZINLER_BASICS_FETCH_GOREV_TURLERI_ERROR:
      case IZINLER_BASICS_FETCH_GOREV_YERLERI_ERROR:
      case IZINLER_RESET:
        return false;
      default:
        return state;
    }
  }

  return combineReducers({
    izinTypes,
    gorevTurleri,
    gorevYerleri,
    error,
    isLoading
  });
}

export default createIzinlerBasicsReducer();