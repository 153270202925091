import styles from './styles.module.css';
import logo from '../../assets/logo/logo.png';
import warnIcon from '../../assets/img/warn.svg';

const AllowNotificationError = () => {


  return <main className={styles.Main}>
    <div className={styles.LogoContainer}>
      <img src={logo} alt="Scot Tanker" />
    </div>
    <div className={styles.IconContainer}>
      <img src={warnIcon} alt="Warning Icon" />
    </div>
    <div>
      <h1>Size bildirim gönderebilmemiz için izin vermelisiniz!</h1>
    </div>
    <a 
      className={styles.Link}
      href='https://support.google.com/chrome/answer/3220216?hl=tr&co=GENIE.Platform%3DDesktop&oco=1'>
      Nasıl Yapılacağını Öğrenmek için tıklayınız.
    </a>
  </main>
  
}

export default AllowNotificationError;