import React from 'react';
import {createPortal} from 'react-dom';
import styles from './backdrop.module.css';

const Backdrop = props => {
    return createPortal(
      <div
        onClick={props.onClick} 
        style={{...props.style}} 
        className={styles.backdrop} 
      />, 
      document.getElementById('backdrop')
    );
};

export default Backdrop;